import React, { useState } from "react";
import {
  Container,
  Graph,
  LayoutG,
  TextContainer,
  Tooltip,
} from "./LineChartStyles";
import Text from "../../../atoms/text/Text";
import { theme } from "../../../../styles/theme";
import { CustomLayerProps, Layer, ResponsiveLine } from "@nivo/line";
import { DotsItem } from "@nivo/core";
import * as Svg from "../../../atoms/icon/index";
import { useLocalization } from "../../../../hooks/useLocalization";

const LineChart = ({
  title,
  data,
  range,
}: {
  title: string;
  data: any;
  range: { min: number; max: number };
}) => {
  const transloction = useLocalization();
  const [currentIndex, setCurrentIndex] = useState(1);

  const clickPoints = (point: any) => {
    const { index, data } = point.points[0];
    if (data.x !== "") setCurrentIndex(index);
  };

  const LastPoint = ({ points, ...props }: CustomLayerProps) => {
    const target = points[currentIndex];

    return (
      <>
        <LayoutG $x={target.x} $y={target.y}>
          <Svg.BubbleBox x={-55} y={-70} />
          <foreignObject x={-50} y={-68} width="93px" height="55px">
            <Tooltip>
              <TextContainer>
                <Text
                  content={transloction.blood_vessel_my_value} //"내 수치"
                  fontSize="10px"
                  fontWeight={theme.weight.bold}
                  color={theme.color.gray100}
                />
                <br />
                <Text
                  content={`${target.data.y}mg/dL`}
                  fontSize="16px"
                  fontWeight={theme.weight.bold}
                  color={theme.color.black}
                />
              </TextContainer>
            </Tooltip>
          </foreignObject>
          <DotsItem
            x={0}
            y={0}
            datum={target.data}
            size={11}
            color={theme.color.white}
            borderWidth={props.pointBorderWidth || 2}
            borderColor={target.borderColor}
            labelYOffset={props.pointLabelYOffset}
          />
        </LayoutG>
      </>
    );
  };

  return (
    <Container>
      <Text
        content={title}
        fontSize={theme.size.medium}
        fontWeight={theme.weight.bold}
        letterSpacing={theme.letterSize.x}
      />
      <Graph>
        <ResponsiveLine
          data={data}
          colors={[theme.color.gray200]}
          animate={true}
          curve="cardinal"
          margin={{ top: 30, right: 5, bottom: 25, left: 35 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            clamp: true,
            nice: true,
            min: range.min - 50,
            max: range.max + 50,
            stacked: false,
            reverse: false,
          }}
          onClick={clickPoints}
          sliceTooltip={() => <></>}
          yFormat=">-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            legendOffset: -55,
            legendPosition: "middle",
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 13,
            tickRotation: 0,
            tickValues: 4,
            legendOffset: -55,
            legendPosition: "middle",
          }}
          gridYValues={4}
          theme={{
            textColor: theme.color.gray40,
            grid: {
              line: {
                stroke: theme.color.gray200,
                strokeWidth: 0.4,
              },
            },
          }}
          lineWidth={2.5}
          enableGridX={false}
          enableCrosshair={false}
          enableSlices="x"
          enableArea={true}
          areaBaselineValue={50}
          areaOpacity={0.25}
          defs={[
            {
              id: "gradientC",
              type: "linearGradient",
              colors: [
                { offset: 0, color: theme.color.white },
                { offset: 100, color: theme.color.black },
              ],
            },
          ]}
          fill={[{ match: { id: "HR" }, id: "gradientC" }]}
          layers={
            [
              "grid",
              "markers",
              "axes",
              "areas",
              "lines",
              "slices",
              "points",
              LastPoint,
            ] as Layer[]
          }
          pointSize={8}
          pointColor={theme.color.white}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
        />
      </Graph>
    </Container>
  );
};

export default LineChart;
