import { styled } from "styled-components";
import { pxToVh } from "../../../utils/calc/pxToVh";

export const ResultCommentStyles = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${pxToVh(38)};
  width: ${pxToVh(346)};
  span {
    line-height: 150%;
    letter-spacing: ${({ theme }) => theme.letterSize.x};
  }
`;

export const GlucoseLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    line-height: 150%;
  }
`;

export const TextToCenter = styled.div`
  width: ${pxToVh(346)};
`;
