import React from "react";
import Title from "../texts/title/Title";
import ResultImage from "../resultImage/ResultImage";
import { theme } from "../../styles/theme";
import SquareInbody from "../square/squareInbody/SquareInbody";
import { SquareBox } from "../square/squareInbody/SquareInbodyStyles";
import ShareFooter from "../shareFooter/ShareFooter";
import { useInbody } from "../../hooks/useInbody";
import { calcBmi, calcInboy } from "../../utils/calc/CalcResult";
import { BMIgrade } from "../../utils/comment/InbodyGrade";
import { DocumentTitle } from "../../utils/DocumentTitle";
import { useLocalization } from "../../hooks/useLocalization";

const InbodyController = () => {
  const { queryData, balancerNavigate } = useInbody();
  const translation = useLocalization();
  const bmiGrade = BMIgrade();

  const {
    gender,
    age,
    height,
    weight,
    bmi,
    smm,
    smmMin,
    smmMax,
    tbw,
    tbwMin,
    tbwMax,
    pbf,
    pbfMin,
    pbfMax,
    whr,
    whrMin,
    whrMax,
  } = queryData;

  const fitCore = bmiGrade[calcBmi(bmi)];

  DocumentTitle(
    `${translation.document_title_result_inbody} - ${fitCore.title}`
  );

  const smmStatus = calcInboy(smmMin, smmMax, smm);
  const tbwStatus = calcInboy(tbwMin, tbwMax, tbw);
  const pbfStatus = calcInboy(pbfMin, pbfMax, pbf);
  const whrStatus = calcInboy(whrMin, whrMax, whr);

  return (
    <>
      <Title
        content={translation.inbody_header_title}
        subTitle={translation.inbody_header_subtitle}
      />
      <ResultImage
        grade={fitCore.title}
        value={`BMI ${bmi}`}
        comment={`${translation.inbody_report_user_information
          .replace("@1", `${height}`)
          .replace("@2", `${weight}`)
          .replace("@3", `${age}`)} ${
          gender === "0" ? translation.male : translation.female
        }`}
        fontSize={theme.size.xs}
        icon={fitCore.img}
      />
      <SquareBox>
        <SquareInbody
          title="smm"
          result={{
            target: smm,
            min: smmMin,
            max: smmMax,
          }}
          status={smmStatus}
          squareClick={() =>
            balancerNavigate("smm", smm, smmMin, smmMax, smmStatus)
          }
        />
        <SquareInbody
          title="tbw"
          result={{
            target: tbw,
            min: tbwMin,
            max: tbwMax,
          }}
          status={tbwStatus}
          squareClick={() =>
            balancerNavigate("tbw", tbw, tbwMin, tbwMax, tbwStatus)
          }
        />
        <SquareInbody
          title="pbf"
          result={{
            target: pbf,
            min: pbfMin,
            max: pbfMax,
          }}
          status={pbfStatus}
          squareClick={() =>
            balancerNavigate("pbf", pbf, pbfMin, pbfMax, pbfStatus)
          }
        />
        <SquareInbody
          title="whr"
          result={{
            target: whr,
            min: whrMin,
            max: whrMax,
          }}
          status={whrStatus}
          squareClick={() =>
            balancerNavigate("whr", whr, whrMin, whrMax, whrStatus)
          }
        />
      </SquareBox>
      <ShareFooter
        shareTitle={`${translation.document_title_result_inbody} - ${fitCore.title}`}
      />
    </>
  );
};

export default InbodyController;
