import { TMark, TStatus } from "../../types/deepQuery.type";

export const calcHr = (hr: number) => {
  hr = Number(hr);
  //   if (hr >= 120) hr = 120;
  // if (120 <= hr) hr = 120;
  let status: TStatus = "normal";
  let warn: TMark = "";
  if (60 <= hr && hr <= 99) {
    status = "good";
  } else if (60 > hr && hr >= 50) {
    status = "normal";
    warn = "down";
  } else if (110 > hr && hr >= 100) {
    status = "normal";
    warn = "up";
  } else if (hr < 50) {
    status = "bad";
    warn = "down";
  } else if (hr >= 110) {
    status = "bad";
    warn = "up";
  }
  return { hrStatus: status, hrMark: warn };
};

export const calcRep = (rep: number) => {
  rep = Number(rep);
  let warn: TMark = "";
  // if (30 <= rep) rep = 30;
  let status: TStatus = "normal";
  if (6 <= rep && rep < 19) {
    status = "good";
  } else if (rep < 6) {
    status = "normal";
    warn = "down";
    // } else if ((19 <= rep && rep < 21)) {
    //   status = "normal";
  } else {
    status = "bad";
    warn = "up";
  }
  return { rep: rep, repStatus: status, repMark: warn };
};

export const calcSi = (si: number) => {
  // if (100 <= si) si = 100;
  let status: TStatus = "normal";
  if (si < 50) {
    status = "good";
  } else if (50 <= si && si < 66) {
    status = "normal";
  } else {
    status = "bad";
  }

  return { si: si, status: status };
};
