export interface IDeepMediQuery {
  hr: number;
  rep: number;
  msi: number;
  psi: number;
}

export const defaulDeepMediQuery: IDeepMediQuery = {
  hr: 0,
  rep: 0,
  msi: 0,
  psi: 0,
};

export const deepMediQueryItems = ["hr", "rep", "msi", "psi"];

export type THealthType = "hr" | "rep" | "msi" | "psi";
export type TStatus = "good" | "normal" | "bad";
export type TInbody = "good" | "down" | "up";
export type TMark = "" | "up" | "down";
