import { styled } from "styled-components";
import { pxToVh } from "../../../../utils/calc/pxToVh";

export const Container = styled.div<{ $margin?: string }>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ $margin }) => $margin || pxToVh(127)};
`;

export const Graph = styled.div`
  position: relative;
  width: ${pxToVh(340)};
  height: ${pxToVh(200)};
`;

export const LayoutG = styled.g<{ $x: number; $y: number }>`
  transform: ${({ $x, $y }) => `translate(${$x}px, ${$y}px)`};
  transition: all 0.5s;
`;

export const Tooltip = styled.div`
  width: 93px;
  height: 55px;
  border-radius: ${pxToVh(10)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
`;

export const TextContainer = styled.div`
  width: 100%;
  padding: 0 ${pxToVh(10)};
`;
