import React from "react";
import Title from "../texts/title/Title";
import { theme } from "../../styles/theme";
import BloodSquare from "../square/bloodSquare/BloodSquare";
import {
  BloodSquareContainer,
  ResultBlood,
} from "../square/bloodSquare/BloodSquareStyles";
import Text from "../atoms/text/Text";
import Question from "../texts/questionText/Question";
import { pxToVh } from "../../utils/calc/pxToVh";
import BloodResult from "../square/bloodSquare/bloodResult/BloodResult";
import Better from "../better/Better";
import ShareFooter from "../shareFooter/ShareFooter";
import Popup from "../popup/Popup";
import { bloodSvg } from "../../utils/comment/BloodSvg";
import { DocumentTitle } from "../../utils/DocumentTitle";
import { useBlood } from "../../hooks/useBlood";
import { BloodComment } from "../../utils/comment/BloodComment";
import { HealthMentController } from "../../utils/comment/HealthMentStore";
import { useLocalization } from "../../hooks/useLocalization";

const BloodTemplate = () => {
  const { queryData, isShow, closeClick, showPopupClick } = useBlood();
  const { bloodBetterComment } = HealthMentController();
  const { bloodComment } = BloodComment();
  const translation = useLocalization();

  const {
    age,
    gender,
    type,
    bloodvesselAge,
    bloodvesselIndex,
    pulse,
    pulseAvg,
    grade,
  } = queryData;

  const refinedGender = gender === 0 ? translation.male : translation.female;
  const bloodNormaltype = translation.blood_vessel_result_a_type
    .split("(")[0]
    .replace(" ", "");
  const bloodCautiontype = translation.blood_vessel_result_c_type
    .split("(")[0]
    .replace(" ", "");
  const status = type.split("(")[0];
  const [avgMin, avgMax] = String(pulseAvg).split("~");
  const month = translation.m_d_hh_mm.split("@")[0];
  const date = translation.m_d_hh_mm.split("@")[1];
  const ageYear = translation.blood_vessel_result_user_age_text.split("@")[0];
  const ageText = translation.blood_vessel_result_user_age_text.split("@")[1];
  const bloodcommentText =
    status === bloodNormaltype
      ? bloodComment.normal
      : status === bloodCautiontype
      ? bloodComment.caution
      : bloodComment.danger;

  DocumentTitle(`${translation.blood_vessel_measure_result} - ${type}`);

  return (
    <>
      <div style={{ width: pxToVh(341) }}>
        <Title
          content={translation.blood_vessel_result_health_title}
          subTitle={`${
            new Date().getMonth() + 1
          }${month} ${new Date().getDate()}${date} ${new Date().getHours()}:${new Date().getMinutes()} ${
            translation.blood_vessel_result_measure_date_text
          }#${ageYear}${age}${ageText} ${refinedGender}`}
        />
        <Title
          content={translation.blood_vessel_summary_result_title_text}
          firstSize={theme.size.medium}
          subTitle={
            translation.blood_vessel_summary_result_blood_vessel_health_type_title_text
          }
        />
        <BloodSquareContainer>
          <BloodSquare
            svg={bloodSvg[grade].type}
            semiContent={
              translation.blood_vessel_summary_result_blood_vessel_health_type_text
            }
            content={type}
          />
          <BloodSquare
            svg={bloodSvg[grade].chart}
            semiContent={
              translation.blood_vessel_summary_result_blood_vessel_health_index_text
            }
            content={String(bloodvesselIndex)}
          />
        </BloodSquareContainer>
        <ResultBlood>
          <Text
            content={bloodcommentText}
            fontWeight={theme.weight.light}
            fontSize={theme.size.small}
            customStyle={{ lineHeight: "150%" }}
          />
        </ResultBlood>
        <Question
          content={
            translation.blood_vessel_summary_result_blood_vessel_health_type_title_dialog_button
          }
          helpClick={() => showPopupClick("graph")}
        />
        <Question
          content={
            translation.blood_vessel_summary_result_blood_vessel_health_index_chart_title_dialog_button
          }
          helpClick={() => showPopupClick("table")}
        />
        <div style={{ marginBottom: `${pxToVh(64)}` }} />
        <Title
          content={translation.blood_vessel_result_detail_title_text}
          firstSize={theme.size.medium}
        />
        <BloodResult
          title={translation.blood_vessel_health_title}
          contentArr={[
            [translation.user_age, String(age)],
            [translation.blood_vessel_age, String(bloodvesselAge)],
            [translation.compare_user_age, String(bloodvesselAge - age)],
          ]}
          target={28}
          standardTarget={34}
          isQuestionMark={true}
          clickQuestionMark={() => showPopupClick("vascular")}
        />
        <BloodResult
          title={translation.ppg_avg_title}
          contentArr={[
            [translation.ppg_state_title, status],
            [translation.user_ppg_result_title, String(pulse)],
            [translation.age_ppg_avg_title, String(pulseAvg)],
          ]}
          target={pulse}
          standardTarget={Number(avgMax) - Number(avgMin)}
          min={Number(avgMin)}
        />
        <Better
          title={translation.better_title}
          category={bloodBetterComment.type}
          comment={bloodBetterComment.comment}
        />
        <ShareFooter
          shareTitle={`${translation.blood_vessel_health_title} - ${type}${translation.blood_vessel_type}`}
        />
      </div>
      {isShow && <Popup isShow={isShow} closeClick={closeClick} />}
    </>
  );
};

export default BloodTemplate;
