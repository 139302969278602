export type TBloodQuery = {
  age: number;
  gender: number;
  type: "";
  bloodvesselAge: number;
  bloodvesselIndex: number;
  pulse: number;
  pulseAvg: number;
  grade: string;
};

export const defaulBloodQuery: TBloodQuery = {
  age: 0,
  gender: 0,
  type: "",
  bloodvesselAge: 0,
  bloodvesselIndex: 0,
  pulse: 0,
  pulseAvg: 0,
  grade: "",
};

export const bloodQueryItems = [
  "age",
  "gender",
  "type",
  "bloodvesselAge",
  "bloodvesselIndex",
  "pulse",
  "pulseAvg",
  "grade",
];
