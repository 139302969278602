import { styled } from "styled-components";
import { pxToVh } from "../../utils/calc/pxToVh";

export const Container = styled.div`
  margin-top: ${pxToVh(4)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImgContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${pxToVh(26)};
  svg {
    width: ${pxToVh(208)};
    height: ${pxToVh(102)};
  }
`;

export const TextInImage = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 70%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  text-align: center;
  width: ${pxToVh(150)};
`;
