import { styled } from "styled-components";
import { pxToVh } from "../../../utils/calc/pxToVh";

export const StyledButton = styled.button`
  width: ${({ style }) => style?.width || pxToVh(298)};
  height: ${({ style }) => style?.height || pxToVh(45)};
  color: ${({ style, theme }) => style?.color || theme.color.white};
  font-size: ${({ style }) => style?.fontSize};
  font-weight: ${({ style }) => style?.fontWeight};
  background-color: ${({ style, theme }) =>
    style?.backgroundColor || theme.color.black};
  border: ${({ style, theme }) =>
    style?.border || `1px solid ${theme.color.gray300}`};
  border-radius: ${({ style }) => style?.borderRadius || pxToVh(10)};
`;
