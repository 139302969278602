import React, { useMemo } from "react";
import { StyledButton } from "./StyledButton";

interface IButton extends React.HTMLAttributes<HTMLButtonElement> {
  content: string;
  color?: string;
  // width: string;
  // height?: string;
  fontSize: string;
  fontWeight: string;
  backgroundColor?: string;
  border?: string;
  borderRadius?: string;
  customStyle?: React.CSSProperties;
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button = ({
  content,
  color,
  // width,
  // height,
  backgroundColor,
  fontSize,
  fontWeight,
  border,
  borderRadius,
  customStyle,
  handleClick,
}: IButton) => {
  const style = useMemo(() => {
    return {
      color,
      backgroundColor,
      fontSize,
      fontWeight,
      border,
      borderRadius,
      ...customStyle,
    };
  }, [
    color,
    backgroundColor,
    fontSize,
    fontWeight,
    border,
    borderRadius,
    customStyle,
  ]);

  return (
    <StyledButton style={style} onClick={handleClick}>
      {content}
    </StyledButton>
  );
};

export default Button;
