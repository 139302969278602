import styled, { keyframes } from "styled-components";
import { pxToVh } from "../../utils/calc/pxToVh";

const appearFadeOut = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30%);
  }
  15% {
    opacity: 1;
    transform: translateY(0);
  }
  85% {
    opacity: 1;
    transform: translateY(0); 
  }
  100% {
    opacity: 0;
    transform: translateY(30%);
  }
`;

export const Line = styled.div`
  width: ${pxToVh(346)};
  height: ${pxToVh(1)};
  background-color: ${({ theme }) => theme.color.gray200};
  margin-bottom: ${pxToVh(18)};
`;

export const FooterLayout = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
  gap: ${pxToVh(20)};
  width: ${pxToVh(346)};
  margin-bottom: ${pxToVh(55)};
`;

export const ClipboardLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${pxToVh(6)};
  svg {
    width: ${pxToVh(24)};
    height: ${pxToVh(24)};
  }
`;

export const ShareLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${pxToVh(6)};
  svg {
    width: ${pxToVh(24)};
    height: ${pxToVh(24)};
  }
`;

export const CopyLayout = styled.div<{ $isCopy: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgb(21, 21, 21, 0.9);
  border-radius: 15px;
  width: 100%;
  height: ${pxToVh(45)};
  animation: ${appearFadeOut} 3.2s ease-in-out;
`;
