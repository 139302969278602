import React from "react";
import * as Svg from "../../atoms/icon/index";
import Text from "../../atoms/text/Text";
import { theme } from "../../../styles/theme";
import { pxToVh } from "../../../utils/calc/pxToVh";
import { SvgContainer } from "./QuestionStyles";

const Question = ({
  content,
  helpClick,
}: {
  content: string;
  helpClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}) => {
  return (
    <SvgContainer onClick={helpClick}>
      <Svg.Help width={pxToVh(18)} height={pxToVh(18)} />
      <Text
        content={content}
        fontSize={theme.size.xs}
        fontWeight={theme.weight.bold}
        color={theme.color.gray50}
        customStyle={{ lineHeight: "150%", textDecorationLine: "underline" }}
      />
    </SvgContainer>
  );
};

export default Question;
