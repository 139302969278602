import React, { useEffect, useState } from "react";
import {
  ClipboardLayout,
  FooterLayout,
  Line,
  ShareLayout,
  CopyLayout,
} from "./ShareFooterStyles";
import Text from "../atoms/text/Text";
import { theme } from "../../styles/theme";
import * as Svg from "../atoms/icon/index";
import { useLocalization } from "../../hooks/useLocalization";

const ShareFooter = ({ shareTitle }: { shareTitle: string }) => {
  const translation = useLocalization();
  const [isCopy, setIsCopy] = useState(false);

  useEffect(() => {
    if (isCopy) setTimeout(() => setIsCopy(false), 3100);
  }, [isCopy]);

  const shareClick = async () => {
    if (!window.navigator.canShare) {
      alert(translation.footer_valid);
    } else {
      window.navigator.share({
        title: shareTitle,
        url: window.location.href,
      });
    }
  };

  const clipClick = async () => {
    try {
      await navigator.clipboard
        .writeText(document.location.href)
        .then(() => setIsCopy(true));
    } catch (err) {
      alert(translation.footer_chrome_safari);
    }
  };

  return (
    <>
      <Line />
      <FooterLayout>
        <ClipboardLayout onClick={clipClick}>
          <Svg.Clipboard />
          <Text
            content={translation.footer_copy}
            fontSize={theme.size.xs}
            fontWeight={theme.weight.light}
            color={theme.color.gray100}
          />
        </ClipboardLayout>
        <ShareLayout onClick={shareClick}>
          <Svg.Share />
          <Text
            content={translation.footer_share}
            fontSize={theme.size.xs}
            fontWeight={theme.weight.light}
            color={theme.color.gray100}
          />
        </ShareLayout>
        {isCopy && (
          <CopyLayout $isCopy={isCopy}>
            {translation.footer_clipboard}
          </CopyLayout>
        )}
      </FooterLayout>
    </>
  );
};

export default ShareFooter;
