import React from "react";
import Text from "../atoms/text/Text";
import { theme } from "../../styles/theme";
import {
  ArrowLayout,
  BetterCommentTitle,
  BetterStyles,
  Categoery,
  CategoryContainer,
  CommentContainer,
  ScollX,
} from "./BetterStyles";

const Better = ({
  title,
  category,
  comment,
  commentTitle,
}: {
  title: string;
  category: string[];
  comment: string[];
  commentTitle?: string[];
}) => {
  const [currentCategory, setCurrentCategory] = React.useState(0);

  return (
    <BetterStyles>
      <ArrowLayout>
        <Text
          content={title}
          fontSize={theme.size.medium}
          fontWeight={theme.weight.bold}
          letterSpacing={theme.letterSize.x}
        />
      </ArrowLayout>
      <ScollX>
        <CategoryContainer>
          {category.map((m, i) => {
            return (
              <Categoery
                key={i}
                onClick={() => setCurrentCategory(i)}
                $isSelect={m === category[currentCategory]}
              >
                {m}
              </Categoery>
            );
          })}
        </CategoryContainer>
      </ScollX>
      <CommentContainer>
        <BetterCommentTitle>
          {commentTitle && commentTitle[currentCategory]}
        </BetterCommentTitle>
        <Text
          content={comment[currentCategory]}
          fontSize={theme.size.small}
          fontWeight={theme.weight.light}
          letterSpacing={theme.letterSize.x}
        />
      </CommentContainer>
    </BetterStyles>
  );
};

export default React.memo(Better);
