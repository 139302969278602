import React from "react";
import { DataContainer, DataInProgress } from "./BloodProgressStyles";
import Text from "../../atoms/text/Text";
import {
  ProgressValue,
  WidthBarLayout,
} from "../progressbar/ProgressbarStyles";
import { pxToVh } from "../../../utils/calc/pxToVh";
import { theme } from "../../../styles/theme";

const BloodProgress = ({
  title,
  target,
  isStandard,
  min,
}: {
  title: string;
  target: number;
  isStandard?: boolean;
  min?: number;
}) => {
  return (
    <DataContainer>
      <DataInProgress>
        <Text
          content={title}
          fontSize={theme.size.xxs}
          fontWeight={theme.weight.light}
          color={theme.color.gray100}
        />
        <WidthBarLayout $width={pxToVh(205)}>
          <ProgressValue $target={target} $isStandard={isStandard} $min={min} />
        </WidthBarLayout>
      </DataInProgress>
    </DataContainer>
  );
};

export default BloodProgress;
