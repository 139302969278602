export interface IInbodyQuery {
  gender: number | string;
  age: number;
  height: number;
  weight: number;
  bmi: number;
  smm: number;
  smmMin: number;
  smmMax: number;
  tbw: number;
  tbwMin: number;
  tbwMax: number;
  pbf: number;
  pbfMin: number;
  pbfMax: number;
  whr: number;
  whrMin: number;
  whrMax: number;
}

export const defaultQuery: IInbodyQuery = {
  gender: 0,
  age: 0,
  height: 0,
  weight: 0,
  bmi: 0,
  smm: 0,
  smmMin: 0,
  smmMax: 0,
  tbw: 0,
  tbwMin: 0,
  tbwMax: 0,
  pbf: 0,
  pbfMin: 0,
  pbfMax: 0,
  whr: 0,
  whrMin: 0,
  whrMax: 0,
};

export const inbodyQueryItems = [
  "gender",
  "age",
  "height",
  "weight",
  "bmi",
  "smm",
  "smmMin",
  "smmMax",
  "tbw",
  "tbwMin",
  "tbwMax",
  "pbf",
  "pbfMin",
  "pbfMax",
  "whr",
  "whrMin",
  "whrMax",
];

export type TBmiGrade =
  | "grade_1"
  | "grade_2"
  | "grade_3"
  | "grade_4"
  | "grade_5";
