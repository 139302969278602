import Title from "../texts/title/Title";
import ResultComment from "../texts/resultComment/ResultComment";
import Better from "../better/Better";
import ResultImage from "../resultImage/ResultImage";
import { calcGlugose } from "../../utils/calc/CalcResult";
import { GlucoseGrade } from "../../utils/comment/GlucoseGrade";
import Text from "../atoms/text/Text";
import { theme } from "../../styles/theme";
import {
  GlucoseLayout,
  TextToCenter,
} from "../texts/resultComment/ResultCommentStyles";
import { DocumentTitle } from "../../utils/DocumentTitle";
import ShareFooter from "../shareFooter/ShareFooter";
import LineChart from "../graph/chart/lineChart/LineChart";
import { useLocation } from "react-router-dom";
import { HealthMentController } from "../../utils/comment/HealthMentStore";
import { useLocalization } from "../../hooks/useLocalization";

const GlucoseTemplate = () => {
  const location = useLocation();
  const { gluoseComment } = HealthMentController();
  const glucoseGrade = GlucoseGrade();
  const translation = useLocalization();
  const params = new URLSearchParams(location.search);
  const urlItem = ["g1", "g2", "g3", "g4", "g5", "g6"];

  let queryData = {};
  const query: number[] = [];

  urlItem.forEach((item) => {
    const value = Number(params.get(item));
    if (value && value !== 0) {
      queryData = Object.assign(queryData, {
        [item]: value,
      });
      query.push(value);
    }
  });
  const lastData = query.at(-1) || 0;
  const calcResult = calcGlugose(lastData);

  const range = { min: Math.min(...query), max: Math.max(...query) };

  DocumentTitle(
    `${translation.glucose_result_title} - ${glucoseGrade[calcResult].type}`
  );

  const weekData: { x: number | string; y?: number }[] = query.map((m, i) => ({
    x: i + 1,
    y: m,
  }));
  weekData.unshift({ x: "", y: query[0] });
  weekData.push({ x: " " });

  const data = [
    {
      id: "HR",
      data: weekData,
    },
  ];

  let type = "down";
  const [prev, target] = query.slice(-2);
  const avgQuery = query.reduce((a, c, i) => {
    a += c;
    if (i === query.length - 1) {
      return Number((a / query.length).toFixed(0));
    } else {
      return a;
    }
  }, 0);
  if (!prev) type = "no";
  else type = prev > target ? "down" : "up";

  const fitCore = glucoseGrade[calcResult];

  const transFitSubTitle = fitCore.comment[type].subComment
    .replace("@1", `${lastData}`)
    .replace("@2", String(lastData - 100))
    .replace("@3", `${query.length}`)
    .replace("@4", `${avgQuery - Number(lastData)}`);

  return (
    <>
      <GlucoseLayout>
        <Title content={fitCore.title} subTitle={fitCore.sub} />
        <ResultImage
          title={translation.result_title}
          grade={fitCore.type}
          value={`${lastData}mg/dL`}
          comment={
            calcResult === "good"
              ? translation.glucose_result_low_text.replace(
                  "@",
                  String(Math.abs(Number(lastData) - 100))
                )
              : translation.glucose_result_high_text.replace(
                  "@",
                  String(Number(lastData) - 100)
                )
          }
          icon={fitCore.svg}
        />
        <LineChart
          title={translation.glucose_line_chart_title}
          data={data}
          range={range}
        />
        <ResultComment title={[fitCore.comment[type].titleComment]} sub="" />
        <TextToCenter>
          <Text
            content={transFitSubTitle}
            fontSize={theme.size.small}
            fontWeight={theme.weight.light}
            letterSpacing={theme.letterSize.x}
          />
        </TextToCenter>
        <Better
          title={translation.better_title}
          category={[
            gluoseComment.category.food,
            gluoseComment.category.exercise,
            gluoseComment.category.water,
          ]}
          comment={[
            gluoseComment.categoryComment.food,
            gluoseComment.categoryComment.exercise,
            gluoseComment.categoryComment.water,
          ]}
        />
      </GlucoseLayout>
      <ShareFooter
        shareTitle={`${translation.glucose_result_title} - ${fitCore.type}`}
      />
    </>
  );
};

export default GlucoseTemplate;
