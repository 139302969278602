import styled from "styled-components";
import { pxToVh } from "../../../../utils/calc/pxToVh";

export const BloodResultContainer = styled.div`
  margin-top: ${pxToVh(12)};
  width: ${pxToVh(341)};
  height: ${pxToVh(242)};
  border: ${({ theme }) => `1px solid ${theme.color.gray100}`};
  border-radius: ${pxToVh(16)};
  padding: ${pxToVh(22)} ${pxToVh(22)};
`;

export const RowSvg = styled.div`
  display: flex;
  align-items: center;
  gap: ${pxToVh(6)};
`;

export const BloodContentContainer = styled.div`
  padding: 0 ${pxToVh(7)};
`;

export const BloodInfoContainer = styled.div`
  margin-top: ${pxToVh(22)};
  display: flex;
  flex-direction: column;
  gap: ${pxToVh(10)};
`;

export const BloodInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${pxToVh(7.5)} 0;
`;

export const BloodLine = styled.div`
  width: 100%;
  height: ${pxToVh(1)};
  background-color: ${({ theme }) => theme.color.gray100};
  margin-top: ${pxToVh(13)};
  margin-bottom: ${pxToVh(20)};
`;
