import { styled } from "styled-components";
import { pxToVh } from "../../../utils/calc/pxToVh";

export const NutritionInfoLayout = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: ${pxToVh(10)};
`;

export const InfoDetailTextLayout = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
