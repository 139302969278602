import { keyframes } from "styled-components";

export const FadeIn = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-20%) ;
    }
    100% {
        opacity: 1;
        transform: translateY(0) ;
    }
`;

export const FadeUp = keyframes`
    0% {
        opacity: 0.5;
        transform: translateY(10%);
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;
