import styled, { createGlobalStyle } from "styled-components";
import { pxToVh } from "../utils/calc/pxToVh";
import Bold from "../assets/fonts/Pretendard-Bold.woff2";
import Regular from "../assets/fonts/Pretendard-Regular.woff2";
import SemiBold from "../assets/fonts/Pretendard-SemiBold.woff2";

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "Pretendard-Bold";
        src: local("Pretendard-Bold"), url(${Bold}) format('woff2'); 
        font-weight: normal;
    }
    @font-face {
        font-family: "Pretendard-Regular";
        src: local("Pretendard-Regular"), url(${Regular}) format('woff2'); 
        font-weight: normal;
    }
    @font-face {
        font-family: "Pretendard-SemiBold";
        src: local("Pretendard-SemiBold"), url(${SemiBold}) format('woff2'); 
        font-weight: normal;
    }
    
    * {
        font-family: "Pretendard", sans-serif;
        box-sizing: border-box;
    }
    
    body {
        margin: 0;
        background-color: #000;
        color: #fff;
        width: 100vw;
        min-height: 667px;
    }
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 ${pxToVh(24)};
`;
