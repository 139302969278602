import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import InbodyTemplate from "./components/templates/InbodyTemplate";
import DeepTemplate from "./components/templates/DeepTemplate";
import DeepController from "./components/controller/DeepController";
import InbodyController from "./components/controller/InbodyController";
import GlucoseTemplate from "./components/templates/GlucoseTemplate";
import BloodTemplate from "./components/templates/BloodTemplate";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/health/:category" element={<DeepController />} />
        <Route path="/health/result/:category" element={<DeepTemplate />} />
        <Route path="/glucose/:glucose" element={<GlucoseTemplate />} />
        <Route path="/inbody/:query" element={<InbodyController />} />
        <Route path="/inbody/result/:category" element={<InbodyTemplate />} />
        <Route path="/blood/:q" element={<BloodTemplate />} />
        <Route
          path="*"
          element={<Navigate to="/health/query?hr=54&rep=23&psi=31&msi=53" />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
