export default {
  hr: "心拍数",
  rep: "呼吸数",
  psi: "疲労度",
  msi: "ストレス",
  gender: "性別",
  male: "男性",
  female: "女性",
  document_title_result_hr: "健康結果",
  document_title_result_inbody: "InBody 結果",
  standard_range: "標準範囲",
  count: "回",
  per_minute: "回 / 分",
  healthcare_compare_normal: "標準平均値と比較しました。",
  healthcare_compare: "測定結果の要約",
  healthcare_hr_good_title:
    "心拍数が標準範囲です。#このまま良く管理してください！",
  healthcare_hr_normalup_title: "心拍数が高く#注意が必要です。",
  healthcare_hr_normaldown_title: "心拍数が低いです。#注意が必要です。",
  healthcare_hr_badup_title: "心拍数が非常に高いです。#管理が必要です。",
  healthcare_hr_baddown_title: "心拍数が非常に低いです。#管理が必要です。",

  healthcare_hr_good_resultTitle:
    "心拍数が標準範囲です。#このまま維持してください！",
  healthcare_hr_normalup_resultTitle: "標準平均数値より心拍数が#@回高いです。",
  healthcare_hr_normaldown_resultTitle:
    "標準平均数値より心拍数が#@回低いです。",
  healthcare_hr_badup_resultTitle: "標準平均数値より心拍数が#@回高いです。",
  healthcare_hr_baddown_resultTitle: "標準平均数値より心拍数が#@回低いです。",

  // healthcare_hr_good_subTitle:
  //   "心拍数が標準範囲にあります。これは一般的に健康な心臓活動を示しますが、健康な生活習慣を維持し、定期的な健康診断を忘れないようにしましょう。ストレスを避け、継続的な運動やバランスの取れた食事が重要です。",
  // healthcare_hr_normalup_subTitle:
  //   "心拍数が平均よりも高く測定されました。速い心拍数はストレス、カフェイン摂取、不安、水分不足など、さまざまな原因で引き起こる可能性があります。継続的な休息とストレス管理が重要です。また、持続的な高い心拍数や他の症状がある場合は、専門家と相談してください。",
  // healthcare_hr_normaldown_subTitle:
  //   "心拍数が平均よりも低く測定されました。低い心拍数は、トレーニングされた運動選手や特定の疾患を持つ人々で見られることがあります。症状や不快感がある場合は、専門家との相談が必要です。",
  // healthcare_hr_badup_subTitle:
  //   "心拍数が過度に高く測定されています。非常に高い心拍数は心臓の負担を増やす可能性があり、心臓の機能障害や他の健康問題の可能性を示すことがあります。すぐに休息を取り、呼吸を整えてください。",
  // healthcare_hr_baddown_subTitle:
  //   "心拍数が非常に低く測定されました。極端に低い心拍数は、心臓の正常な機能に問題がある可能性があります。めまい、疲労、胸の痛みなどの症状が伴う場合は、すぐに医療機関を受診してください。",

  healthcare_hr_good_subTitle:
    "安定時の心拍数が標準範囲にあり、健康な状態を維持しています。定期的な運動と健康的な食事を維持し、ストレスを効果的に管理することが重要です。引き続き健康を維持するために努力してください。",
  healthcare_hr_normalup_subTitle:
    "心拍数が継続的に速い場合、心臓病、甲状腺機能亢進症、心不全、不規則な心拍数など、さまざまな要因が心拍数を増加させる可能性があります。継続的に速い心拍数は、血圧の上昇、血管の損傷、心臓の負担の増加などの問題を引き起こす可能性があるため、医療専門家に相談することが重要です。生活様式、食習慣、ストレス管理などを通じて心拍数を正常化させることが重要です。",
  healthcare_hr_normaldown_subTitle:
    "心拍数が持続的に遅い場合、心臓病、低血圧、心筋炎、甲状腺機能低下、心室制動、心不全、心筋梗塞などが心拍数減少の原因となります。心拍数が遅すぎると、血液循環が十分に行われず、脳や他の臓器に酸素が不足する可能性があり、心不全などの心血管疾患のリスクが増加する可能性があります。したがって、心拍数が継続的に遅い場合は、医療専門家に相談して原因を明らかにし、適切な治療を受けることが重要です。ただし運動選手のように運動をたくさんした人の中では低い場合があります。運動によって心臓の大きさが大きくなり、一度に供給できる血液の量が十分なためです。",
  healthcare_hr_badup_subTitle:
    "心拍数が継続的に速い場合、心臓病、甲状腺機能亢進症、心不全、不規則な心拍数など、さまざまな要因が心拍数を増加させる可能性があります。継続的に速い心拍数は、血圧の上昇、血管の損傷、心臓の負担の増加などの問題を引き起こす可能性があるため、医療専門家に相談することが重要です。生活様式、食習慣、ストレス管理などを通じて心拍数を正常化させることが重要です。",
  healthcare_hr_baddown_subTitle:
    "心拍数が持続的に遅い場合、心臓病、低血圧、心筋炎、甲状腺機能低下、心室制動、心不全、心筋梗塞などが心拍数減少の原因となります。心拍数が遅すぎると、血液循環が十分に行われず、脳や他の臓器に酸素が不足する可能性があり、心不全などの心血管疾患のリスクが増加する可能性があります。したがって、心拍数が継続的に遅い場合は、医療専門家に相談して原因を明らかにし、適切な治療を受けることが重要です。ただし運動選手のように運動をたくさんした人の中では低い場合があります。運動によって心臓の大きさが大きくなり、一度に供給できる血液の量が十分なためです。",

  healthcare_rep_good_title:
    "呼吸数が標準範囲です。#このまま良く管理してください！",
  healthcare_rep_normal_title: "呼吸数が低く#注意が必要です。",
  healthcare_rep_bad_title: "呼吸数が高く#注意が必要です。",

  healthcare_rep_good_resultTitle:
    "呼吸数が標準範囲です。#このまま維持してください！",
  healthcare_rep_normal_resultTitle: "標準平均数値より呼吸数が#@回低いです。",
  healthcare_rep_bad_resultTitle: "標準平均数値より呼吸数が#@回高いです。",

  // healthcare_rep_good_subTitle:
  //   "呼吸数が標準範囲にあります。これは健康な状態を意味することがありますが、個人の状態や他の症状によって異なる場合があります。引き続き自身の体調をチェックし、異常が見つかった場合は専門家の助けを求めることが良いでしょう。",
  // healthcare_rep_normalup_subTitle:
  //   "呼吸数が平均よりも高く測定されています。速い呼吸はストレス、身体的問題などさまざまな原因で発生する可能性があります。もし速い呼吸が継続したり、他の症状と一緒に感じる場合は、専門家と相談することを検討してください。",
  // healthcare_rep_normaldown_subTitle:
  //   "呼吸数が平均よりも低く測定されています。呼吸数の減少はさまざまな原因に起因することがあり、時には健康問題の兆候になることがあります。もし他の症状や不快感がある場合は、ためらわずに専門家と相談してください。",
  // healthcare_rep_badup_subTitle:
  //   "呼吸数が平均よりも高く測定されています。速い呼吸はストレス、身体的問題などさまざまな原因で発生する可能性があります。もし速い呼吸が継続したり、他の症状と一緒に感じる場合は、専門家と相談することを検討してください。",

  healthcare_rep_good_subTitle:
    "呼吸数が標準範囲内にあります。これは健康な状態を示す可能性がありますが、個人の状態や他の症状によって異なる場合があります。引き続き自分の身体の状態をチェックし、異常が見つかった場合は専門家の助けを求めることが賢明です。",
  healthcare_rep_normal_subTitle:
    "呼吸数が平均よりも低く測定されました。呼吸数の減少は様々な原因によるものであり、時には健康問題の兆候である可能性があります。他の症状や不快感がある場合は、専門家と相談することを躊躇しないでください。",
  healthcare_rep_bad_subTitle:
    "呼吸数が平均よりも高く測定されました。速い呼吸は、ストレスや身体的問題など、様々な原因で発生することがあります。速い呼吸が持続する場合や他の症状が伴う場合は、専門家との相談を検討する必要があります。",

  healthcare_psi_good_title:
    "疲労度が標準範囲内です。#引き続き良く管理してください！",
  healthcare_psi_normalup_title: "疲労度が少し高く#注意が必要です。",
  healthcare_psi_normaldown_title:
    "疲労度が少し低く#バランスの取れた管理が必要です。",
  healthcare_psi_bad_title: "過負荷状態！#疲労回復管理が必要です。",

  healthcare_psi_good_resultTitle:
    "疲労度が標準範囲内です。#安定を保ってください。",
  healthcare_psi_normalup_resultTitle: "標準平均数値より疲労度が#@高いです。",
  healthcare_psi_normaldown_resultTitle: "標準平均数値より疲労度が#@低いです。",
  healthcare_psi_bad_resultTitle: "標準平均数値より疲労度が#@高いです。",

  // healthcare_psi_good_subTitle:
  //   "疲労度が標準範囲にあります。日常の中で規則的なストレス管理とポジティブな生活習慣の重要性をすでにご存知のようですね。このように安定した心を保ちながら、健康な日常を楽しんでください。",
  // healthcare_psi_normalup_subTitle:
  //   "現在、疲労度が高く測定されています。日常生活やストレス、睡眠不足などさまざまな原因で疲労が増加する可能性があります。適切な休息やバランスの取れた食事、水分摂取を増やすことで体の回復を助けます。",
  // healthcare_psi_normaldown_subTitle:
  //   "現在、疲労度が低く測定されています。これは相対的に活力があり、十分にリチャージされていることを意味します。ただし、持続的に低い疲労度は過度の活動を意味することもありますので、適切な休息とバランスの取れた生活習慣の重要性を忘れないでください。",
  // healthcare_psi_bad_subTitle:
  //   "現在、疲労度が高く測定されています。疲労回復管理に特に注意を払うべき時期です。健康な日常のために休息を取り、自己管理に時間を投資してみてください。",

  healthcare_psi_good_subTitle:
    "現在の疲労度指数は低く測定されています。これは比較的活力があり、再充電がうまくいっていることを意味します。定期的な運動と食事を維持し、十分な休息をとりましょう。",
  healthcare_psi_normal_subTitle:
    "疲労度が標準範囲にありますね。日常の中で、定期的なストレス管理と肯定的な生活習慣の重要性をすでに知っていると思います。よく休んで、正しい休憩をとりながら、体と心を握ることが大切です。バランスの取れた活動とリラクゼーションを維持し、自分のエネルギーを管理しましょう。引き続き健康で活気のある日常を維持できるように努力してください。",
  healthcare_psi_bad_subTitle:
    "疲労度が高く測定されました。疲労が続くと、さまざまな身体的および精神的な問題が発生する可能性があります。一般的に疲労は体や精神的に疲れたときに現れ、寝ているにもかかわらず十分な休息を取らない状態でも続くことが多いです。疲労が続くと、うつ病や不安障害の発生リスクの増加、免疫力の低下、筋肉痛、消化問題の誘発、集中力、記憶力の低下などの問題が発生することがあります。したがって、疲労が続く場合は、適切な休息とストレス管理、健康な食習慣、十分な運動などを通じて疲労を管理し、もし持続する場合は、医療専門家に相談して基本的な健康問題や基礎疾患の可能性を検討することが重要です。",

  healthcare_msi_good_title:
    "ストレスが標準範囲です。#引き続き良く管理してください！",
  healthcare_msi_normalup_title: "ストレスが少し高く#注意が必要です。",
  healthcare_msi_normaldown_title:
    "ストレスが少し低く#バランスの取れた管理が必要です。",
  healthcare_msi_bad_title: "過負荷状態！#ストレス管理が必要です。",

  healthcare_msi_good_resultTitle:
    "ストレスが標準範囲内です。#安定を保ってください。",
  healthcare_msi_normalup_resultTitle: "標準平均数値よりストレスが#@高いです。",
  healthcare_msi_normaldown_resultTitle:
    "標準平均数値よりストレスが#@低いです。",
  healthcare_msi_bad_resultTitle: "標準平均数値よりストレスが#@高いです。",

  // healthcare_msi_good_subTitle:
  //   "ストレス指数が標準範囲にあります。日常の中で規則的なストレス管理とポジティブな生活習慣の重要性をすでにご存知のようですね。このように安定した心を保ちながら、健康な日常を楽しんでください。",
  // healthcare_msi_normalup_subTitle:
  //   "現在、ストレスが高く測定されています。日常生活やストレス、睡眠不足などさまざまな原因でストレスが増加する可能性があります。適切な休息やバランスの取れた食事、水分摂取を増やすことで体の回復を助けます。",
  // healthcare_msi_normaldown_subTitle:
  //   "現在、ストレス指数が低く測定されています。これは相対的に活力があり、十分にリチャージされていることを意味します。ただし、持続的に低い疲労度は過度の活動を意味することもありますので、適切な休息とバランスの取れた生活習慣の重要性を忘れないでください。",
  // healthcare_msi_bad_subTitle:
  //   "現在、ストレス指数が高く測定されています。ストレス管理に特に注意を払うべき時期です。健康な日常のために休息を取り、自己管理に時間を投資してみてください。",

  healthcare_msi_good_subTitle:
    "現在のストレスレベルは低く測定されています。これは、相対的に活力があり、うまく充電されていることを意味します。日常の活動に満足を感じ続け、体と心を休息させてください。趣味の活動や友人との時間を楽しんでください。自分だけのストレス管理技術を学び、ポジティブな思考を維持してください。",
  healthcare_msi_normal_subTitle:
    "ストレスレベルは標準範囲内ですが、継続的なストレス管理が必要です！ストレスは、心理的、環境的、社会的要因など様々な原因で発生することがあります。ストレスを感じると、ストレスホルモンが放出され、心拍数、血圧、呼吸が高まり、疲労や頭痛、睡眠障害などの症状が現れることがあります。そのため、瞑想、ストレス、運動を通じて自分だけのストレス解消法を見つけて管理することが重要です。日光に当たるとセロトニンが活性化され、ストレスを解消することができます。少し時間を取って、日光を浴びながら散歩するのはどうでしょうか？",
  healthcare_msi_bad_subTitle:
    "ストレスが高く測定されました。日常活動やストレス、睡眠不足など様々な原因でストレスが増加する可能性があります。ストレスが持続すると、身体的、精神的な疾患が発生する可能性があります。したがって、ストレスを管理し、減らすことは、身体と精神の健康を維持する上で非常に重要です。何よりも、規則正しい生活習慣を持つことがストレス管理の始まりです。実践可能な管理方法には、ゆっくりと食事をする習慣を持ち、6〜8時間程度十分に睡眠を取ることが望ましいです。規則的な運動、ストレス管理技術（例：瞑想、筋肉リラクゼーション）、趣味や関心事に時間を投資することもストレス管理に良い方法です。ストレスが持続し、健康に悪影響を与えることが懸念される場合は、医療専門家と相談することが重要です。",

  barchart_me: "使用者",
  barchart_standard: "標準平均数値",
  inbody_header_title: "インボディ検査結果です。",
  inbody_header_subtitle: "各項目をタップしてください。",
  check_url: "URLをご確認ください",
  normal: "正常",
  warning: "注意が必要",
  management: "管理が必要",
  standard: "標準内",
  below_standard: "標準未満",
  above_standard: "標準超",
  healthcare_normal_0: "全般的に健康に#積極的な管理が必要な段階です。",
  healthcare_normal_1: "健康に対する関心が#必要な段階です。",
  healthcare_normal_2hr: "心拍数の項目に特に注意が#必要な段階です。",
  healthcare_normal_2rep: "呼吸数の項目に特に注意が#必要な段階です。",
  healthcare_normal_2psi: "疲労度の項目に特に注意が#必要な段階です。",
  healthcare_normal_2msi: "ストレスの項目に特に注意が#必要な段階です。",
  healthcare_normal_3hr: "心拍数の項目には注意が#必要な段階です。",
  healthcare_normal_3rep: "呼吸数の項目には注意が#必要な段階です。",
  healthcare_normal_3psi: "疲労度の項目には注意が#必要な段階です。",
  healthcare_normal_3msi: "ストレスの項目には注意が#必要な段階です。",
  healthcare_normal_4: "すべて正常です！#このまま維持してください！",
  footer_valid: "モバイルでお試しください。",
  footer_chrome_safari: "ChromeとSafariをご利用ください。",
  footer_copy: "複写",
  footer_share: "コンユ",
  footer_clipboard: "クリップボードにコピーされました。",

  blood_vessel_result_a_type: "標準(aタイプ)",
  blood_vessel_result_b_type: "標準(bタイプ)",
  blood_vessel_result_c_type: "注意(cタイプ)",
  blood_vessel_result_d_type: "注意(dタイプ)",
  blood_vessel_result_e_type: "診断が必要(eタイプ)",
  blood_vessel_result_f_type: "診断が必要(fタイプ)",
  blood_vessel_result_g_type: "診断が必要(gタイプ)",
  blood_vessel_result_health_title: "血管の状態が良好です。",
  blood_vessel_result_waring_title: "血管の健康に注意が必要です。",
  blood_vessel_result_danger_title: "専門家との相談が必要です。",
  blood_vessel_result_summary_health_text:
    "血管タイプが「標準」で、20〜30代の波形を示しており、良好です。適切な生活習慣と定期的な運動で現在の状態を維持してください。",
  blood_vessel_result_summary_waring_text:
    "血管タイプが「注意」で、40〜50代の波形を示しており、注意が必要です。継続的な管理と生活習慣の改善を検討してください。",
  blood_vessel_result_summary_danger_text:
    "血管タイプが「診断が必要」で、60〜70代の波形を示しており、管理が必要です。専門家との相談と生活習慣の改善が急務です。",
  blood_vessel_result_button_back: "ホーム",
  blood_vessel_result_button_move_mobile_page: "モバイルで受け取る",
  blood_vessel_result_measure_date_text: "測定基準",
  blood_vessel_result_user_age_text: "(満)@歳",
  blood_vessel_summary_result_title_text: "結果の概要",
  blood_vessel_summary_result_blood_vessel_health_type_title_text:
    "血管の健康度タイプは3タイプ(正常、注意、管理)で、#血管の健康指数は低いほど良いです。",
  blood_vessel_summary_result_blood_vessel_health_type_text:
    "血管の健康度タイプ",
  blood_vessel_summary_result_blood_vessel_health_index_text: "血管の健康指数",
  blood_vessel_summary_result_blood_vessel_health_type_title_dialog_button:
    "血管の健康度タイプとは何ですか？",
  blood_vessel_summary_result_blood_vessel_health_index_chart_title_dialog_button:
    "血管の健康指数の波形はどのように見るのですか？",
  blood_vessel_result_detail_title_text: "測定の詳細結果",
  blood_vessel_result_detail_blood_vessel_age_title_text:
    "血管の健康年齢は、同じ年齢層に対するユーザーの血管年齢です。",
  blood_vessel_tip_food_title_text: "食事",
  blood_vessel_tip_exercise_title_text: "運動",
  blood_vessel_tip_check_up_title_text: "検診",
  blood_vessel_tip_rest_title_text: "休息",
  blood_vessel_tip_food_body:
    "飽和脂肪、トランス脂肪、ナトリウムの摂取を減らし、植物性の多様な食品（果物、野菜、全粒穀物、ナッツ）を摂取してください。また、過度なアルコール摂取は血圧を上げる可能性がありますので、適度な量でアルコールを摂取してください。",
  blood_vessel_tip_exercise_body:
    "血管の健康のために、中程度から低強度の有酸素運動（散歩、サイクリング）を週3〜5回、各30分間行ってください。また、ストレッチやヨガを行い、柔軟性を高め血液循環を改善してください。",
  blood_vessel_tip_check_up_body:
    "規則正しい健康診断を通じて血管の状態を確認してください。特にコレステロール、血圧、血糖値を管理し、必要に応じて専門家と相談して血管の健康を改善する方法を見つけてください。",
  blood_vessel_tip_rest_body:
    "適切な休息は血管の健康に不可欠です。定期的に休憩を取り、ストレスを軽減するために深い睡眠を優先し、瞑想や深呼吸などで日常の緊張を解消して血管の健康を維持してください。",
  blood_vessel_measuring_title: "私たちは#あなたの血管状態を測定しています。",
  blood_vessel_measuring_count: "@秒ほど残っています",
  blood_vessel_measuring_subtitle: "測定中は#安定した状態を保ってください。",
  blood_vessel_measuring_my_ppg: "私のPPG（脈波光電法）",
  blood_vessel_measuring_chart_title:
    "このチャートは心拍ごとに血液がどのように流れるかを示しています。",
  blood_vessel_measuring_back: "戻る",
  blood_vessel_count: "秒",
  m_d_hh_mm: "月@日",
  ppg_avg_title: "平均脈拍",
  ppg_state_title: "脈拍の状態",
  user_ppg_result_title: "使用者の数値",
  age_ppg_avg_title: "年齢別平均",
  user: "ユーザー",
  age_range_ppg_avg_title: "年齢層別平均",
  low: "低い",
  high: "高い",
  average: "平均",
  blood_vessel_health_title: "血管の健康",
  user_age: "使用者年齢",
  blood_vessel_age: "血管の年齢",
  compare_user_age: "ユーザーの年齢と比較",
  blood_vessel_health_type_dialog_title:
    "血管のタイプは#血管の内部構造を示します。",
  blood_vessel_health_type_dialog_subtitle:
    "大きく3つのタイプに分類されています。",
  blood_vessel_health_index_chart_type_dialog_title:
    "血管の健康指数の波形は#血管の弾力性を示します。",
  blood_vessel_health_index_chart_type_dialog_subtitle:
    "4つの段階に分かれています。",
  blood_vessel_health_age_dialog_title:
    "血管の健康は#血管の機能と状態を示します。",
  blood_vessel_health_age_dialog_subtitle: "分かりやすく説明します！",
  blood_vessel_value: "脈拍数値",
  blood_vessel_my_value: "私の数字",
  blood_vessel_measure_result: "血管の健康度の測定結果",
  blood_vessel_type: "タイプ",

  better_title: "これでより良くなります",

  better_category_food: "食事",
  better_category_exercise: "運動",
  better_category_breath: "呼吸法",

  better_title_food_hr_good: "低脂肪タンパク質、オメガ3が豊富な食品 ",
  better_title_food_hr_up: "マグネシウム、オメガ3、抗酸化物質を含む食事 ",
  better_title_food_hr_down: "オメガ3、食物繊維、カリウムを含む食事 ",
  better_title_food_rep_good:
    "抗酸化剤、オメガ-3、ビタミンD、マグネシウムが豊富な食品",
  better_title_food_rep_up: "マグネシウム、オメガ3、ビタミンBを含む食事 ",
  better_title_food_rep_down:
    "鉄分、ビタミンC、オメガ3、マグネシウムを含む食事 ",
  better_title_food_msi_up: "マグネシウム、オメガ3、ビタミンを含む食事",
  better_title_food_msi_down: "オメガ3、複合炭水化物、ビタミンが豊富な食品",
  better_title_food_psi_up: "鉄分、複合炭水化物、タンパク質、オメガ3を含む食事",
  better_title_food_psi_down: "タンパク質、鉄分、ビタミンが豊富な食品 ",

  better_title_exercise_hr_good:
    "有酸素運動と筋力トレーニングで正常な心拍数を維持する ",
  better_title_exercise_hr_up: "マグネシウム、オメガ3、抗酸化物質を含む食事 ",
  better_title_exercise_hr_down:
    "定期的な有酸素運動と筋力トレーニングで心臓の筋肉を強化する",
  better_title_exercise_rep_good:
    "有酸素運動、呼吸調整、筋力トレーニングで安定した状態を保つ ",
  better_title_exercise_rep_up:
    "ヨガ、瞑想、軽い散歩、ストレッチングで呼吸数を調整する ",
  better_title_exercise_rep_down:
    "有酸素運動、ヨガ、ストレッチングで心肺機能を向上させる ",
  better_title_exercise_msi_up: "マグネシウム、オメガ3、ビタミンを含む食事",
  better_title_exercise_msi_down:
    "定期的な有酸素運動、ヨガ、筋力トレーニング、瞑想で安定した状態を保つ",
  better_title_exercise_psi_up:
    "軽い有酸素運動とストレッチングで体の緊張を和らげる",
  better_title_exercise_psi_down:
    "筋力トレーニングとストレッチングで安定した状態を維持する ",

  // 음식
  better_comment_food_hr_good:
    "心拍数が正常範囲内である場合、心の健康と全体的な健康を促進するためには、全粒穀物、さまざまな果物と野菜、低脂肪のタンパク質源（鶏肉、魚、豆類）、オメガ3脂肪酸が豊富な魚、そして健康的な脂肪（アボカド、オリーブオイル、ナッツ）を含む多様でバランスの取れた食事が重要です。\n\n十分な水分摂取とカフェインや糖分の多い飲料の制限も心の健康を維持するために不可欠です。\n\nこれらの栄養素をバランス良く摂取することで、心疾患のリスクを減らし、心拍数を健康なレベルで維持することができます。 ",
  better_comment_food_hr_up:
    "心拍数が高い場合、マグネシウム（ほうれん草、アーモンド、黒豆）とオメガ3脂肪酸（サーモン、サバ、亜麻仁）が豊富な食品、および抗酸化剤が多い果物と野菜（ベリー類、ケール）、抗酸化物質が豊富な飲料（緑茶、ハーブティー）の摂取が推奨されます。\n\nカフェイン、アルコール、高糖分加工食品を避け、十分な水分補給と休息を取ることも、心拍数を安定させ、心血管の健康を改善する上で重要です。 ",
  better_comment_food_hr_down:
    "心拍数が標準平均数値より低い場合、オメガ3脂肪酸（サーモン、マグロ、亜麻仁、チアシード、クルミ）、食物繊維（野菜、果物、全粒穀物、豆類）、カリウム（バナナ、じゃがいも、ほうれん草、アプリコット）、抗酸化物質（ベリー類、ダークチョコレート、アーティチョーク、ナッツ）が豊富な食品の摂取がお勧めです。 \n\nこれらの食品は心拍数の安定化、心リズムの改善、消化の促進、血糖値及びコレステロールの調整、心と血管の健康保護に役立ち、心血管システムへの負担を軽減します。 \n\n継続的に低い心拍数については、専門医に相談することが重要です。",
  better_comment_food_rep_good:
    "呼吸数が標準範囲内である場合、抗酸化物質が豊富な果物と野菜（ベリー類、ほうれん草、ブロッコリー）、オメガ3脂肪酸（サーモン、クルミ）、ビタミンD（卵黄、脂肪分の多い魚）、マグネシウム（アーモンド、カボチャの種）を含む食品を摂取することが推奨されます。 \n\nこれらの栄養素は、細胞の保護、炎症の減少、呼吸筋機能のサポートに有益であり、バランスの取れた食事を通じて全体的な健康と呼吸健康を促進することができます。 ",
  better_comment_food_rep_up:
    "呼吸数が高い時、身体と精神の緊張を軽減し、落ち着かせるために、マグネシウム（ほうれん草、アーモンド、カボチャの種）、オメガ3脂肪酸（サーモン、亜麻仁、クルミ）、ビタミンB6とB12（鶏肉、サーモン、乳製品）が豊富な食品と、カモミール、ラベンダーのハーブティーの摂取が有益です。 \n\n十分な水分摂取も重要で、これらの栄養素と水分は、呼吸数が高い状態を和らげ、ストレス反応を減らすのに役立ちます。 \n\nこれらの食品と飲料を定期的に摂取することで、身体的、精神的な安定を促進することができます。 ",
  better_comment_food_rep_down:
    "呼吸数が標準範囲より低い場合、鉄分（ほうれん草、レンズ豆、赤身の肉）、ビタミンC（オレンジ、キウイ、ブロッコリー）、オメガ3脂肪酸（サーモン、亜麻仁）、マグネシウム（アーモンド、カボチャの種）を含む食事を摂取することが推奨されます。 \n\nこれらの栄養素は酸素化を促進し、鉄分の吸収を助け、呼吸筋のリラックスをサポートして、呼吸健康を改善し、全体的な健康を向上させることができます。 ",
  better_comment_food_msi_good:
    "ストレスが標準範囲内である場合、マグネシウム（ほうれん草、カボチャの種、アーモンド）、オメガ3脂肪酸（サーモン、亜麻仁、クルミ）、複合炭水化物（全粒粉のパン、オーツ麦、玄米）、ビタミンB群（全粒製品、卵、乳製品）、ビタミンC（オレンジ、キウイ、ブロッコリー）、トリプトファン（七面鳥、鶏肉、牛乳）を含む食事を摂取することで、身体と精神の健康を維持し、ストレスを管理することが推奨されます。 \n\nこれらの食品は、神経系を落ち着かせ、エネルギーレベルを維持し、ストレスホルモンを減らす効果があります。 \n\nバランスの取れた食事、定期的な運動、十分な睡眠、およびストレス管理技術の実践は、全体的な健康を向上させるために重要です。 ",
  better_comment_food_msi_up:
    "ストレスが高い時は、マグネシウム（ほうれん草、カボチャの種、アーモンド）、オメガ3脂肪酸（サーモン、亜麻仁、クルミ）、ビタミンB群（全粒製品、卵、乳製品）、ビタミンC（オレンジ、キウイ、ブロッコリー）が豊富な食品を摂取して神経系を落ち着かせ、情緒的安定を促進し、ストレスホルモンのレベルを下げることが重要です。 \n\nさらに、トリプトファンが豊富なタンパク質食品（七面鳥、鶏肉、牛乳）の摂取と十分な水分摂取は、気分を改善し、ストレス反応を軽減するのに役立ちます。 \n\nこのようなバランスの取れた食事は、身体的、精神的なウェルビーイングを維持し、ストレスを効果的に管理するために不可欠です。 ",
  better_comment_food_psi_good:
    "疲労度が標準範囲内である場合、エネルギーレベルを維持し活力を高めるためには、複合炭水化物（全粒粉のパン、オーツ麦、玄米）、タンパク質（鶏肉、魚、豆類、ナッツ）、鉄分（ほうれん草、赤身の肉、レンズ豆）、オメガ3脂肪酸（サーモン、亜麻仁、チアシード）を含む栄養豊富な食品を継続的に摂取することが推奨されます。 \n\nまた、ビタミンとミネラルが豊富な果物と野菜の摂取と十分な水分摂取は、全体的な健康を向上させ、エネルギーレベルを高めるのに重要です。 \n\nこのようなバランスの取れた食事は、日常生活での健康を維持し、疲労を管理するのに役立ちます。 ",
  better_comment_food_psi_up:
    "疲労を減らし、エネルギーを高めるためには、鉄分が豊富な食品（ほうれん草、赤身の肉、レンズ豆）、複合炭水化物（全粒粉のパン、オーツ麦、玄米）、タンパク質（鶏肉、魚、豆類、ナッツ）、オメガ3脂肪酸（サーモン、亜麻仁、チアシード）を含むバランスの良い食事と十分な水分摂取が重要です。 \n\nこれらの栄養素は、日常生活でのエネルギーレベルを高め、持続的な疲労を管理するのに役立ちます。 ",

  // 운동
  better_comment_exercise_hr_good:
    "心拍数が標準平均数値の時、心の健康を維持し向上させるために、有酸素運動（速歩き、ジョギング、自転車、水泳、ダンス）と筋力トレーニング（重量挙げ、ボディウェイト運動、レジスタンスバンド運動）を含む様々な活動を定期的に行うことが推奨されます。 \n\nこれらの運動は心血管システムを強化し、心拍数を改善し、筋肉を強化して心の効率を高めます。 \n\n運動計画を立てる際には、個人の体力レベルと健康状態を考慮し、必要に応じて専門家の助言を求めることが重要です。",
  better_comment_exercise_hr_up:
    "心拍数が高い時は、心に過度の負担をかけずに心血管システムを強化できる低〜中強度の有酸素運動を選択することが重要です。散歩や軽量ダンベルを使用した軽い筋力トレーニングは、心拍数を適切に管理しながら心の健康を改善する効果的な方法です。\n\n運動前に医師や健康専門家と相談し、安全に運動計画を立て、過度な強度を避け、体力レベルに合わせて調整することが推奨されます。 ",
  better_comment_exercise_hr_down:
    "心拍数が標準平均数値より低い場合、心筋を強化し血液循環を改善するために、有酸素運動（歩行、ジョギング、水泳、自転車）と筋力トレーニング（軽いウェイトトレーニング、抵抗バンド運動）を含む運動を検討することが良いでしょう。 \n\nこれらの活動は心拍数を自然に高め、心の効率を改善するのに有益です。 \n\n運動を始める前に医師と相談し、個人の健康状態と体力レベルに合った適切な運動計画を立てることが重要であり、継続的に低い心拍数に対して専門家の助言を求めることが推奨されます。",
  better_comment_exercise_rep_good:
    "呼吸数が標準である時、身体と呼吸機能を強化し、全体的な健康を維持するために、有酸素運動（歩行、ジョギング、自転車、水泳）、ヨガやピラティス、筋力トレーニングを含む様々な運動を定期的に行うことが良いでしょう。 \n\nこれらの運動は心臓の健康と呼吸の効率を向上させ、心肺機能と筋力を高め、ストレス管理と精神的なウェルビーイングを促進することができます。 \n\n個人の体力と興味に合わせて運動計画を多様化し選択することが重要です。 ",
  better_comment_exercise_rep_up:
    "呼吸数が高い時、身体と心を落ち着かせ、呼吸を調整するために、ヨガ、瞑想またはマインドフルネス、軽い散歩やジョギング、ストレッチやピラティスなどの運動を選択することが重要です。 \n\nこれらの活動は体と心の緊張を解放し、ストレスホルモンを減少させ、深く規則的な呼吸を促進するのに役立ちます。定期的な実践は、身体的、精神的な安定を促進し、ストレスを軽減することができます。",
  better_comment_exercise_rep_down:
    "呼吸数が標準範囲より低い場合、軽い有酸素運動（速歩き、軽いジョギング、水泳）、呼吸制御に焦点を当てたヨガやピラティス、定期的なストレッチを行うことが推奨されます。 \n\nこれらの活動は呼吸筋を強化し、心肺機能を向上させ、呼吸パターンを改善するのに有益です。 \n\n個人の体力レベルと健康状態を考慮して運動計画を立てることが重要です。 ",
  better_comment_exercise_msi_good:
    "ストレスが標準範囲内である場合、身体と精神のバランスを保ち、ストレスを管理するために、有酸素運動（歩行、ジョギング、自転車、水泳）、ヨガとピラティス、筋力トレーニング（ウェイトトレーニング、抵抗バンド運動）、瞑想と呼吸運動を含む様々な活動を継続的に行うことが推奨されます。 \n\nこれらの活動は、心を強化し、体の緊張を解き、ポジティブな気分を向上させ、精神的な明瞭さを高めるなど、ストレスホルモンのレベルを下げるのに役立ちます。 \n\n定期的な実践は、ストレス管理と全体的なウェルビーイングを向上させることができます。 ",
  better_comment_exercise_msi_up:
    "ストレスが高い時、有酸素運動（歩行、ジョギング、自転車、水泳）、ヨガとピラティス、瞑想と呼吸運動、そして筋力トレーニングを含む様々な形態の運動を選択することが、身体的、精神的な安定を見つけ、ストレスを減らすために重要です。 \n\nこれらの活動は、心を強化し、体と心の緊張を解きほぐし、気分を改善し、ストレスホルモンのレベルを下げるなど、肯定的な影響を提供します。 \n\n定期的な実践は、ストレス管理と健康的な生活スタイルの維持に役立ちます。 ",
  better_comment_exercise_psi_good:
    "疲労度が標準範囲内である場合、体力維持と健康促進のために、有酸素運動（歩行、ジョギング、自転車、水泳）、筋力トレーニング（ウェイトトレーニング、抵抗バンド運動）、柔軟性とバランスの運動（ヨガ、ピラティス、ストレッチング）を含む様々な運動を定期的に行うことが推奨されます。 \n\nこれらの活動はエネルギーレベルと気分を高め、ストレスを減らし、筋肉の緊張を解放し、全体的な健康を向上させます。 \n\n運動計画は、個人の体力レベルと興味に合わせて多様に構成することが重要です。 ",
  better_comment_exercise_psi_up:
    "疲れている時には、軽い有酸素運動（歩行、軽いジョギング、自転車）、ストレッチ、ヨガ、ピラティス、水泳など、ストレスを減らしエネルギーを高める活動が理想的です。 \n\nこれらの運動は身体に活力を与え、筋肉の緊張を解放し、血液循環を改善して、全体的な健康を向上させます。運動量は自身の体力レベルに合わせ、必要に応じて専門家の助言を求め、過度な疲労は避けることが重要です。 ",

  better_comment_breath:
    "深くゆっくりした呼吸や瞑想は心を落ち着かせ、ストレスを軽減するのに役立ちます。呼吸法は深く息を吸い込んでゆっくりと吐き出し、瞑想はリラックスした場所で呼吸に集中して行います。継続的な練習が重要です。",

  // glucose Comment
  better_glucose_food: "低脂肪、低塩分",
  better_glucose_exercise: "定期的な運動",
  better_glucose_water: "水分摂取",
  better_glucose_comment_food:
    "料理する際は、食用油を減らし、塩の代わりにハーブやスパイスで味付けしてみてください。また、加工食品より新鮮な果物や野菜を楽しむ習慣を身につけることも良い方法です。",
  better_glucose_comment_exercise:
    "定期的な運動は体と心の健康に必須です。運動は直接的に血糖を下げる効果があり、長期的に糖尿病の合併症を防ぎ、ストレス解消にも大いに役立ちます。日常生活に継続的な運動習慣を取り入れて、より健康で活力ある生活を楽しみましょう！",
  better_glucose_comment_water:
    "水は体の60%を占め、毒素の排出、体温調節、関節の保護、消化を支援し、栄養を運ぶのに不可欠です。健康を維持するためには、1日に2～3リットルの水分摂取が推奨されます。",

  better_blood_type_food: "食事",
  better_blood_type_exercise: "運動",
  better_blood_type_medical: "検診",
  better_blood_type_rest: "休息",

  better_blood_comment_food:
    "飽和脂肪、トランス脂肪、ナトリウムの摂取を減らし、植物性の多様な食品（果物、野菜、全粒穀物、ナッツ）を摂取してください。また、過度なアルコール摂取は血圧を上げる可能性がありますので、適度な量でアルコールを摂取してください。",
  better_blood_comment_exercise:
    "血管の健康のために、中程度から低強度の有酸素運動（散歩、サイクリング）を週3〜5回、各30分間行ってください。また、ストレッチやヨガを行い、柔軟性を高め血液循環を改善してください。",
  better_blood_comment_medical:
    "規則正しい健康診断を通じて血管の状態を確認してください。特にコレステロール、血圧、血糖値を管理し、必要に応じて専門家と相談して血管の健康を改善する方法を見つけてください。",
  better_blood_comment_rest:
    "適切な休息は血管の健康に不可欠です。定期的に休憩を取り、ストレスを軽減するために深い睡眠を優先し、瞑想や深呼吸などで日常の緊張を解消して血管の健康を維持してください。",

  //-----------------------inbody---------------------------
  inbody_result_feedback_normal_0:
    "全体的に健康に対する#積極的な管理が必要な段階です。",
  inbody_result_feedback_normal_1_smm:
    "骨格筋項目は正常ですが#注意が必要な段階です。",
  inbody_result_feedback_normal_1_tbw:
    "体水分項目は正常ですが#注意が必要な段階です。",
  inbody_result_feedback_normal_1_pbf:
    "体脂肪率項目は正常ですが#注意が必要な段階です。",
  inbody_result_feedback_normal_1_whr:
    "腹部脂肪率項目は正常ですが#注意が必要な段階です。",
  inbody_result_feedback_normal_2: "健康に対する関心が#必要な段階です。",
  inbody_result_feedback_normal_3_smm:
    "骨格筋項目に対する注意が#必要な段階です。",
  inbody_result_feedback_normal_3_tbw:
    "体水分項目に対する注意が#必要な段階です。",
  inbody_result_feedback_normal_3_pbf:
    "体脂肪率項目に対する注意が#必要な段階です。",
  inbody_result_feedback_normal_3_whr:
    "腹部脂肪率項目に対する注意が#必要な段階です。",
  inbody_result_feedback_normal_4: "すべて正常！#このまま維持してください！",
  permission_denied_message: "権限を許可してください",
  home: "ホーム",
  inbody_bmi_data: "BMI @",
  inbody_after_move: "後に移動",
  inbody_second: "@秒",
  inbody_ok: "確認",
  inbody_retry: "再試行",
  inbody_back: "戻る",
  inbody_do_back: "戻る",
  inbody_score: "@点",
  inbody_normal: "正常",
  inbody_needs_attention: "管理が必要",
  inbody_caution_needs: "注意が必要",
  inbody_rank: "@位",
  inbody_receive_mobile: "モバイルで受け取る",
  inbody_swipe_hint: "画面を横にスワイプしてください！",
  inbody_swipe_hint_content_easy_access_hint:
    "コンテンツを簡単に見ることができます。",
  inbody_complete: "完了",
  inbody_smm: "骨格筋",
  inbody_tbw: "体水分",
  inbody_pbf: "体脂肪率",
  inbody_whr: "腹部脂肪率",
  inbody_gram: "@g",
  inbody_percentage: "@%",
  inbody_do_connect: "接続する",
  inbody_cancel_connect: "接続キャンセル",
  inbody_standard_range: "標準範囲",
  within_standard: "標準内",
  inbody_carbohydrate: "炭水化物",
  inbody_fat: "脂肪",
  inbody_protein: "タンパク質",
  inbody_empty: "",
  inbody_return_to_device_connection: "デバイス接続に戻る",
  SMM: "骨格筋",
  TBW: "体水分",
  PBF: "体脂肪率",
  WHR: "腹部脂肪率",
  PSMM: "骨格筋パーセント",
  inbody_PBF_MIN: "体脂肪の最小標準",
  inbody_PBF_MAX: "体脂肪の最大標準",
  inbody_IPBF: "理想的な体脂肪率",
  inbody_WHR_MAX: "腹部脂肪の最大標準",
  inbody_WHR_MIN: "腹部脂肪率の最小標準",
  inbody_SMM_MIN: "骨格筋の最低標準",
  inbody_SMM_MAX: "骨格筋の最大標準",
  inbody_TBW_MIN: "体水分の最低標準",
  inbody_TBW_MAX: "体水分の最大標準",
  bmi_1: "低体重",
  bmi_2: "標準体重",
  bmi_3: "肥満",
  bmi_4: "高度肥満",
  bmi_5: "高度肥満",
  chicken_breast_steak: "鶏胸肉ステーキ",
  salmon_steak: "サーモンステーキ",
  chicken_breast: "鶏胸肉",
  salmon: "サーモン",
  soy_sauce: "醤油",
  honey: "ハチミツ",
  garlic: "ニンニク",
  olive_oil: "オリーブオイル",
  onion: "タマネギ",
  fresh_lemon_juice: "レモン汁",
  inbody_inbodyconnect_instruction_start:
    "入力が完了しました！#デバイスの接続を開始します。",
  inbody_inbodyconnect_instruction_stand_on:
    "インボディ装置の台に立ってください！",
  inbody_inbodyconnect_status_connecting: "デバイスを#接続中です。",
  inbody_inbodyconnect_instruction_disconnect_when_leave_device:
    "インボディ装置の台から離れると#接続が解除されます。",
  inbody_inbodyconnect_progress_percentage: "%d 完了",
  inbody_inbodyconnect_status_connected: "デバイスの接続が#完了しました。",
  inbody_inbodyconnect_instruction_next_step: "すぐに測定が始まります。",
  inbody_inbodyconnect_status_connecting_fail: "デバイスの接続に失敗しました。",
  inbody_inbodyconnect_status_bluetooth_connecting_fail:
    "Bluetooth接続に失敗しました。",
  inbody_inbodyconnect_instruction_check_settings:
    "設定を確認して、再試行してください。",
  inbody_connecting_cancel_connection: "接続キャンセル",
  inbody_inbodyconnect_status_connecting_cancel: "接続がキャンセルされました。",
  inbody_inbodyconnect_instruction_press_connect:
    "接続するにはボタンを押してください。",
  inbody_inbodyconnect_status_connecting_delay: "接続が#遅延しています。",
  inbody_inbodyconnect_instruction_if_cant_connect_try_again:
    "接続が続かない場合は、#戻って再試行してください。",
  inbody_preparation_barefoot_title: "裸足で検査します。",
  inbody_preparation_barefoot_description:
    "靴下やストッキングは電流の流れを妨げて体成分の測定を不正確にします。",
  inbody_preparation_after_toilet_title: "トイレ後測定。",
  inbody_preparation_after_toilet_description:
    "尿や便は体成分ではありませんが、検査結果に影響を与える可能性があります。",
  inbody_preparation_moist_hands_feet_title: "手と足は湿らせてください。",
  inbody_preparation_moist_hands_feet_description:
    "ウェットティッシュで手のひらと足の裏を十分に拭いた後、検査してください。",
  inbody_preparation_header_main_text: "検査前に確認してください！",
  inbody_preparation_header_sub_text: "検査前の注意事項",
  inbody_preparation_posture_step2_main_text:
    "太ももの間が#触れないようにしてください。",
  inbody_preparation_posture_step3_main_text:
    "足は離さずに装置に#しっかりと付けてください。",
  inbody_preparation_posture_step4_main_text:
    "さあ、ハンドルを#掴んでください！",
  inbody_preparation_posture_sub_text: "検査時の注意事項",
  inbody_preparation_go_measuring: "測定に直行",
  inbody_measuring_in_progress: "体成分を#測定中です。",
  inbody_measuring_in_progress2:
    "腕と足が触れないように#そのまま維持してください。",
  inbody_measuring_duration_approx: "約60秒かかります。",
  inbody_measuring_warnings: "動くと接続が切れる可能性があります",
  inbody_measuring_completed: "測定が#完了しました。",
  inbody_measuring_check_result: "測定結果を確認してみてください。",
  inbody_measuring_fail: "測定に失敗しました。",
  inbody_measuring_fail_do_not_move: "測定中に動いてはいけません。",
  inbody_report_user_balance_evalutation_good: "バランスが#良く整っています！",
  inbody_report_user_information: "@1cm、@2kg \n@3歳",
  inbody_report_range_type_under: "必要",
  inbody_report_range_type_over: "高い",
  inbody_report_under_weight: "低体重",
  inbody_report_standard_weight: "標準体重",
  inbody_report_over_weight: "過体重",
  inbody_report_obese_weight: "肥満",
  inbody_report_severe_obese_weight: "高度肥満",
  inbody_report_header_title: "インボディ検査結果です。",
  inbody_report_header_title_sub: "各項目をタップしてください。",
  inbody_dashboard_detail_guide_title: "食事ガイド",
  inbody_dashboard_detail_guide_description:
    "お客様の健康情報に基づいて@の増進のための食事を提案します。",
  inbody_dashboard_detail_diet_detailed_info: "詳細情報を詳しく見る",
  inbody_dashboard_detail_diet_image_description: "食事画像",
  inbody_dashboard_detail_daily_intake_cal_percentage:
    "@kcal#1日の推奨摂取量の%2$s%%",
  inbody_dashboard_detail_nutrient_info_title: "栄養情報",
  inbody_dashboard_detail_nutrient_info_title_detail: "詳細情報",
  inbody_dashboard_detail_daily_intake_note: "*1日の推奨摂取量基準",
  inbody_dashboard_detail_daily_intake_note1: "一日の推奨摂取量の15％",
  inbody_dashboard_detail_daily_intake_note2: "一日の推奨摂取量の25％",
  inbody_dashboard_detail_standard_intake_note: "*1人前基準",
  inbody_dashboard_detail_carbohydrate_format1: " / 炭水化物 @g",
  inbody_dashboard_detail_protein_format1: " / タンパク質 @g",
  inbody_dashboard_detail_fat_format1: " / 脂質 @g",
  inbody_dashboard_detail_title_note: "標準平均数値と比較しました。",
  inbody_dashboard_detail_evaluation_title_inner_smm:
    "骨格筋量が標準範囲内です。",
  inbody_dashboard_detail_evaluation_title_under_smm:
    "骨格筋量が標準範囲未満です。",
  inbody_dashboard_detail_evaluation_title_over_smm:
    "骨格筋量が標準範囲を超えました。",
  inbody_dashboard_detail_evaluation_title_inner_tbw:
    "体水分量が標準範囲内です。",
  inbody_dashboard_detail_evaluation_title_under_tbw:
    "体水分量が標準範囲未満です。,",
  inbody_dashboard_detail_evaluation_title_over_tbw:
    "体水分量が標準範囲を超えました。",
  inbody_dashboard_detail_evaluation_title_inner_pbf:
    "体脂肪率が標準範囲内です。",
  inbody_dashboard_detail_evaluation_title_under_pbf:
    "体脂肪率が標準範囲未満です。",
  inbody_dashboard_detail_evaluation_title_over_pbf:
    "体脂肪率が標準範囲を超えました。",
  inbody_dashboard_detail_evaluation_title_inner_whr:
    "腹部脂肪率が標準範囲内です。",

  inbody_dashboard_detail_evaluation_title_under_whr:
    "腹部脂肪率が標準範囲未満です。",
  inbody_dashboard_detail_evaluation_title_over_whr:
    "腹部脂肪率が標準範囲を超えました。",
  inbody_dashboard_detail_evaluation_description_inner_smm:
    "上手にできています！",
  inbody_dashboard_detail_evaluation_description_under_smm:
    "継続的な管理が必要です。",
  inbody_dashboard_detail_evaluation_description_over_smm:
    "バランスの取れた管理が必要です。",
  inbody_dashboard_detail_evaluation_description_inner_tbw:
    "上手にできています！",
  inbody_dashboard_detail_evaluation_description_under_tbw:
    "継続的な管理が必要です。",
  inbody_dashboard_detail_evaluation_description_over_tbw:
    "バランスの取れた管理が必要です。",
  inbody_dashboard_detail_evaluation_description_inner_pbf:
    "上手にできています！",
  inbody_dashboard_detail_evaluation_description_under_pbf:
    "継続的な管理が必要です。",
  inbody_dashboard_detail_evaluation_description_over_pbf:
    "バランスの取れた管理が必要です。",
  inbody_dashboard_detail_evaluation_description_inner_whr:
    "上手にできています！",
  inbody_dashboard_detail_evaluation_description_under_whr:
    "継続的な管理が必要です。",
  inbody_dashboard_detail_evaluation_description_over_whr:
    "バランスの取れた管理が必要です。",
  inbody_dashboard_detail_evaluation_description_detail_inner_smm:
    "現在の骨格筋量が標準範囲内です。骨格筋は私たちの基本的な運動能力と日常生活の質を決定する重要な要素の一つです。持続的な体力管理と健康的な生活習慣を維持し、良い状態を維持しましょう。",
  inbody_dashboard_detail_evaluation_description_detail_under_smm:
    "現在の骨格筋量が標準範囲より低く測定されました。骨格筋量を向上させるための持続的な運動とバランスの取れた食事が必要です。適切な骨格筋量は健康維持に大きな助けになります。",
  inbody_dashboard_detail_evaluation_description_detail_over_smm:
    "現在の骨格筋量が標準範囲より高く測定されました。これは筋肉の発達がよく行われていることを示しています。しかし、過度な筋肉増加は健康に影響を与える可能性があるため、バランスの取れた運動と食生活を維持することが重要です。",
  inbody_dashboard_detail_evaluation_description_detail_inner_tbw:
    "現在の体水分量が標準範囲内です。体水分量は体の全体的な健康状態と密接に関連しています。持続的な水分摂取と健康的な生活習慣を維持し、この状態を継続してください。",
  inbody_dashboard_detail_evaluation_description_detail_under_tbw:
    "現在の体水分量が標準範囲より低く測定されました。体水分量は私たちの体の健康と密接に関連しているため、不足した水分を補うことが重要です。1日の推奨水分摂取量と現在の生活習慣を確認し、必要に応じて専門家と相談してください。",
  inbody_dashboard_detail_evaluation_description_detail_over_tbw:
    "現在の体水分量が標準範囲より高く測定されました。過剰な体水分量は体の様々な機能に影響を与える可能性があります。適切な水分バランスを維持するために飲料摂取量と食事パターンを確認し、異常な症状や心配がある場合は専門家との相談をお勧めします。",
  inbody_dashboard_detail_evaluation_description_detail_inner_pbf:
    "現在の体脂肪率が標準範囲内です。体脂肪率は全体的な健康状態、体の機能、および代謝関連の健康問題と関連しています。現在の状態を維持するために持続的な運動とバランスの取れた食事を続けることをお勧めします。",
  inbody_dashboard_detail_evaluation_description_detail_under_pbf:
    "現在の体脂肪率が標準範囲より低く測定されました。低い体脂肪率は必要なエネルギー貯蔵および複数の機能の不足を意味する可能性があるため、注意が必要です。バランスの取れた食事と栄養摂取を通じて体脂肪率を適正範囲に維持してください。",
  inbody_dashboard_detail_evaluation_description_detail_over_pbf:
    "現在の体脂肪率が標準範囲より高く測定されました。過剰な体脂肪率は様々な健康問題のリスクを増加させる可能性があるため、注意が必要です。持続的な運動、バランスの取れた食事、および生活習慣の改善を通じて体脂肪率を調整することが重要です。個人の状況に合った健康および食生活の計画を立てるために専門家との相談をお勧めします。",
  inbody_dashboard_detail_evaluation_description_detail_inner_whr:
    "現在の腹部脂肪率が標準範囲内です。腹部脂肪は体の様々な機能および健康状態と関連しています。現在の腹部脂肪率が安定した範囲にあるため、持続的な運動とバランスの取れた食事を続けることをお勧めします。",
  inbody_dashboard_detail_evaluation_description_detail_under_whr:
    "現在の腹部脂肪率が標準範囲より低く測定されました。過度に低い腹部脂肪率は体のエネルギー貯蔵および様々な機能に影響を与える可能性があります。バランスの取れた食事と栄養摂取を通じて腹部脂肪率を適正範囲に維持することが良いです。",
  inbody_dashboard_detail_evaluation_description_detail_over_whr:
    "現在の腹部脂肪率が標準範囲より高く測定されました。過度に高い腹部脂肪率は体のエネルギー貯蔵および様々な機能に影響を与える可能性があります。バランスの取れた食事と栄養摂取を通じて腹部脂肪率を適正範囲に維持することが良いです。",
  inbody_dashboard_detail_normal_amount_string: "@1～@2",
  glucose_popup_title: "血管健康指数の波形は、#血管の弾性度を指します。",
  glucose_popup_sub: "全部で4段階で構成されています。",

  glucose_result_title: "血糖測定結果",
  result_title: "診断結果",
  glucose_result_high_text: "正常基準より@mg/dL高いです",
  glucose_result_low_text: "正常基準より@mg/dL低いです",
  glucose_line_chart_title: "最近の健康状態",

  glucose_grade_good_type: "正常",
  glucose_grade_good_title: "おめでとうございます!#血糖値が正常です。",
  glucose_grade_good_sub: "このまま管理してください!",
  glucose_grade_good_comment_up_title: "現在の生活習慣を維持してください。",
  //---------------glucose--------------------
  glucose_result_type_waring_title: "少し注意してください、#糖尿病の初期です!",
  glucose_result_type_waring_subtitle: "健康な生活習",
  glucose_result_type_waring_result: "糖尿病の初期",
  glucose_result_type_waring_chart: "健康的な生活習慣を維持してください",
  glucose_result_type_danger_title:
    "血糖値が高いです。#糖尿病であると推測されます。",
  glucose_result_type_danger_subtitle: "専門家との相談が必要です。",
  glucose_result_type_danger_result: "糖尿病",
  glucose_result_type_danger_chart: "健康管理にもっと注意してください",
  glucose_category_type_low_fat_low_salt_title: "低脂肪、低塩分",
  glucose_category_type_low_fat_low_salt_body:
    "料理する際は、食用油を減らし、塩の代わりにハーブやスパイスで味付けしてみてください。また、加工食品より新鮮な果物や野菜を楽しむ習慣を身につけることも良い方法です。",
  glucose_category_type_exercise_title: "定期的な運動",
  glucose_category_type_exercise_body:
    "定期的な運動は体と心の健康に必須です。運動は直接的に血糖を下げる効果があり、長期的に糖尿病の合併症を防ぎ、ストレス解消にも大いに役立ちます。日常生活に継続的な運動習慣を取り入れて、より健康で活力ある生活を楽しみましょう！",
  glucose_category_type_water_title: "水分摂取",
  glucose_category_type_water_body:
    "水は体の60%を占め、毒素の排出、体温調節、関節の保護、消化を支援し、栄養を運ぶのに不可欠です。健康を維持するためには、1日に2～3リットルの水分摂取が推奨されます。",
  glucose_result_text_tip_title: "これでより良くなります",
  glucose_result_button_back: "ホーム",
  glucose_result_button_move_mobile_page: "モバイルで受け取る",
  glucose_result_text_mg_dl: "@mg/dL",
  glucose_result_text_last_update_date: "@日前",
  glucose_result_screen_text_chart_body_resent_data_normal:
    "血糖値は @1mg/dLで、「正常」に該当します。#最近@3回の血糖値をうまく維持しています！現在の健康的な生活習慣とバランスの取れた食事を続けてください。",
  glucose_result_screen_text_chart_body_resent_data_bad_danger:
    "血糖値は @1mg/dLで測定されました。#正常範囲より約 @2%高く、「糖尿病」の可能性があります。最近 @3回の血糖値が高いです。糖尿病は管理されない場合、さまざまな合併症を引き起こす可能性があります。専門医の相談を受け、健康管理にもっと注意してください！",
  glucose_result_screen_text_chart_body_resent_data_good_danger:
    "血糖値は @1mg/dLで測定されました。#正常範囲より約 @2%高く、「糖尿病」の可能性があります。最近 @3回の数値より @4mg/dL改善されましたが、糖尿病はさまざまな合併症を引き起こす可能性があるため、管理が必要です。専門医の相談を受け、健康管理にもっと注意してください！",
  glucose_result_screen_text_chart_body_resent_data_bad_waring:
    "血糖値は @1mg/dLで測定されました。#正常範囲より約 @2%高く、「糖尿病初期」の可能性があります。最近 @3回の血糖値が少し高いです。糖尿病は管理されない場合、さまざまな合併症を引き起こす可能性があります。もっと努力してください！",
  glucose_result_screen_text_chart_body_resent_data_good_waring:
    "血糖値は @1mg/dLで測定されました。#正常範囲より約 @2%高く、「糖尿病初期」の可能性があります。最近 @3回の数値より @4mg/dL改善されましたが、糖尿病はさまざまな合併症を引き起こす可能性があるため、継続的な管理が必要です。もっと努力してください！",
  glucose_result_screen_text_chart_body_resent_no_data_normal:
    "血糖値は @1mg/dLで「正常」範囲に該当します。現在の健康的な生活習慣とバランスの取れた食事を続けてください。定期的な健康診断と共に、自身の身体の状態を注意深く観察し、最適な状態を維持してください。",
  glucose_result_screen_text_chart_body_resent_no_data_waring:
    "血糖値は @1mg/dLで測定されました。#正常範囲より約 @2%高く、「糖尿病初期」の可能性があります。糖尿病はさまざまな合併症を引き起こす可能性があるため、継続的な管理が必要です。もっと努力してください！",
  glucose_result_screen_text_chart_body_resent_no_data_danger:
    "血糖値は @1mg/dLで測定されました。#正常範囲より約 @2%高く、「糖尿病」の可能性があります。糖尿病はさまざまな合併症を引き起こす可能性があるため、管理が必要です。専門医の相談を受け、健康管理にもっと注意してください！",
  glucose_device_search_button_connect_device: "該当デバイスを接続",
  glucose_device_search_text_title: "接続するデバイス",
  glucose_device_search_text_subtitle: "接続可能なデバイスを検索します。",
  glucose_device_search_text_registered_device_title: "登録済みデバイス",
  glucose_device_search_text_new_search_device_title:
    "新しく検索されたデバイス",
  glucose_device_search_button_search: "検索",
};
