import { styled } from "styled-components";
import { pxToVh } from "../../../utils/calc/pxToVh";

export const DataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${pxToVh(10)};
`;

export const DataInProgress = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  align-items: center;
`;

// export const StandardInProgress = styled.div`
//     width:
// `
