import React from "react";
import {
  ProgressValue,
  TextLayout,
  WidthBarLayout,
  WidthBarPadding,
} from "./ProgressbarStyles";
import Text from "../../atoms/text/Text";
import { theme } from "../../../styles/theme";
import { useLocalization } from "../../../hooks/useLocalization";

const Progressbar = ({
  result,
}: {
  result: { target: number; min: number; max: number };
}) => {
  const { target, min, max } = result;
  const translation = useLocalization();

  return (
    <WidthBarPadding>
      <WidthBarLayout $min={min} $max={max}>
        <ProgressValue $target={target} />
        <TextLayout $center={(min + max) / 2}>
          <Text
            content={translation.inbody_standard_range}
            fontSize={theme.size.xxs}
            fontWeight={theme.weight.light}
            color={theme.color.gray100}
          />
        </TextLayout>
      </WidthBarLayout>
    </WidthBarPadding>
  );
};

export default Progressbar;
