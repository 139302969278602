import React, { ReactElement } from "react";
import { BloodSquareBox, SvgType } from "./BloodSquareStyles";
import Text from "../../atoms/text/Text";
import { theme } from "../../../styles/theme";
import { pxToVh } from "../../../utils/calc/pxToVh";

const BloodSquare = ({
  svg,
  semiContent,
  content,
}: {
  svg: ReactElement;
  semiContent: string;
  content: string;
}) => {
  return (
    <BloodSquareBox>
      <SvgType width={49} height={49}>
        {svg}
      </SvgType>
      <Text
        content={semiContent}
        fontSize={theme.size.xs}
        fontWeight={theme.weight.bold}
        customStyle={{ opacity: "0.7", marginTop: `${pxToVh(18)}` }}
      />
      <Text
        content={content}
        fontSize={theme.size.small}
        fontWeight={theme.weight.bold}
        customStyle={{ marginTop: `${pxToVh(4)}` }}
      />
    </BloodSquareBox>
  );
};

export default BloodSquare;
