import React from "react";
import { ResultCommentStyles } from "./ResultCommentStyles";
import Text from "../../atoms/text/Text";
import { theme } from "../../../styles/theme";
import { pxToVh } from "../../../utils/calc/pxToVh";

const ResultComment = ({
  title,
  sub,
}: {
  title: string | string[];
  sub: string;
}) => {
  return (
    <ResultCommentStyles>
      <Text
        content={title[0]}
        fontSize={theme.size.medium}
        fontWeight={theme.weight.bold}
      />
      {title[1] && (
        <Text
          content={title[1]}
          fontSize={theme.size.medium}
          fontWeight={theme.weight.bold}
        />
      )}
      <Text
        content={sub}
        fontSize={theme.size.small}
        fontWeight={theme.weight.light}
        customStyle={{ marginTop: pxToVh(10) }}
      />
    </ResultCommentStyles>
  );
};

export default React.memo(ResultComment);
