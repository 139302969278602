import { ReactElement } from "react";
import * as Svg from "../../components/atoms/icon/index";

export const bloodSvg: {
  [index: string]: { chart: ReactElement; type: ReactElement };
} = {
  a: { chart: <Svg.ChartA />, type: <Svg.TypeA /> },
  b: { chart: <Svg.ChartB />, type: <Svg.TypeB /> },
  c: { chart: <Svg.ChartC />, type: <Svg.TypeC /> },
  d: { chart: <Svg.ChartD />, type: <Svg.TypeD /> },
  e: { chart: <Svg.ChartE />, type: <Svg.TypeE /> },
  f: { chart: <Svg.ChartF />, type: <Svg.TypeF /> },
  g: { chart: <Svg.ChartG />, type: <Svg.TypeG /> },
};
