import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import {
  IInbodyQuery,
  defaultQuery,
  inbodyQueryItems,
} from "../types/inbodyQuery.type";
import { TInbody } from "../types/deepQuery.type";

export const useInbody = () => {
  const [isDetail, setIsDetail] = React.useState({
    chicken: false,
    salmon: false,
  });
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  let queryData: IInbodyQuery = defaultQuery;

  inbodyQueryItems.forEach((item) => {
    const value = params.get(item);
    queryData = Object.assign(queryData, { [item]: value });
  });

  const onDetail = (props: string) =>
    setIsDetail((prev) => {
      return { ...prev, [props]: true };
    });

  const closeClick = (props: string) =>
    setIsDetail((prev) => {
      return { ...prev, [props]: false };
    });

  const balancerNavigate = (
    direct: string,
    target: number,
    min: number,
    max: number,
    status: TInbody
  ) => {
    navigate(`/inbody/result/${direct}`, {
      state: {
        type: direct,
        target: target,
        min: min,
        max: max,
        status: status,
      },
    });
  };

  return {
    queryData,
    isDetail,
    balancerNavigate,
    onDetail,
    closeClick,
  };
};
