import React from "react";
import Title from "../texts/title/Title";
import { theme } from "../../styles/theme";
import FoodGuide from "../foodGuide/FoodGuide";
import { NutriData } from "../../utils/comment/FoodData";
import Text from "../atoms/text/Text";
import { SquareLayout, StateCommentLayout } from "../foodGuide/FoodGuideStyles";
import { useLocation } from "react-router-dom";
import { useInbody } from "../../hooks/useInbody";
import SquareInbody from "../square/squareInbody/SquareInbody";
import { pxToVh } from "../../utils/calc/pxToVh";
import { useLocalization } from "../../hooks/useLocalization";
import { InbodyCommentController } from "../../utils/comment/InbodyGrade";

const InbodyTemplate = () => {
  const { isDetail, onDetail, closeClick } = useInbody();
  const translocation = useLocalization();
  const { inbodyComment } = InbodyCommentController();
  const nutriData = NutriData();
  const location = useLocation();
  const { type, target, min, max, status } = location.state;

  const category = (type: String) => {
    let cType = type.toUpperCase();
    switch (cType) {
      case "SMM":
        return translocation.SMM;
      case "TBW":
        return translocation.TBW;
      case "PBF":
        return translocation.PBF;
      case "WHR":
        return translocation.WHR;
      default:
        return "";
    }
  };

  return (
    <div style={{ marginBottom: pxToVh(65), width: pxToVh(346) }}>
      <Title
        content={inbodyComment[type][status].title}
        subTitle={translocation.healthcare_compare_normal}
      />
      <SquareLayout>
        <Text
          content={translocation.healthcare_compare}
          fontSize={theme.size.medium}
          fontWeight={theme.weight.bold}
        />
        <SquareInbody
          title={type}
          result={{
            target: target,
            min: min,
            max: max,
          }}
          status={status}
          isDetail={true}
          squareClick={() => {}}
        />
      </SquareLayout>
      <StateCommentLayout>
        <Text
          content={inbodyComment[type][status].resultTitle}
          fontSize={theme.size.medium}
          fontWeight={theme.weight.bold}
          customStyle={{ lineHeight: "150%" }}
        />
        <Text
          content={inbodyComment[type][status].subTitle}
          fontSize={theme.size.small}
          fontWeight={theme.weight.light}
          customStyle={{ lineHeight: "150%" }}
        />
      </StateCommentLayout>
      <Title
        content={translocation.inbody_dashboard_detail_guide_title}
        subTitle={translocation.inbody_dashboard_detail_guide_description.replace(
          "@",
          category(type)
        )} //"사용자님의 건강 정보를 바탕으로 골격근량 증진을 위한 식단을 제안합니다."
        firstSize={theme.size.medium}
      />
      <FoodGuide
        category="chicken"
        nutriData={nutriData["chicken"]}
        isDetail={isDetail.chicken}
        onDetail={onDetail}
        closeClick={closeClick}
      />
      <FoodGuide
        category="salmon"
        nutriData={nutriData["salmon"]}
        isDetail={isDetail.salmon}
        onDetail={onDetail}
        closeClick={closeClick}
      />
    </div>
  );
};

export default InbodyTemplate;
