import { styled } from "styled-components";
import { pxToVh } from "../../utils/calc/pxToVh";

export const BetterStyles = styled.div`
  margin-top: ${pxToVh(55)};
  width: ${pxToVh(346)};
`;

export const ArrowLayout = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CategoryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  width: fit-content;
  min-width: 100%;
  height: ${pxToVh(40)};
  margin-top: ${pxToVh(14)};
`;

export const ScollX = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Categoery = styled.span<{ $isSelect: boolean }>`
  height: 100%;
  padding: 0 ${pxToVh(30)};
  border-radius: ${pxToVh(19)};
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.size.small};
  font-weight: ${({ $isSelect, theme }) =>
    $isSelect ? theme.weight.bold : theme.weight.light};
  transition: 0.3s background-color;
  color: ${({ $isSelect, theme }) =>
    $isSelect ? theme.color.white : theme.color.gray100};
  background-color: ${({ $isSelect, theme }) =>
    $isSelect ? theme.color.gray300 : theme.color.black};
`;

export const CommentContainer = styled.div`
  span {
    line-height: 150%;
  }
  min-height: ${pxToVh(140)};
  margin-top: ${pxToVh(15)};
  margin-bottom: ${pxToVh(20)};
`;

export const BetterCommentTitle = styled.p`
  font-size: ${({ theme }) => theme.size.large};
  font-weight: ${({ theme }) => theme.weight.bold};
  line-height: 150%;
`;
