import { useLocalization } from "../../hooks/useLocalization";

export const BloodComment = () => {
  const translation = useLocalization();
  const bloodComment = {
    normal: translation.blood_vessel_result_summary_health_text,
    caution: translation.blood_vessel_result_summary_waring_text,
    danger: translation.blood_vessel_result_summary_danger_text,
  };
  return { bloodComment };
};
