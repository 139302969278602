import React from "react";
import {
  BloodContentContainer,
  BloodInfo,
  BloodInfoContainer,
  BloodLine,
  BloodResultContainer,
  RowSvg,
} from "./BloodResultStyles";
import Text from "../../../atoms/text/Text";
import { theme } from "../../../../styles/theme";
import BloodProgress from "../../../graph/bloodProgress/BloodProgress";
import { useLocalization } from "../../../../hooks/useLocalization";
import { pxToVh } from "../../../../utils/calc/pxToVh";
import * as Svg from "../../../atoms/icon/index";

const BloodResult = ({
  contentArr,
  title,
  target,
  standardTarget,
  min,
  isQuestionMark,
  clickQuestionMark,
}: {
  contentArr: string[][];
  title: string;
  target: number;
  standardTarget: number;
  min?: number;
  isQuestionMark?: boolean;
  clickQuestionMark?: (e: React.MouseEvent<HTMLOrSVGElement>) => void;
}) => {
  const translation = useLocalization();
  return (
    <BloodResultContainer>
      <RowSvg>
        <Text
          content={title}
          fontSize={theme.size.small}
          fontWeight={theme.weight.bold}
          customStyle={{ lineHeight: "150%" }}
        />
        {isQuestionMark && (
          <Svg.Help
            onClick={clickQuestionMark}
            width={pxToVh(18)}
            height={pxToVh(18)}
          />
        )}
      </RowSvg>
      <BloodContentContainer>
        <BloodInfoContainer>
          {contentArr.map((content, i) => {
            return (
              <BloodInfo key={i}>
                <Text
                  content={content[0]}
                  fontSize={theme.size.xs}
                  fontWeight={theme.weight.bold}
                  customStyle={{ opacity: "0.7" }}
                />
                <Text
                  content={content[1]}
                  fontSize={theme.size.small}
                  fontWeight={theme.weight.bold}
                />
              </BloodInfo>
            );
          })}
        </BloodInfoContainer>
        <BloodLine />
        <BloodProgress title={translation.user_age} target={target} />
        <BloodProgress
          title={translation.blood_vessel_age}
          target={standardTarget}
          isStandard={true}
          min={min}
        />
      </BloodContentContainer>
    </BloodResultContainer>
  );
};

export default BloodResult;
