import React from "react";
import {
  ArrowLayout,
  DetailInfoLayout,
  DetailTitleLayout,
  FoodGuideLayout,
  GuideTitle,
  Header,
  Line,
  SvgGuide,
  ThreeCircleLayout,
} from "./FoodGuideStyles";
import Text from "../atoms/text/Text";
import { theme } from "../../styles/theme";
import CircleProgress from "../graph/circleProgress/CircleProgress";
import Button from "../atoms/button/Button";
import * as Svg from "../atoms/icon/index";
import { pxToVh } from "../../utils/calc/pxToVh";
import { IFoodGuide } from "../../types/foodGuide.type";
import Nutrition from "./nutrition/Nutrition";
import { INutrition } from "../../types/nutrition.type";
import { useLocalization } from "../../hooks/useLocalization";

const FoodGuide = ({
  nutriData,
  category,
  isDetail,
  onDetail,
  closeClick,
}: IFoodGuide) => {
  const { svg, infoText, mainCircleValue, info, details } = nutriData;
  const translocation = useLocalization();
  return (
    <FoodGuideLayout $isDetail={isDetail}>
      <Header>
        <SvgGuide>
          {svg}
          <GuideTitle>
            <Text
              content={infoText.name}
              fontSize={theme.size.small}
              fontWeight={theme.weight.bold}
            />
            <Text
              content={infoText.kcal}
              fontSize={theme.size.xxs}
              fontWeight={theme.weight.light}
              color={theme.color.gray100}
            />
            <Text
              content={infoText.sub}
              fontSize={theme.size.xxs}
              fontWeight={theme.weight.light}
              color={theme.color.gray100}
            />
          </GuideTitle>
        </SvgGuide>
        <CircleProgress
          width={pxToVh(56)}
          value={mainCircleValue}
          circleColor={theme.color.cyan10}
          textSize={"26px"}
        />
      </Header>
      <Line />
      <DetailTitleLayout>
        <Text
          content={translocation.inbody_dashboard_detail_nutrient_info_title} //"영양 정보"
          fontSize={theme.size.small}
          fontWeight={theme.weight.bold}
        />
        <Text
          content={translocation.inbody_dashboard_detail_daily_intake_note} //"*하루 권장 섭취량 기준"
          fontSize={theme.size.xxs}
          fontWeight={theme.weight.light}
          color={theme.color.gray100}
          customStyle={{ textAlign: "right" }}
        />
      </DetailTitleLayout>
      <ThreeCircleLayout>
        {info.map((nutrition: INutrition, i: number) => {
          return (
            <Nutrition
              key={i}
              circleValue={nutrition.circleValue}
              nutriTitle={nutrition.nutriTitle}
              nutriValue={nutrition.nutriValue}
            />
          );
        })}
      </ThreeCircleLayout>
      {isDetail && (
        <>
          <Line />
          <DetailTitleLayout>
            <Text
              content={
                translocation.inbody_dashboard_detail_nutrient_info_title_detail
              } //"상세 정보"
              fontSize={theme.size.small}
              fontWeight={theme.weight.bold}
            />
            <Text
              content={
                translocation.inbody_dashboard_detail_standard_intake_note
              } //"*1인분 기준"
              fontSize={theme.size.xxs}
              fontWeight={theme.weight.light}
              color={theme.color.gray100}
            />
          </DetailTitleLayout>
          <DetailInfoLayout>
            {details.map((detail, i: number) => {
              return (
                <DetailTitleLayout key={i}>
                  <Text
                    content={detail.title}
                    fontSize={theme.size.xs}
                    fontWeight={theme.weight.bold}
                  />
                  <Text
                    content={detail.detail}
                    fontSize={theme.size.xs}
                    fontWeight={theme.weight.light}
                  />
                </DetailTitleLayout>
              );
            })}
          </DetailInfoLayout>
          <ArrowLayout onClick={() => closeClick(category)}>
            <Svg.Arrow />
          </ArrowLayout>
        </>
      )}
      {!isDetail && (
        <Button
          content={translocation.inbody_dashboard_detail_diet_detailed_info} //"상세정보 자세히 보기"
          fontSize={theme.size.xs}
          fontWeight={theme.weight.bold}
          backgroundColor={theme.color.gray300}
          border={`1px solid ${theme.color.gray200}`}
          customStyle={{ marginTop: pxToVh(20) }}
          handleClick={() => onDetail(category)}
        />
      )}
    </FoodGuideLayout>
  );
};

export default FoodGuide;
