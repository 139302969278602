import React from "react";
import {
  Absolute,
  BodyGraph,
  Bottom,
  Header,
  PopupContainer,
  TitleContainer,
  VascularWrapper,
} from "./PopupStyles";
import Text from "../atoms/text/Text";
import { theme } from "../../styles/theme";
import { pxToVh } from "../../utils/calc/pxToVh";
import * as Svg from "../atoms/icon/index";
import { useLocalization } from "../../hooks/useLocalization";
import { TPopup } from "../../types/popup.type";

const Popup = ({
  isShow,
  closeClick,
}: {
  isShow: TPopup;
  closeClick: (e: React.MouseEvent<HTMLOrSVGElement>) => void;
}) => {
  const translation = useLocalization();
  const lang = navigator.language.includes("ko")
    ? "ko"
    : navigator.language.includes("ja")
    ? "jp"
    : "en";

  const localizationSvg: {
    [index: string]: {
      table: React.ReactElement;
      chartInfo: React.ReactElement;
      infoTable: React.ReactElement;
      vascular: React.ReactElement;
    };
  } = {
    ko: {
      table: <Svg.Table />,
      chartInfo: <Svg.InfoChartInText />,
      infoTable: <Svg.InfoTable />,
      vascular: <Svg.KoVascular />,
    },
    jp: {
      table: <Svg.JaTable />,
      chartInfo: <Svg.JaInfoChartInText />,
      infoTable: <Svg.JaInfoTable />,
      vascular: <Svg.JaVascular />,
    },
    en: {
      table: <Svg.EnTable />,
      chartInfo: <Svg.EnInfoChartInText />,
      infoTable: <Svg.EnInfoTable />,
      vascular: <Svg.EnVascular />,
    },
  };

  const [first, end] = translation.glucose_popup_title.split("#");

  return (
    <Absolute onClick={closeClick}>
      <PopupContainer
        $isGraph={isShow === "graph"}
        onClick={(e) => e.stopPropagation()}>
        <Header>
          {isShow !== "vascular" && (
            <TitleContainer>
              <Text
                content={first}
                fontSize={theme.size.lardium}
                fontWeight={theme.weight.bold}
                color={theme.color.black}
                customStyle={{ lineHeight: "150%" }}
              />
              <Text
                content={end}
                fontSize={theme.size.lardium}
                fontWeight={theme.weight.bold}
                color={theme.color.black}
                customStyle={{ lineHeight: "150%", marginBottom: pxToVh(4) }}
              />
              <Text
                content={translation.glucose_popup_sub}
                fontSize={theme.size.small}
                fontWeight={theme.weight.light}
                color={theme.color.gray100}
                customStyle={{ lineHeight: "150%" }}
              />
            </TitleContainer>
          )}
          {isShow === "vascular" && (
            <VascularWrapper>{localizationSvg[lang].vascular}</VascularWrapper>
          )}
          <Svg.Close onClick={closeClick} />
        </Header>
        {isShow !== "vascular" && (
          <>
            <BodyGraph $isShow={isShow === "graph"}>
              {isShow === "graph" && <Svg.MarkChart />}
              {isShow === "table" && localizationSvg[lang].table}
            </BodyGraph>
            <Bottom $isShow={isShow === "graph"}>
              {isShow === "graph" && localizationSvg[lang].chartInfo}
              {isShow === "table" && localizationSvg[lang].infoTable}
            </Bottom>
          </>
        )}
      </PopupContainer>
    </Absolute>
  );
};

export default Popup;
