import React, { useEffect } from "react";
import {
  ProgressLayout,
  PickState,
  SquareLayout,
  TitleLayout,
} from "./SquareInbodyStyles";
import { theme } from "../../../styles/theme";
import Text from "../../atoms/text/Text";
import * as Svg from "../../atoms/icon/index";
import WidthBar from "../../graph/progressbar/Progressbar";
import {
  Baseline,
  Header,
  RightHeader,
} from "../../graph/progressbar/ProgressbarStyles";
import { percentV } from "../../../utils/calc/CalcResult";
import { pxToVh } from "../../../utils/calc/pxToVh";
import {
  InbodyCommentController,
  TInbodyGrade,
} from "../../../utils/comment/InbodyGrade";
import { PickData } from "../../../utils/comment/PickColorData";
import { THealthType, TInbody, TStatus } from "../../../types/deepQuery.type";

const SquareInbody = ({
  title,
  result,
  isCustom,
  isDetail,
  status,
  type = "inbody",
  width,
  squareClick,
}: {
  title: TInbodyGrade | THealthType;
  result: { target: number; min: number; max: number };
  isDetail?: boolean;
  status: TInbody | TStatus;
  isCustom?: boolean;
  type?: "health" | "inbody";
  width?: string;
  squareClick: () => void;
}) => {
  const [target, setTarget] = React.useState({
    target: 0,
    min: 0,
    max: 0,
  });
  const PickColorData = PickData();
  const { transformType } = InbodyCommentController();

  useEffect(() => {
    const gap = result.max - result.min;
    const barPercent = {
      min: isCustom ? 0 : Number(result.min) - gap * 2,
      max: isCustom ? 100 : Number(result.max) + gap * 2,
    };

    const resultMin = percentV(barPercent.min, barPercent.max, result.min);
    const resultMax = percentV(barPercent.min, barPercent.max, result.max);
    const resultTarget = percentV(
      barPercent.min,
      barPercent.max,
      result.target
    );

    setTarget({
      target: resultTarget,
      min: isCustom ? 0.1 : resultMin,
      max: isCustom ? 50 : resultMax,
    });
  }, []);

  return (
    <SquareLayout $width={width} onClick={squareClick}>
      <TitleLayout>
        <Header>
          <Text
            content={transformType[title].name}
            fontSize={theme.size.small}
            fontWeight={theme.weight.bold}
          />
        </Header>
        <RightHeader>
          <Baseline>
            <Text
              content={String(result.target)}
              fontSize={theme.size.medium}
              fontWeight={theme.weight.bold}
            />
            <Text
              content={transformType[title].unit}
              fontSize={theme.size.small}
              fontWeight={theme.weight.light}
              color={theme.color.gray100}
            />
          </Baseline>
          {!isDetail ? <Svg.ArrowRight /> : <div></div>}
        </RightHeader>
      </TitleLayout>
      <PickState
        $backgroundColor={PickColorData[type][status].pickBackColor}
        $isBorder={PickColorData[type][status].isBorder}
      >
        <Text
          content={PickColorData[type][status].ment}
          fontSize={pxToVh(9)}
          fontWeight={theme.weight.bold}
        />
      </PickState>
      <ProgressLayout>
        <WidthBar result={target} />
      </ProgressLayout>
    </SquareLayout>
  );
};

export default SquareInbody;
