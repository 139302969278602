import styled from "styled-components";
import { pxToVh } from "../../utils/calc/pxToVh";
import { FadeUp } from "../../styles/animation";

export const Absolute = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: brightness(50%);
  -webkit-backdrop-filter: brightness(50%);
`;

export const PopupContainer = styled.div<{ $isGraph: boolean }>`
  &::-webkit-scrollbar {
    display: none;
  }
  position: absolute;
  display: flex;
  flex-direction: column;
  width: ${({ $isGraph }) => ($isGraph ? pxToVh(310) : pxToVh(360))};
  height: ${({ $isGraph }) => ($isGraph ? pxToVh(560) : pxToVh(578))};
  padding: ${pxToVh(44)} ${pxToVh(25)};
  border-radius: ${pxToVh(20)};
  background-color: ${({ theme }) => theme.color.white};
  gap: ${pxToVh(32)} 0;
  overflow-y: scroll;
  animation: ${FadeUp} 0.4s ease-in-out;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  svg {
    width: ${pxToVh(24)};
    height: ${pxToVh(24)};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${pxToVh(10)};
  width: 90%;
`;

export const BodyGraph = styled.div<{ $isShow: boolean }>`
  svg {
    width: ${({ $isShow }) => ($isShow ? pxToVh(207) : pxToVh(301))};
    height: 100%;
  }
`;

export const Bottom = styled.div<{ $isShow: boolean }>`
  svg {
    width: ${({ $isShow }) => ($isShow ? pxToVh(258) : pxToVh(246))};
    height: 100%;
  }
`;

export const VascularWrapper = styled.div`
  svg {
    width: ${pxToVh(246)};
    height: 100%;
  }
`;
