export default {
  hr: "심박수",
  rep: "호흡수",
  psi: "피로도",
  msi: "스트레스",
  gender: "성별",
  male: "남성",
  female: "여성",
  document_title_result_hr: "영상 건강 측정결과",
  document_title_result_inbody: "인바디 측정결과",
  standard_range: "표준 범위",
  count: "회",
  per_minute: "회 / 분",
  healthcare_compare_normal: "표준 평균 수치와 비교했어요.",
  healthcare_compare: "측정 결과 요약",

  healthcare_hr_good_title: "심박수가 표준 범위네요.#계속 잘 관리하세요!",
  healthcare_hr_normalup_title: "심박수가 높아#주의가 필요합니다.",
  healthcare_hr_normaldown_title: "심박수가 낮아#주의가 필요합니다.",
  healthcare_hr_badup_title: "심박수가 매우 높아#관리가 필요합니다.",
  healthcare_hr_baddown_title: "심박수가 매우 낮아#관리가 필요합니다.",

  healthcare_hr_good_resultTitle:
    "심박수가 표준 범위 이내입니다.#이대로 유지해주세요!",
  healthcare_hr_normalup_resultTitle:
    "표준 평균 수치보다 심박수가#@회 더 높아요.",
  healthcare_hr_normaldown_resultTitle:
    "표준 평균 수치보다 심박수가#@회 더 낮아요.",
  healthcare_hr_badup_resultTitle: "표준 평균 수치보다 심박수가#@회 더 높아요.",
  healthcare_hr_baddown_resultTitle:
    "표준 평균 수치보다 심박수가#@회 더 낮아요.",

  // healthcare_hr_good_subTitle:
  //   "심박수가 표준 범위에 있습니다. 이는 대체로 건강한 심장 활동을 나타냅니다. 하지만 건강한 생활 습관을 지속하고, 주기적인 건강 검진을 잊지 말아야 합니다. 스트레스를 피하고, 꾸준한 운동 및 균형 잡힌 식사가 중요합니다.",
  // healthcare_hr_normalup_subTitle:
  //   "심박수가 평균보다 높게 측정되었습니다. 빠른 심박수는 스트레스, 카페인 섭취, 불안, 물 부족 등 다양한 원인으로 발생할 수 있습니다. 꾸준한 휴식과 스트레스 관리가 중요하며, 지속적인 높은 심박수나 다른 증상이 동반된다면 전문가와 상의하세요.",
  // healthcare_hr_normaldown_subTitle:
  //   "심박수가 평균보다 낮게 측정되었습니다. 낮은 심박수는 훈련된 운동선수나 특정 질환을 가진 사람들에서 나타날 수 있습니다. 증상이나 불편함이 동반된다면 전문가와의 상의가 필요합니다.",
  // healthcare_hr_badup_subTitle:
  //   "심박수가 지나치게 높게 측정되었습니다. 매우 높은 심박수는 심장 부담을 증가시킬 수 있으며, 심장의 기능 장애나 다른 건강 문제의 가능성을 시사할 수 있습니다. 즉시 휴식을 취하고, 호흡을 조절하세요.",
  // healthcare_hr_baddown_subTitle:
  //   "심박수가 매우 낮게 측정되었습니다. 지나치게 낮은 심박수는 심장의 정상적인 기능에 문제가 있을 수 있습니다. 어지러움, 피로, 가슴 통증 등의 증상이 동반된다면 즉시 의료 기관을 방문하세요.",

  healthcare_hr_good_subTitle:
    "안정시 심박수가 표준 범위에 있어 건강한 상태를 유지하고 있습니다. 규칙적인 운동과 건강한 식습관을 유지하며, 스트레스를 효과적으로 관리하는 것이 중요합니다. 계속해서 건강을 유지하기 위해 노력해주세요.",
  healthcare_hr_normalup_subTitle:
    "심박수가 지속적으로 빠르다면 심장 질환, 갑상선 기능 항진증, 심부전, 불규칙한 심장 박동 등 여러 가지 요인이 심박수를 증가시킬 수 있어요. 지속적으로 빠른 심박수는 혈압 상승, 혈관 손상, 심장 부담 증가 등의 문제를 유발할 수 있으므로 의료 전문가와 상담하는 것이 중요합니다. 생활 방식, 식습관, 스트레스 관리 등을 통해 심박수를 정상화시키는 것이 중요해요.",
  healthcare_hr_normaldown_subTitle:
    "심박수가 지속적으로 느리다면 심장 질환, 저혈압, 심근염, 갑상선 기능 저하, 심실제동, 심부전, 심근 경색 등이 심박수 감소의 원인이 될 수 있어요. 심박수가 너무 느릴 경우 혈액순환이 충분히 이루어지지 않아 뇌나 다른 장기에 산소가 부족할 수 있으며, 심부전과 같은 심혈관 질환의 위험이 증가할 수 있어요. 따라서 심박수가 계속적으로 느리다면 의료 전문가와 상담하여 원인을 밝히고 적절한 치료를 받는 것이 중요해요. 단 운동선수와 같이 운동을 많이 한 사람 중에서는 낮은 경우가 있어요. 운동으로 인해 심장의 크기가 커져 한 번에 공급할 수 있는 혈액의 양이 충분하기 때문이에요.",
  healthcare_hr_badup_subTitle:
    "심박수가 지속적으로 빠르다면 심장 질환, 갑상선 기능 항진증, 심부전, 불규칙한 심장 박동 등 여러 가지 요인이 심박수를 증가시킬 수 있어요. 지속적으로 빠른 심박수는 혈압 상승, 혈관 손상, 심장 부담 증가 등의 문제를 유발할 수 있으므로 의료 전문가와 상담하는 것이 중요합니다. 생활 방식, 식습관, 스트레스 관리 등을 통해 심박수를 정상화시키는 것이 중요해요.",
  healthcare_hr_baddown_subTitle:
    "심박수가 지속적으로 느리다면 심장 질환, 저혈압, 심근염, 갑상선 기능 저하, 심실제동, 심부전, 심근 경색 등이 심박수 감소의 원인이 될 수 있어요. 심박수가 너무 느릴 경우 혈액순환이 충분히 이루어지지 않아 뇌나 다른 장기에 산소가 부족할 수 있으며, 심부전과 같은 심혈관 질환의 위험이 증가할 수 있어요. 따라서 심박수가 계속적으로 느리다면 의료 전문가와 상담하여 원인을 밝히고 적절한 치료를 받는 것이 중요해요. 단 운동선수와 같이 운동을 많이 한 사람 중에서는 낮은 경우가 있어요. 운동으로 인해 심장의 크기가 커져 한 번에 공급할 수 있는 혈액의 양이 충분하기 때문이에요.",

  healthcare_rep_good_title: "호흡수가 표준 범위네요.#계속 잘 관리하세요!",
  healthcare_rep_normal_title: "호흡수가 낮아#주의가 필요합니다.",
  healthcare_rep_bad_title: "호흡수가 높아#주의가 필요합니다.",

  healthcare_rep_good_resultTitle:
    "호흡 수치가 표준 범위입니다.#이대로 유지해주세요!",
  healthcare_rep_normal_resultTitle:
    "표준 평균 수치보다 호흡수가#@회 더 낮아요.",
  healthcare_rep_bad_resultTitle: "표준 평균 수치보다 호흡수가#@회 더 높아요.",

  // healthcare_rep_good_subTitle:
  //   "호흡수가 표준 범위에 있습니다. 이는 건강한 상태를 의미할 수 있으나, 개인의 상태나 다른 증상에 따라 다를 수 있습니다. 계속해서 자신의 몸 상태를 체크하며, 이상이 발견되면 전문가의 도움을 받는 것이 좋습니다.",
  // healthcare_rep_normalup_subTitle:
  //   "호흡수가 평균보다 높게 측정되었습니다. 빠른 호흡은 스트레스, 신체적 문제 등 다양한 원인으로 발생할 수 있습니다. 만약 빠른 호흡이 지속되거나 다른 증상과 함께 느껴진다면, 전문가와의 상의를 고려해야 합니다.",
  // healthcare_rep_normaldown_subTitle:
  //   "호흡수가 평균보다 낮게 측정되었습니다. 호흡수의 감소는 다양한 원인에 기인할 수 있으며, 때로는 건강 문제의 신호일 수 있습니다. 혹시 다른 증상이나 불편함이 있으시다면 주저하지 말고 전문가와 상의하세요.",
  // healthcare_rep_badup_subTitle:
  //   "호흡수가 평균보다 높게 측정되었습니다. 빠른 호흡은 스트레스, 신체적 문제 등 다양한 원인으로 발생할 수 있습니다. 만약 빠른 호흡이 지속되거나 다른 증상과 함께 느껴진다면, 전문가와의 상의를 고려해야 합니다.",

  healthcare_rep_good_subTitle:
    "호흡수가 표준범위에 있어요. 이는 건강한 상태를 의미할 수 있으나, 개인의 상태나 다른 증상에 따라 다를 수 있어요. 계속해서 자신의 몸 상태를 체크하며, 이상이 발견되면 전문가의 도움을 받는 것이 좋아요.",
  healthcare_rep_normal_subTitle:
    "호흡수가 평균보다 낮게 측정되었어요. 호흡수의 감소는 다양한 원인에 기인할 수 있으며, 때로는 건강 문제의 신호일 수 있어요. 혹시 다른 증상이나 불편함이 있으시다면 주저하지 말고 전문가와 상의하세요.",
  healthcare_rep_bad_subTitle:
    "호흡수가 평균보다 높게 측정되었어요. 빠른 호흡은 스트레스, 신체적 문제 등 다양한원인으로 발생할 수 있어요. 만약 빠른 호흡이 지속되거나 다른 증상과 함께 느껴진다면, 전문가와의 상의를 고려해야 해요.",

  healthcare_psi_good_title: "피로도가 표준 범위 이내네요.#계속 잘 관리하세요!",
  healthcare_psi_normalup_title: "피로도가 조금 높아#주의가 필요합니다.",
  healthcare_psi_normaldown_title:
    "피로도가 약간 낮아#균형 잡힌 관리가 필요해요",
  healthcare_psi_bad_title: "과부화 상태!#피로 회복 관리가 필요해요.",

  healthcare_psi_good_resultTitle:
    "피로도가 표준 범위 이내입니다.#안정을 유지해보세요.",
  healthcare_psi_normalup_resultTitle:
    "표준 평균 수치보다 피로도가#@이 더 높아요.",
  healthcare_psi_normaldown_resultTitle:
    "표준 평균 수치보다 피로도가#@이 더 낮아요.",
  healthcare_psi_bad_resultTitle: "표준 평균 수치보다 피로도가#@이 더 높아요.",

  // healthcare_psi_good_subTitle:
  //   "피로도가 표준 범위에 있습니다. 일상 속에서 규칙적인 스트레스 관리와 긍정적인 생활 습관의 중요성을 이미 알고 계시는 것 같아요. 이렇게 흔들림 없는 마음을 계속 유지하며, 건강한 일상을 즐겨보세요.",
  // healthcare_psi_normalup_subTitle:
  //   "현재 피로도가 높게 측정되었습니다. 일상 활동이나 스트레스, 부족한 수면 등 다양한 원인으로 피로가 증가할 수 있습니다. 적절한 휴식과 균형 잡힌 식사, 물 섭취를 증가시켜 몸의 복원을 돕습니다.",
  // healthcare_psi_normaldown_subTitle:
  //   "현재 피로도가 낮게 측정되었습니다. 이는 상대적으로 활력이 있고, 재충전이 잘 되어 있음을 의미합니다. 그러나 지속적으로 낮은 피로도는 과도한 활동을 의미할 수도 있으므로, 적절한 휴식과 균형 잡힌 생활 습관의 중요성을 잊지 마세요.",
  // healthcare_psi_bad_subTitle:
  //   "현재 피로도가 높게 측정되었습니다. 피로 회복 관리에 특별히 주의를 기울이는 것이 좋을 시기입니다. 건강한 일상을 위해 휴식을 취하고, 자기 관리에 시간을 투자해 보세요.",
  healthcare_psi_good_subTitle:
    "현재 피로도 지수는 낮게 측정되었어요. 이는 상대적으로 활력이 있고, 재충전이 잘 되어있음을 의미해요. 규칙적인 운동과 식이요법을 유지하고 충분한 휴식을 취하세요.",
  healthcare_psi_normal_subTitle:
    "피로도가 표준 범위에 있네요. 일상 속에서 규칙적인 스트레스 관리와 긍정적인 생활 습관의 중요성을 이미 알고 계시는 것 같아요. 잘 쉬고 올바른 휴식을 취하면서 몸과 마음을 챙겨주는 것이 중요합니다. 균형 잡힌 활동과 휴식을 유지하며 자신의 에너지를 잘 관리하세요. 계속해서 건강하고 활기찬 일상을 유지할 수 있도록 노력해 보세요.",
  healthcare_psi_bad_subTitle:
    "피로도가 높게 측정되었어요. 피로가 지속되면 여러 가지 신체적 및 정신적 문제가 발생할 수 있어요. 일반적으로 피로는 몸이나 정신적으로 지칠 때 나타나며, 잠자리에 들어도 충분한 휴식을 취하지 못한 상태에서도 계속되는 경우가 많아요. 피로가 지속되면 우울감과 불안장애의 발생위험 증가, 면역력 저하, 근육통, 소화 문제 유발, 집중력, 기억력 저하 등의 문제가 발생할 수 있어요. 따라서 피로가 계속되는 경우, 적절한 휴식과 스트레스 관리, 건강한 식습관, 충분한 운동 등을 통해 피로를 관리하고, 만약 지속되는 경우 의료 전문가와 상담하여 기본적인 건강 문제나 기저 질환의 가능성을 검토하는 것이 중요해요.",

  healthcare_msi_good_title: "스트레스가 표준 범위네요.#계속 잘 관리하세요!",
  healthcare_msi_normalup_title: "스트레스가 조금 높아#주의가 필요합니다.",
  healthcare_msi_normaldown_title:
    "스트레스가 약간 낮아#균형 잡힌 관리가 필요해요.",
  healthcare_msi_bad_title: "과부화 상태!#스트레스 관리가 필요해요.",

  healthcare_msi_good_resultTitle:
    "스트레스가 표준 범위 이내입니다.#안정을 유지해보세요.",
  healthcare_msi_normalup_resultTitle:
    "표준 평균 수치보다 스트레스가#@이 더 높아요.",
  healthcare_msi_normaldown_resultTitle:
    "표준 평균 수치보다 스트레스가#@이 더 낮아요.",
  healthcare_msi_bad_resultTitle:
    "표준 평균 수치보다 스트레스가#@이 더 높아요.",

  // healthcare_msi_good_subTitle:
  //   "스트레스 수치가 표준 범위에 있습니다. 일상 속에서 규칙적인 스트레스 관리와 긍정적인 생활 습관의 중요성을 이미 알고 계시는 것 같아요. 이렇게 흔들림 없는 마음을 계속 유지하며, 건강한 일상을 즐겨보세요.",
  // healthcare_msi_normalup_subTitle:
  //   "현재 스트레스가 높게 측정되었습니다. 일상 활동이나 스트레스, 부족한 수면 등 다양한 원인으로 스트레스가 증가할 수 있습니다. 적절한 휴식과 균형 잡힌 식사, 물 섭취를 증가시켜 몸의 복원을 돕습니다.",
  // healthcare_msi_normaldown_subTitle:
  //   "현재 스트레스 지수가 낮게 측정되었습니다. 이는 상대적으로 활력이 있고, 재충전이 잘 되어 있음을 의미합니다. 그러나 지속적으로 낮은 피로도는 과도한 활동을 의미할 수도 있으므로, 적절한 휴식과 균형 잡힌 생활 습관의 중요성을 잊지 마세요.",
  // healthcare_msi_bad_subTitle:
  //   "현재 스트레스 지수가 높게 측정되었습니다. 스트레스 관리에 특별히 주의를 기울이는 것이 좋을 시기입니다. 건강한 일상을 위해 휴식을 취하고, 자기 관리에 시간을 투자해 보세요.",

  healthcare_msi_good_subTitle:
    "현재 스트레스가 낮게 측정되었어요. 이는 상대적으로 활력이 있고, 재충전이 잘 되어 있음을 의미합니다. 지속적으로 일상적인 활동에 만족을 느끼며 몸과 마음을 쉬게 해주세요. 취미 활동이나 친구들과 함께하는 시간을 즐겨보세요. 나만의 스트레스 관리 기술을 익히고 긍정적인 생각을 유지하세요.",
  healthcare_msi_normal_subTitle:
    "스트레스 수치가 표준 범위에 속하지만, 지속적인 스트레스 관리가 필요해요! 스트레스는 심리적, 환경적, 사회적 요인 등 다양한 원인에 의해 나타날 수 있어요. 스트레스를 받게 되면 스트레스 호르몬이 방출되고 심박수와 혈압, 호흡이 높아질 수 있고 피로나 두통, 수면장애 등의 증상을 보일 수 있어요. 그러므로, 명상, 스트레스, 운동 등을 통한 자신만의 스트레스 해소법을 찾아 관리하는 것이 중요해요. 햇빛을 받으면 세로토닌이 활성화 되어 스트레스를 해소시킬 수 있어요. 잠시 시간을 내어 햇빛을 받으며 산책을 하는 건 어떨까요?",
  healthcare_msi_bad_subTitle:
    "스트레스가 높게 측정되었어요. 일상 활동이나 스트레스, 부족한 수면 등 다양한 원인으로 스트레스가 증가할 수 있어요. 스트레스가 지속될 경우 신체적, 정신적 질병이 발생할 수 있어요. 따라서 스트레스를 관리하고 줄이는 것은 신체와 정신건강을 유지하는 데 매우 중요해요. 무엇보다 평소 규칙적인 생활습관을 갖는 것이 스트레스 관리의 시작이에요. 실천 가능한 관리 방법에는 음식은 천천히 먹는 습관을 갖고, 잠은 6~8시간정도 충분히 자는 것이 좋아요. 규칙적인 운동, 스트레스 관리 기술(예: 명상, 근육 이완), 취미와 관심사에 시간을 투자하는 것도 스트레스 관리에 좋은 방법이에요. 만약 스트레스가 지속되고, 건강에 부정적인 영향을 줄 것으로 우려된다면 의료 전문가와 상담하는 것이 중요해요",

  barchart_me: "사용자님",
  barchart_standard: "표준 평균 수치",
  inbody_header_title: "인바디 검사 결과입니다.",
  inbody_header_subtitle: "각 항목을 탭해주세요.",
  check_url: "URL을 확인해주세요.",
  normal: "정상",
  warning: "주의 필요",
  management: "관리 필요",
  standard: "표준 이내",
  below_standard: "표준 미만",
  above_standard: "표준 초과",
  healthcare_normal_0:
    "전반적으로 건강에 대한#적극적 관리가 필요한 단계입니다.",
  healthcare_normal_1: "건강에 대한 관심이#필요한 단계입니다.",
  healthcare_normal_2hr: "심박수 항목에 특히#주의가 필요한 단계입니다.",
  healthcare_normal_2rep: "호흡수 항목에 특히#주의가 필요한 단계입니다.",
  healthcare_normal_2psi: "피로도 항목에 특히#주의가 필요한 단계입니다.",
  healthcare_normal_2msi: "스트레스 항목에 특히#주의가 필요한 단계입니다.",
  healthcare_normal_3hr: "심박수 항목에 대한#주의가 필요한 단계입니다.",
  healthcare_normal_3rep: "호흡수 항목에 대한#주의가 필요한 단계입니다.",
  healthcare_normal_3psi: "피로도 항목에 대한#주의가 필요한 단계입니다.",
  healthcare_normal_3msi: "스트레스 항목에 대한#주의가 필요한 단계입니다.",
  healthcare_normal_4: "모두 정상!#계속 이대로 유지하세요!",
  footer_valid: "모바일에서 시도해주세요.",
  footer_chrome_safari: "Chrome과 Safari를 이용해주세요.",
  footer_copy: "복사",
  footer_share: "공유",
  footer_clipboard: "클립보드에 복사되었습니다.",

  blood_vessel_result_a_type: "표준(a타입)",
  blood_vessel_result_b_type: "표준(b타입)",
  blood_vessel_result_c_type: "주의(c타입)",
  blood_vessel_result_d_type: "주의(d타입)",
  blood_vessel_result_e_type: "진단 필요(e타입)",
  blood_vessel_result_f_type: "진단 필요(f타입)",
  blood_vessel_result_g_type: "진단 필요(g타입)",
  blood_vessel_result_health_title: "혈관 상태가 양호해요.",
  blood_vessel_result_waring_title: "혈관 건강 주의가 필요해요.",
  blood_vessel_result_danger_title: "전문가와의 상담이 필요해요.",
  blood_vessel_result_summary_health_text:
    "혈관 타입이 ‘표준’(으)로, 2–30대의 파형을 띄고 있어 양호합니다. 올바른 생활 습관과 규칙적인 운동으로 현재의 상태를 유지하세요.",
  blood_vessel_result_summary_waring_text:
    "혈관 타입이 ‘주의’(으)로, 4–50대의 파형을 띄고 있어 주의가 필요합니다. 꾸준한 관리와 생활습관 개선을 고려해보세요.",
  blood_vessel_result_summary_danger_text:
    "혈관 타입이 ‘진단 필요’(으)로, 6–70대의 파형을 띄고 있어 관리가 필요합니다. 전문가와의 상담과 생활습관 개선이 시급합니다.",
  blood_vessel_result_button_back: "홈",
  blood_vessel_result_button_move_mobile_page: "모바일로 받아보기",
  blood_vessel_result_measure_date_text: "측정 기준",
  blood_vessel_result_user_age_text: "(만)@세",
  blood_vessel_summary_result_title_text: "결과 요약",
  blood_vessel_summary_result_blood_vessel_health_type_title_text:
    "혈관 건강도 타입은 3타입(정상, 주의, 관리)이며,#혈관 건강 지수는 낮을수록 좋습니다.",
  blood_vessel_summary_result_blood_vessel_health_type_text: "혈관 건강도 타입",
  blood_vessel_summary_result_blood_vessel_health_index_text: "혈관 건강 지수",
  blood_vessel_summary_result_blood_vessel_health_type_title_dialog_button:
    "혈관 건강도 타입은 무엇인가요?",
  blood_vessel_summary_result_blood_vessel_health_index_chart_title_dialog_button:
    "혈관 건강 지수의 파형은 어떻게 보는건가요?",
  blood_vessel_result_detail_title_text: "측정 상세결과",
  blood_vessel_result_detail_blood_vessel_age_title_text:
    "혈관 건강 나이는 동일 연령대 대비 사용자의#혈관 나이입니다.",
  blood_vessel_tip_food_title_text: "음식",
  blood_vessel_tip_exercise_title_text: "운동",
  blood_vessel_tip_check_up_title_text: "검진",
  blood_vessel_tip_rest_title_text: "휴식",
  blood_vessel_tip_food_body:
    "포화지방, 트랜스 지방, 나트륨 섭취를 줄이고, 식물 기반의 다양한 음식(과일, 채소, 전곡, 견과류)을 섭취하세요. 또한 과도한 알코올 섭취는 혈압을 높일 수 있습니다. 적당한 양으로 알코올을 섭취하세요.",
  blood_vessel_tip_exercise_body:
    "혈관 건강을 위해 중·저강도의 유산소 운동(걷기, 자전거 타기)을 주 3~5회, 각 30분씩 꾸준히 실시하세요. 또한, 스트레칭이나 요가로 유연성을 높여 혈액 순환도 개선해보세요.",
  blood_vessel_tip_check_up_body:
    "정기적인 건강검진을 통해 혈관 상태를 확인하세요. 특히 콜레스테롤, 혈압, 혈당 수치를 관리하며, 필요시 전문의와 상담해 혈관 건강을 개선하는 방법을 찾아보세요.",
  blood_vessel_tip_rest_body:
    "적절한 휴식은 혈관 건강에 필수입니다. 스트레스 감소를 위해 꾸준한 휴식 시간을 갖고, 깊은 숙면을 취하는 것이 중요합니다. 또한, 명상이나 깊은 호흡 등으로 일상의 긴장을 풀어 혈관 건강을 지키세요.",
  blood_vessel_measuring_title: "혈관 상태를#측정 중이에요.",
  blood_vessel_measuring_count: "약 @초 남음",
  blood_vessel_measuring_subtitle: "측정 중에는 안정된 상태를#유지해주세요.",
  blood_vessel_measuring_my_ppg: "나의 맥파(PPG)",
  blood_vessel_measuring_chart_title:
    "심장 박동을 통해 혈류가 움직이는 모습입니다.",
  blood_vessel_measuring_back: "뒤로",
  blood_vessel_count: "초",
  m_d_hh_mm: "월@일",
  ppg_avg_title: "평균 맥박",
  ppg_state_title: "맥박 상태",
  user_ppg_result_title: "사용자 수치",
  age_ppg_avg_title: "연령 평균",
  user: "사용자",
  age_range_ppg_avg_title: "연령대 평균",
  low: "낮음",
  high: "높음",
  average: "평균",
  blood_vessel_health_title: "혈관 건강도",
  user_age: "사용자 나이",
  blood_vessel_age: "혈관 나이",
  compare_user_age: "사용자 나이 대비",
  blood_vessel_health_type_dialog_title:
    "혈관 유형은#혈관 내부 구조 유형을 말해요.",
  blood_vessel_health_type_dialog_subtitle:
    "크게 3가지 유형으로 구성되어있어요.",
  blood_vessel_health_index_chart_type_dialog_title:
    "혈관 건강지수의 파형은#혈관의 탄성도를 말해요.",
  blood_vessel_health_index_chart_type_dialog_subtitle:
    "총 4단계로 구성되어 있어요.",
  blood_vessel_health_age_dialog_title:
    "혈관 건강도는#혈관의 기능과 상태를 말해요.",
  blood_vessel_health_age_dialog_subtitle: "알기 쉽게 설명해드릴게요!",
  blood_vessel_value: "맥박 수치",
  blood_vessel_my_value: "내 수치",
  blood_vessel_measure_result: "혈관 건강도 측정결과",
  blood_vessel_type: "타입",

  better_title: "이렇게 하면 더 좋아요",

  better_category_food: "음식",
  better_category_exercise: "운동",
  better_category_breath: "호흡 연습",

  better_title_food_hr_good: "저지방 단백질, 오메가 3가 풍부한 음식  ",
  better_title_food_hr_up: "마그네슘과 오메가 3, 항산화 물질이 포함된 식단 ",
  better_title_food_hr_down: "오메가3, 섬유질, 칼륨이 포함된 식단 ",
  better_title_food_rep_good:
    "항산화제, 오메가-3, 비타민 D, 마그네슘이 풍부한 음식 ",
  better_title_food_rep_up: "마그네슘, 오메가 3, 비타민 b가 포함된 식단 ",
  better_title_food_rep_down:
    "철분, 비타민 C, 오메가 3, 마그네슘이 포함된 식단 ",
  better_title_food_msi_up: "마그네슘, 오메가 3, 비타민이 포함된 식단 ",
  better_title_food_msi_down: "오메가 3, 복합 탄수화물, 비타민이 풍부한 음식 ",
  better_title_food_psi_up:
    "철분, 복합 탄수화물, 단백질, 오메가 3가 포함된 식단 ",
  better_title_food_psi_down: "단백질, 철분, 비타민이 풍부한 음식 ",

  better_title_exercise_hr_good:
    "유산소 및 근력 운동으로 정상 심박수 유지하기 ",
  better_title_exercise_hr_up:
    "가벼운 유산소와 근력 운동으로 심혈관 건강 강화하기 ",
  better_title_exercise_hr_down:
    "정기적인 유산소 및 근력 운동으로 심장 근육 강화하기 ",
  better_title_exercise_rep_good:
    "유산소, 호흡 조절, 근력 운동으로 안정 상태 유지하기 ",
  better_title_exercise_rep_up:
    "요가, 명상, 가벼운 산책, 스트레칭으로 호흡 수 조절하기 ",
  better_title_exercise_rep_down:
    "유산소, 요가, 스트레칭으로 심폐 기능 향상시키기 ",
  better_title_exercise_msi_up:
    "유산소, 요가, 명상, 근력 운동으로 몸과 마음을 진정시키기 ",
  better_title_exercise_msi_down:
    "오메가 3, 복합 탄수화물, 비타민이 풍부한 음식 ",
  better_title_exercise_psi_up:
    "가벼운 유산소와 스트레칭 몸의 긴장감 완화하기 ",
  better_title_exercise_psi_down: "근력 운동과 스트레칭으로 안정 상태 유지하기",

  // 음식
  better_comment_food_hr_good:
    "심박수가 정상 범위일 때 심장 건강과 전반적인 건강을 증진하기 위해, 전곡물, 다양한 과일과 채소, 지방이 낮은 단백질원(가금류, 생선, 콩류), 오메가-3 지방산이 풍부한 생선, 그리고 건강한 지방(아보카도, 올리브 오일, 견과류)을 포함한 다양하고 균형 잡힌 식사가 중요합니다. \n\n충분한 수분 섭취와 카페인, 당분이 많은 음료의 제한 역시 심장 건강을 유지하는 데 필수적입니다. \n\n이러한 영양소를 균형 있게 섭취함으로써 심장 질환의 위험을 줄이고 심박수를 건강한 수준으로 유지할 수 있습니다. ",
  better_comment_food_hr_up:
    "심박수가 빠를 때, 마그네슘(시금치, 아몬드, 검은콩)과 오메가-3 지방산(연어, 고등어, 아마씨)이 풍부한 음식, 그리고 항산화제가 많은 과일과 채소(베리류, 케일) 및 항산화 물질이 풍부한 음료(녹차, 허브차) 섭취가 권장됩니다. \n\n카페인, 알코올, 고당분 가공식품은 피하고 충분한 수분 섭취와 휴식을 취하는 것도 심박수를 안정시키고 심혈관 건강을 개선하는 데 중요합니다. ",
  better_comment_food_hr_down:
    "심박수가 표준 범위보다 낮을 때, 오메가-3 지방산(연어, 참치, 아마씨, 치아씨, 호두), 섬유질(채소, 과일, 전곡류, 콩류), 칼륨(바나나, 감자, 시금치, 살구), 항산화제(베리류, 다크 초콜릿, 아티초크, 견과류)가 풍부한 음식을 섭취하는 것이 좋습니다. \n\n이러한 식품들은 심박수 안정화, 심장 리듬 개선, 소화 촉진, 혈당 및 콜레스테롤 조절, 심장 및 혈관 건강 보호에 유익하며 심혈관 시스템에 부담을 줄입니다. \n\n지속적으로 낮은 심박수에 대해서는 전문의와 상담하는 것이 중요합니다.",
  better_comment_food_rep_good:
    "호흡수가 표준 범위 이내일 때, 호흡 기능 강화와 신체 건강 지원을 위해 항산화제가 풍부한 과일과 채소(베리류, 시금치, 브로콜리), 오메가-3 지방산(연어, 호두), 비타민 D(계란 노른자, 지방이 많은 생선), 마그네슘(아몬드, 호박씨)을 포함한 음식을 섭취하는 것이 좋습니다.  \n\n이러한 영양소는 세포 보호, 염증 감소, 호흡 근육 기능 지원에 유익하며, 균형 잡힌 식단을 통해 전반적인 건강과 호흡 건강을 증진시킬 수 있습니다. ",
  better_comment_food_rep_up:
    "호흡수가 높을 때, 신체와 정신의 긴장을 줄이고 진정시키기 위해 마그네슘(시금치, 아몬드, 호박씨), 오메가-3 지방산(연어, 아마씨, 호두), 비타민 B6와 B12(닭고기, 연어, 유제품)가 풍부한 음식과 카모마일, 라벤더 허브 티의 섭취가 유익합니다. \n\n충분한 수분 섭취도 중요하며, 이러한 영양소와 수분은 호흡수가 높은 상태를 완화하고 스트레스 반응을 줄이는 데 도움을 줍니다.  \n\n이러한 음식과 음료를 규칙적으로 섭취하면 신체적, 정신적 안정을 촉진할 수 있습니다. ",
  better_comment_food_rep_down:
    "호흡수가 표준 범위보다 낮을 때, 산소 운반 능력을 향상시키고 호흡 기능을 지원하기 위해 철분(시금치, 렌틸콩, 적색 육류), 비타민 C(오렌지, 키위, 브로콜리)가 풍부한 음식과 오메가-3 지방산(연어, 아마씨), 마그네슘(아몬드, 호박씨)을 포함한 식단을 섭취하는 것이 좋습니다. \n\n이러한 영양소는 산소화를 촉진하고, 철분의 흡수를 돕고, 호흡 근육의 이완을 지원하여 호흡 건강을 개선하고 전반적인 건강을 증진시킬 수 있습니다. ",
  better_comment_food_msi_good:
    "스트레스가 표준 범위일 때, 마그네슘(시금치, 호박씨, 아몬드), 오메가-3 지방산(연어, 아마씨, 호두), 복합 탄수화물(전곡빵, 귀리, 현미), 비타민 B군(전곡 제품, 달걀, 유제품), 비타민 C(오렌지, 키위, 브로콜리), 트립토판(칠면조, 닭고기, 우유)을 포함한 식단을 섭취하여 신체와 정신 건강을 유지하고 스트레스를 관리하는 것이 좋습니다.  \n\n이러한 음식들은 신경계를 진정시키고, 에너지 수준을 유지하며, 스트레스 호르몬을 줄이는 효과가 있습니다.  \n\n균형 잡힌 식단, 규칙적인 운동, 충분한 수면, 그리고 스트레스 관리 기술의 실천은 전반적인 건강을 증진시키는 데 중요합니다. ",
  better_comment_food_msi_up:
    "스트레스가 높을 때는 마그네슘(시금치, 호박씨, 아몬드), 오메가-3 지방산(연어, 아마씨, 호두), 비타민 B군(전곡 제품, 달걀, 유제품) 및 비타민 C(오렌지, 키위, 브로콜리)가 풍부한 음식을 섭취하여 신경계를 진정시키고, 정서적 안정을 촉진하며, 스트레스 호르몬 수치를 낮추는 것이 중요합니다.  \n\n또한, 트립토판이 풍부한 단백질 식품(칠면조, 닭고기, 우유)과 충분한 수분 섭취는 기분을 개선하고 스트레스 반응을 완화하는데 도움을 줍니다.  \n\n이러한 균형 있는 식단은 신체적, 정신적 웰빙을 유지하고 스트레스를 효과적으로 관리하는 데 필수적입니다. ",
  better_comment_food_psi_good:
    "피로도가 표준 범위 이내일 때 에너지 수준을 유지하고 활력을 증진시키기 위해, 복합 탄수화물(전곡빵, 귀리, 현미), 단백질(닭고기, 생선, 콩류, 견과류), 철분(시금치, 적색 육류, 렌틸콩), 오메가-3 지방산(연어, 아마씨, 치아씨)을 포함한 영양소가 풍부한 음식을 지속적으로 섭취하는 것이 좋습니다.  \n\n또한, 비타민과 미네랄이 풍부한 과일과 채소의 섭취와 충분한 수분 섭취는 전반적인 건강을 증진시키고 에너지 수준을 높이는 데 중요합니다.  \n\n이러한 균형 있는 식단은 일상 생활에서 건강을 유지하고 피로를 관리하는 데 도움이 됩니다. ",
  better_comment_food_psi_up:
    "피로감을 줄이고 에너지를 증진시키기 위해 철분이 풍부한 음식(시금치, 적색 육류, 렌틸콩), 복합 탄수화물(전곡빵, 귀리, 현미), 단백질(닭고기, 생선, 콩류, 견과류), 오메가-3 지방산(연어, 아마씨, 치아씨)을 포함한 균형 있는 식단과 충분한 수분 섭취가 중요합니다.  \n\n이러한 영양소는 일상 생활에서의 에너지 수준을 높이고 지속적인 피로를 관리하는 데 도움이 됩니다. ",

  // 운동
  better_comment_exercise_hr_good:
    "심박수가 표준일 때 심장 건강을 유지하고 증진시키기 위해, 유산소 운동(빠르게 걷기, 조깅, 자전거 타기, 수영, 댄스)과 근력 운동(무게 들어올리기, 보디웨이트 운동, 탄력 밴드 운동)을 포함하는 다양한 활동을 규칙적으로 실천하는 것이 추천됩니다. \n\n이러한 운동은 심혈관 시스템을 강화하고, 심장 박동을 증진시키며, 근육을 강화하여 심장의 효율성을 높입니다. \n\n운동 계획을 세울 때는 개인의 체력 수준과 건강 상태를 고려하고, 필요시 전문가의 조언을 구하는 것이 중요합니다. ",
  better_comment_exercise_hr_up:
    "심박수가 빠를 때는 심장에 과도한 부담을 주지 않으면서 심혈관 시스템을 강화할 수 있는 저강도에서 중강도 수준의 유산소 운동을 선택하는 것이 중요합니다. 걷기와 경량 덤벨을 이용한 가벼운 근력 운동은 심박수를 적절히 관리하며 심장 건강을 개선할 수 있는 효과적인 방법입니다. \n\n운동 전 의사나 건강 전문가와 상담하여 안전하게 운동 계획을 세우고, 과도한 강도를 피하며 체력 수준에 맞게 조절하는 것이 권장됩니다. ",
  better_comment_exercise_hr_down:
    "심박수가 표준 범위보다 낮을 때, 심장 근육을 강화하고 혈액 순환을 개선하기 위해 유산소 운동(걷기, 조깅, 수영, 자전거 타기)과 근력 운동(가벼운 웨이트 트레이닝, 저항 밴드 운동)을 포함하는 운동을 고려하는 것이 좋습니다. \n\n이러한 활동은 심박수를 자연스럽게 높이고 심장의 효율성을 개선하는 데 유익합니다. \n\n운동 시작 전 의사와의 상담을 통해 개인의 건강 상태와 체력 수준에 맞는 적합한 운동 계획을 세우는 것이 중요하며, 지속적으로 낮은 심박수에 대한 전문가의 조언을 구하는 것이 권장됩니다.",
  better_comment_exercise_rep_good:
    "호흡수가 표준일 때, 신체와 호흡 기능을 강화하며 전반적인 건강을 유지하기 위해 유산소 운동(걷기, 조깅, 자전거 타기, 수영), 요가나 필라테스, 근력 트레이닝을 포함하는 다양한 운동을 규칙적으로 실천하는 것이 좋습니다. \n\n이들 운동은 심장 건강과 호흡 효율성을 증진시키고, 심폐 기능과 근육의 힘을 향상시키며, 스트레스 관리와 정신적 웰빙을 증진시킬 수 있습니다.  \n\n개인의 체력과 관심사에 맞게 운동 계획을 다양화하고 선택하는 것이 중요합니다. ",
  better_comment_exercise_rep_up:
    "호흡수가 높을 때, 신체와 마음을 진정시키고 호흡을 조절하기 위해 요가, 명상 또는 마음챙김, 가벼운 산책이나 조깅, 스트레칭 또는 필라테스와 같은 운동을 선택하는 것이 중요합니다.  \n\n이들 활동은 몸과 마음의 긴장을 풀고, 스트레스 호르몬을 감소시키며, 호흡을 깊고 규칙적으로 만드는 데 도움을 줍니다.  \n\n규칙적인 실천을 통해 신체적, 정신적 안정을 촉진하고 스트레스를 줄일 수 있습니다. ",
  better_comment_exercise_rep_down:
    "호흡수가 표준 범위보다 낮을 때, 심폐 기능 증진과 산소 흡수 개선을 위해 가벼운 유산소 운동(빠르게 걷기, 가벼운 조깅, 수영), 호흡 조절에 초점을 맞춘 요가나 필라테스, 규칙적인 스트레칭을 포함하는 운동을 실천하는 것이 좋습니다. \n\n이러한 활동은 호흡 근육을 강화하고, 심폐 기능을 향상시키며, 호흡 패턴을 개선하는 데 유익합니다.  \n\n개인의 체력 수준과 건강 상태를 고려하여 운동 계획을 세우는 것이 중요합니다. ",
  better_comment_exercise_msi_good:
    "스트레스가 표준 범위일 때 신체와 정신의 균형을 유지하고 스트레스를 관리하기 위해, 유산소 운동(걷기, 조깅, 자전거 타기, 수영), 요가와 필라테스, 근력 트레이닝(웨이트 트레이닝, 저항 밴드 운동), 명상 및 호흡 운동을 포함하는 다양한 활동을 꾸준히 하는 것을 권장합니다.  \n\n이러한 활동들은 심장을 강화하고, 몸의 긴장을 풀며, 긍정적인 기분을 증진시키고, 정신적 명료성을 향상시키는 등 스트레스 호르몬 수준을 낮추는데 도움을 줍니다.  \n\n규칙적인 실천을 통해 스트레스 관리와 전반적인 웰빙을 증진시킬 수 있습니다. ",
  better_comment_exercise_msi_up:
    "스트레스가 높을 때, 유산소 운동(걷기, 조깅, 자전거 타기, 수영), 요가와 필라테스, 명상 및 호흡 운동, 그리고 근력 트레이닝을 포함한 다양한 형태의 운동을 선택하는 것이 신체적, 정신적 안정을 찾고 스트레스를 줄이는 데 중요합니다. \n\n이러한 활동들은 심장을 강화하고, 몸과 마음의 긴장을 풀며, 기분을 개선하고, 스트레스 호르몬 수준을 낮추는 등 긍정적인 영향을 제공합니다.  \n\n정기적인 실천을 통해 스트레스 관리와 건강한 생활 방식 유지에 도움이 됩니다. ",
  better_comment_exercise_psi_good:
    "피로도가 표준 범위 이내일 때, 체력 유지와 건강 증진을 위해 유산소 운동(걷기, 조깅, 자전거 타기, 수영), 근력 트레이닝(웨이트 트레이닝, 저항 밴드 운동), 그리고 유연성 및 균형 운동(요가, 필라테스, 스트레칭)을 포함하는 다양한 운동을 규칙적으로 수행하는 것이 좋습니다. \n\n이러한 활동은 에너지 수준과 기분을 높이며, 스트레스를 줄이고, 근육의 긴장을 풀어 전반적으로 건강을 향상시킵니다.  \n\n운동 계획은 개인의 체력 수준과 관심사에 맞추어 다양하게 구성하는 것이 중요합니다. ",
  better_comment_exercise_psi_up:
    "피로감이 높을 때는 가벼운 유산소 운동(걷기, 가벼운 조깅, 자전거 타기), 스트레칭, 요가, 필라테스, 수영과 같이 스트레스를 줄이고 에너지를 증진시키는 활동이 이상적입니다.  \n\n이러한 운동들은 신체에 활력을 주고 근육의 긴장을 풀어주며 혈액 순환을 개선하여 전반적으로 건강을 향상 시킵니다. 운동량을 체력 수준에 맞추고 필요한 경우 전문가의 조언을 구하며, 과도한 피로를 피하는 것이 중요합니다. ",

  // 숨
  better_comment_breath:
    "깊고 느린 호흡이나 명상은 마음을 진정시키고 스트레스를 낮추는 데 도움이 됩니다. 호흡 연습은 깊게 숨을 들이마시고 천천히 내쉬며, 명상은 편안한 곳에서 호흡에 집중하며 실시합니다. 꾸준한 연습이 중요합니다.",

  // glucose Comment
  better_glucose_food: "저지방, 저염식",
  better_glucose_exercise: "규칙적인 운동",
  better_glucose_water: "물 섭취",
  better_glucose_comment_food:
    "음식을 조리할 때는 식용유를 줄이고, 소금 대신 허브와 스파이스로 맛을 내보세요. 아울러 가공식품보다는 신선한 과일과 채소를 즐기는 습관을 기르는 것도 좋은 방법이랍니다. ",
  better_glucose_comment_exercise:
    "규칙적인 운동은 몸과 마음의 건강을 위해 필수적입니다. 혈당을 안정시키고 체중을 관리하며, 심혈관 건강을 향상시키고 스트레스를 감소시키는 데 큰 도움이 됩니다. 일상 속에 꾸준한 운동 습관을 통해 더 건강하고 활력 넘치는 삶을 즐겨보세요!",
  better_glucose_comment_water:
    "물은 몸의 60%를 차지하며, 독소 제거, 체온 조절, 관절 보호, 소화 지원 및 영양 운반에 필수입니다. 건강 유지를 위해 하루 2-3리터의 물 섭취가 권장됩니다.",

  better_blood_type_food: "음식",
  better_blood_type_exercise: "운동",
  better_blood_type_medical: "검진",
  better_blood_type_rest: "휴식",

  better_blood_comment_food:
    "포화지방, 트랜스 지방, 나트륨 섭취를 줄이고, 식물 기반의 다양한 음식(과일, 채소, 전곡, 견과류)을 섭취하세요. 또한 과도한 알코올 섭취는 혈압을 높일 수 있습니다. 적당한 양으로 알코올을 섭취하세요.",
  better_blood_comment_exercise:
    "혈관 건강을 위해 중·저강도의 유산소 운동(걷기, 자전거 타기)을 주 3~5회, 각 30분씩 꾸준히 실시하세요. 또한, 스트레칭이나 요가로 유연성을 높여 혈액 순환도 개선해보세요.",
  better_blood_comment_medical:
    "정기적인 건강검진을 통해 혈관 상태를 확인하세요. 특히 콜레스테롤, 혈압, 혈당 수치를 관리하며, 필요시 전문의와 상담해 혈관 건강을 개선하는 방법을 찾아보세요.",
  better_blood_comment_rest:
    "적절한 휴식은 혈관 건강에 필수입니다. 스트레스 감소를 위해 꾸준한 휴식 시간을 갖고, 깊은 숙면을 취하는 것이 중요합니다. 또한, 명상이나 깊은 호흡 등으로 일상의 긴장을 풀어 혈관 건강을 지키세요.",

  glucose_popup_title: "혈관 건강지수의 파형은#혈관의 탄성도를 말해요.",
  glucose_popup_sub: "총 4단계로 구성되어 있어요.",
  glucose_result_title: "혈당 측정결과",
  result_title: "진단 결과",
  glucose_result_high_text: "정상 기준치보다 @mg/dL 높습니다",
  glucose_result_low_text: "정상 기준치보다 @mg/dL 낮습니다",
  glucose_line_chart_title: "최근 건강 상태",

  glucose_grade_good_type: "정상",
  glucose_grade_good_title: "축하합니다!#혈당 수치가 정상입니다.",
  glucose_grade_good_sub: "이대로만 관리해주세요!",

  //
  glucose_grade_good_comment_up_title: "현재 생활 습관을 유지해주세요.",
  glucose_grade_good_comment_up_sub:
    "혈당 수치가 #/dL로 측정되어 '정상'에 해당됩니다. 최근 7회간 혈당 수치를 잘 유지하고 있네요! 현재의 건강한 생활 습관과 균형 잡힌 식사를 계속 유지해 주세요.",
  glucose_grade_good_comment_down_title: "현재 생활 습관을 유지해주세요.",
  glucose_grade_good_comment_down_sub: "이대로만 관리해주세요!",
  glucose_grade_good_comment_no_title: "현재 생활 습관을 유지해주세요.",
  glucose_grade_good_comment_no_sub: "이대로만 관리해주세요!",
  //-----------------------inbody---------------------------
  inbody_result_feedback_normal_0:
    "전반적으로 건강에 대한#적극적 관리가 필요한 단계입니다.",
  inbody_result_feedback_normal_1_smm:
    "골격근 항목은 정상이지만#주의가 필요한 단계입니다.",
  inbody_result_feedback_normal_1_tbw:
    "체수분 항목은 정상이지만#주의가 필요한 단계입니다.",
  inbody_result_feedback_normal_1_pbf:
    "체지방률 항목은 정상이지만#주의가 필요한 단계입니다.",
  inbody_result_feedback_normal_1_whr:
    "복부지방률 항목은 정상이지만#주의가 필요한 단계입니다.",
  inbody_result_feedback_normal_2: "건강에 대한 관심이#필요한 단계입니다.",
  inbody_result_feedback_normal_3_smm:
    "골격근 항목에 대한 주의가#필요한 단계입니다.",
  inbody_result_feedback_normal_3_tbw:
    "체수분 항목에 대한 주의가#필요한 단계입니다.",
  inbody_result_feedback_normal_3_pbf:
    "체지방률 항목에 대한 주의가#필요한 단계입니다.",
  inbody_result_feedback_normal_3_whr:
    "복부지방률 항목에 대한 주의가#필요한 단계입니다.",
  inbody_result_feedback_normal_4: "모두 정상!#계속 이대로 유지하세요!",
  permission_denied_message: "권한을 허용해주세요",
  home: "홈",
  inbody_bmi_data: "BMI @",
  inbody_after_move: " 후 이동",
  inbody_second: "@초",
  inbody_ok: "확인",
  inbody_retry: "다시 시도",
  inbody_back: "뒤로",
  inbody_do_back: "뒤로 가기",
  inbody_score: "@점",
  inbody_normal: "정상",
  inbody_needs_attention: "관리 필요",
  inbody_caution_needs: "주의 필요",
  inbody_rank: "@위",
  inbody_receive_mobile: "모바일로 받아보기",
  inbody_swipe_hint: "화면을 옆으로 밀어보세요!",
  inbody_swipe_hint_content_easy_access_hint: "콘텐츠를 손쉽게 볼 수 있어요.",
  inbody_complete: "완료",
  inbody_smm: "골격근",
  inbody_tbw: "체수분",
  inbody_pbf: "체지방률",
  inbody_whr: "복부지방률",
  inbody_gram: "@g",
  inbody_percentage: "@%",
  inbody_do_connect: "연결하기",
  inbody_cancel_connect: "연결 취소",
  inbody_standard_range: "표준범위",
  within_standard: "표준 이내",
  inbody_carbohydrate: "탄수화물",
  inbody_fat: "지방",
  inbody_protein: "단백질",
  inbody_empty: "",
  inbody_return_to_device_connection: "기기 연결로 돌아가기",
  SMM: "골격근",
  TBW: "체수분",
  PBF: "체지방률",
  WHR: "복부지방률",
  PSMM: "골격근 퍼센트",
  inbody_PBF_MIN: "체지방 최소 표준",
  inbody_PBF_MAX: "체지방 최대 표준",
  inbody_IPBF: "이상적인 체지방률",
  inbody_WHR_MAX: "복부지방 최대 표준",
  inbody_WHR_MIN: "복부지방률 최소 표준",
  inbody_SMM_MIN: "골격근 최저 표준",
  inbody_SMM_MAX: "골격근 최대 표준",
  inbody_TBW_MIN: "체수분 최저 표준",
  inbody_TBW_MAX: "체수분 최대 표준",
  bmi_1: "저체중",
  bmi_2: "표준 체중",
  bmi_3: "과체중",
  bmi_4: "비만",
  bmi_5: "고도비만",
  chicken_breast_steak: "닭가슴살 스테이크",
  salmon_steak: "연어 스테이크",
  chicken_breast: "닭가슴살",
  salmon: "연어",
  soy_sauce: "간장",
  honey: "꿀",
  garlic: "마늘",
  olive_oil: "올리브 오일",
  onion: "양파",
  fresh_lemon_juice: "레몬즙",
  inbody_inbodyconnect_instruction_start:
    "입력이 완료되었어요!#기기 연결을 시작할게요.",
  inbody_inbodyconnect_instruction_stand_on: "인바디 기기 발판에 서 주세요!",
  inbody_inbodyconnect_status_connecting: "기기를#연결하는 중이에요.",
  inbody_inbodyconnect_instruction_disconnect_when_leave_device:
    "인바디 기기의 발판에서 벗어나면#연결이 해제됩니다.",
  inbody_inbodyconnect_progress_percentage: "%d 완료",
  inbody_inbodyconnect_status_connected: "기기 연결이#완료되었습니다.",
  inbody_inbodyconnect_instruction_next_step: "곧 측정이 시작됩니다.",
  inbody_inbodyconnect_status_connecting_fail: "기기 연결에 실패했습니다.",
  inbody_inbodyconnect_status_bluetooth_connecting_fail:
    "블루투스 연결에 실패했습니다.",
  inbody_inbodyconnect_instruction_check_settings:
    "설정을 확인하고 다시 시도해주세요.",
  inbody_connecting_cancel_connection: "연결 취소",
  inbody_inbodyconnect_status_connecting_cancel: "연결이 취소되었어요.",
  inbody_inbodyconnect_instruction_press_connect:
    "연결하시려면 버튼을 눌러주세요.",
  inbody_inbodyconnect_status_connecting_delay: "연결이#지연되고 있습니다.",
  inbody_inbodyconnect_instruction_if_cant_connect_try_again:
    "계속해서 연결이 되지 않는다면,#뒤로 가서 다시 시도해주세요.",
  inbody_preparation_barefoot_title: "맨발로 검사합니다.",
  inbody_preparation_barefoot_description:
    "양말이나 스타킹은 전류의 흐름을 방해하여 체성분 측정을 정확하지 않게 합니다.",
  inbody_preparation_after_toilet_title: "화장실 다녀온 후 측정합니다.",
  inbody_preparation_after_toilet_description:
    "대소변은 체성분이 아니나, 검사 결과에 영향을 미칠 수 있습니다.",
  inbody_preparation_moist_hands_feet_title: "손과 발은 촉촉하게 해주세요.",
  inbody_preparation_moist_hands_feet_description:
    "물티슈로 손바닥과 발바닥을 충분히 닦은 후 검사해주세요.",
  inbody_preparation_header_main_text: "검사 전 확인해주세요!",
  inbody_preparation_header_sub_text: "검사 전 주의사항",
  inbody_preparation_posture_step2_main_text:
    "허벅지 사이가#닿지 않아야 합니다.",
  inbody_preparation_posture_step3_main_text:
    "발은 떼지 말고 기기에#꼭 붙여주세요.",
  inbody_preparation_posture_step4_main_text: "이제, 손잡이를#잡아주세요!",
  inbody_preparation_posture_sub_text: "검사 시 주의사항",
  inbody_preparation_go_measuring: "측정 바로가기",
  inbody_measuring_in_progress: "체성분을#측정 중이에요.",
  inbody_measuring_in_progress2: "팔과 다리가 붙지 않게#그대로 유지해주세요.",
  inbody_measuring_duration_approx: "약 60초 가량 소요됩니다.",
  inbody_measuring_warnings: "움직이시면 연결이 끊어질 수 있어요",
  inbody_measuring_completed: "측정이#완료되었습니다.",
  inbody_measuring_check_result: "측정 결과를 확인해보세요.",
  inbody_measuring_fail: "측정에 실패했습니다.",
  inbody_measuring_fail_do_not_move: "측정 중 움직이시면 안 돼요.",
  inbody_report_user_balance_evalutation_good: "밸런스가#잘 맞춰졌어요!",
  inbody_report_user_information: "@1cm, @2kg \n만 @3세",
  inbody_report_range_type_under: "필요",
  inbody_report_range_type_over: "높음",
  inbody_report_under_weight: "저체중",
  inbody_report_standard_weight: "표준 체중",
  inbody_report_over_weight: "과체중",
  inbody_report_obese_weight: "비만",
  inbody_report_severe_obese_weight: "고도비만",
  inbody_report_header_title: "인바디 검사 결과입니다.",
  inbody_report_header_title_sub: "각 항목을 탭해주세요.",
  inbody_dashboard_detail_guide_title: "식단 가이드",
  inbody_dashboard_detail_guide_description:
    "사용자님의 건강 정보를 바탕으로 @ 증진을 위한 식단을 제안합니다.",
  inbody_dashboard_detail_diet_detailed_info: "상세정보 자세히 보기",
  inbody_dashboard_detail_diet_image_description: "식단 이미지",
  inbody_dashboard_detail_daily_intake_cal_percentage:
    "@1kcal#하루 권장 섭취량의 @2",
  inbody_dashboard_detail_nutrient_info_title: "영양 정보",
  inbody_dashboard_detail_nutrient_info_title_detail: "상세 정보",
  inbody_dashboard_detail_daily_intake_note: "*하루 권장 섭취량 기준",
  inbody_dashboard_detail_daily_intake_note1: "하루 권장 섭취량의 15%",
  inbody_dashboard_detail_daily_intake_note2: "하루 권장 섭취량의 25%",
  inbody_dashboard_detail_standard_intake_note: "*1인분 기준",
  inbody_dashboard_detail_carbohydrate_format1: " / 탄수화물 @g",
  inbody_dashboard_detail_protein_format1: " / 단백질 @g",
  inbody_dashboard_detail_fat_format1: " / 지방 @g",
  inbody_dashboard_detail_title_note: "표준 평균 수치와 비교했어요.",
  inbody_dashboard_detail_evaluation_title_inner_smm:
    "골격근량이#표준 범위 내에요.",
  inbody_dashboard_detail_evaluation_title_under_smm:
    "골격근량이#표준 범위 미만이에요.",
  inbody_dashboard_detail_evaluation_title_over_smm:
    "골격근량이#표준 범위를 초과했어요.",
  inbody_dashboard_detail_evaluation_title_inner_tbw:
    "체수분량이#표준 범위 내에요.",
  inbody_dashboard_detail_evaluation_title_under_tbw:
    "체수분량이#표준 범위 미만에요.",
  inbody_dashboard_detail_evaluation_title_over_tbw:
    "체수분량이#표준 범위를 초과했어요.",
  inbody_dashboard_detail_evaluation_title_inner_pbf:
    "체지방률이#표준 범위 내에요.",
  inbody_dashboard_detail_evaluation_title_under_pbf:
    "체지방률이#표준 범위 미만에요.",
  inbody_dashboard_detail_evaluation_title_over_pbf:
    "체지방률이#표준 범위를 초과했어요.",
  inbody_dashboard_detail_evaluation_title_inner_whr:
    "복부지방률이#표준 범위 내에요.",
  inbody_dashboard_detail_evaluation_title_under_whr:
    "복부지방률이#표준 범위 미만에요.",
  inbody_dashboard_detail_evaluation_title_over_whr:
    "복부지방률이#표준 범위를 초과했어요.",
  inbody_dashboard_detail_evaluation_description_inner_smm: "잘 하고 있어요!",
  inbody_dashboard_detail_evaluation_description_under_smm:
    "꾸준한 관리가 필요해요.",
  inbody_dashboard_detail_evaluation_description_over_smm:
    "균형잡힌 관리가 필요해요.",
  inbody_dashboard_detail_evaluation_description_inner_tbw: "잘 하고 있어요!",
  inbody_dashboard_detail_evaluation_description_under_tbw:
    "꾸준한 관리가 필요해요.",
  inbody_dashboard_detail_evaluation_description_over_tbw:
    "균형잡힌 관리가 필요해요.",
  inbody_dashboard_detail_evaluation_description_inner_pbf: "잘 하고 있어요!",
  inbody_dashboard_detail_evaluation_description_under_pbf:
    "꾸준한 관리가 필요해요.",
  inbody_dashboard_detail_evaluation_description_over_pbf:
    "균형잡힌 관리가 필요해요.",
  inbody_dashboard_detail_evaluation_description_inner_whr: "잘 하고 있어요!",
  inbody_dashboard_detail_evaluation_description_under_whr:
    "꾸준한 관리가 필요해요.",
  inbody_dashboard_detail_evaluation_description_over_whr:
    "균형잡힌 관리가 필요해요.",
  inbody_dashboard_detail_evaluation_description_detail_inner_smm:
    "현재 골격근량이 표준 범위 이내입니다. 골격근은 우리 몸의 기본적인 운동 능력과 일상 생활의 품질을 결정하는 중요한 요소 중 하나입니다. 지속적인 체력 관리와 건강한 생활 습관을 유지하면서 좋은 상태를 유지해 보세요.",
  inbody_dashboard_detail_evaluation_description_detail_under_smm:
    "현재 골격근량이 표준 범위보다 낮게 측정되었습니다. 골격근량을 향상시키기 위한 꾸준한 운동과 균형 잡힌 식사가 필요합니다. 적절한 골격근량은 건강 유지에 큰 도움이 됩니다.",
  inbody_dashboard_detail_evaluation_description_detail_over_smm:
    "현재 골격근량이 표준 범위보다 높게 측정되었습니다. 이는 근육 발달이 잘 되어 있음을 나타냅니다. 그러나 과도한 근육 증가는 건강에 영향을 줄 수 있으므로, 균형 잡힌 운동과 식습관을 유지하는 것이 중요합니다.",
  inbody_dashboard_detail_evaluation_description_detail_inner_tbw:
    "현재 체수분량이 표준 범위 이내입니다. 체수분량은 신체의 전반적인 건강 상태와 밀접한 관련이 있습니다. 꾸준한 수분 섭취와 건강한 생활 습관을 유지하여 이 상태를 계속 유지하시길 바랍니다.",
  inbody_dashboard_detail_evaluation_description_detail_under_tbw:
    "현재 체수분량이 표준 범위보다 낮게 측정되었습니다. 체수분량은 우리 몸의 건강과 밀접한 관련이 있으므로, 부족한 수분을 보충하는 것이 중요합니다. 일일 권장 수분 섭취량과 현재의 생활 습관을 확인하고, 필요한 경우 전문가와 상담해보세요.",
  inbody_dashboard_detail_evaluation_description_detail_over_tbw:
    "현재 체수분량이 표준 범위보다 높게 측정되었습니다. 과도한 체수분량은 신체의 여러 기능에 영향을 줄 수 있습니다. 적절한 수분 균형을 유지하기 위해 음료 섭취량과 식사 패턴을 확인하시고, 만약 이상 증상이나 걱정이 있으시다면 전문가와 상담하는 것을 권장드립니다.",
  inbody_dashboard_detail_evaluation_description_detail_inner_pbf:
    "현재 체지방률이 표준 범위 이내입니다. 체지방률은 전반적인 건강 상태, 신체 기능, 그리고 대사 관련 건강 문제와 관련이 있습니다. 현재 상태를 유지하기 위해 꾸준한 운동과 균형 잡힌 식사를 계속하시길 권장드립니다.",
  inbody_dashboard_detail_evaluation_description_detail_under_pbf:
    "현재 체지방률이 표준 범위보다 낮게 측정되었습니다. 낮은 체지방률은 필요한 에너지 저장 및 여러 기능의 부족을 의미할 수 있으므로 주의가 필요합니다. 균형 잡힌 식사와 영양 섭취를 통해 체지방률을 적정 범위로 유지해주세요.",
  inbody_dashboard_detail_evaluation_description_detail_over_pbf:
    "현재 체지방률이 표준 범위보다 높게 측정되었습니다. 과도한 체지방률은 다양한 건강 문제의 위험을 증가시킬 수 있으므로 주의가 필요합니다. 꾸준한 운동, 균형 잡힌 식사, 그리고 생활 습관 개선을 통해 체지방률을 조절하는 것이 중요합니다. 개인의 상황에 맞는 건강 및 식생활 계획을 세우기 위해 전문가와의 상담을 권장드립니다.",
  inbody_dashboard_detail_evaluation_description_detail_inner_whr:
    "현재 복부지방률이 표준 범위 이내입니다. 복부지방은 신체의 여러 기능 및 건강 상태와 관련이 있습니다. 현재 복부지방률이 안정적인 범위에 있으므로, 꾸준한 운동과 균형 잡힌 식사를 계속하시길 바랍니다.",
  inbody_dashboard_detail_evaluation_description_detail_under_whr:
    "현재 복부지방률이 표준 범위보다 낮게 측정되었습니다. 지나치게 낮은 복부지방률은 몸의 에너지 저장 및 다양한 기능에 영향을 줄 수 있습니다. 균형 잡힌 식사와 영양 섭취를 통해 체지방률을 적정 범위로 유지하는 것이 좋습니다.",
  inbody_dashboard_detail_evaluation_description_detail_over_whr:
    "현재 복부지방률이 표준 범위보다 높게 측정되었습니다. 지나치게 높은 복부지방률은 몸의 에너지 저장 및 다양한 기능에 영향을 줄 수 있습니다. 균형 잡힌 식사와 영양 섭취를 통해 체지방률을 적정 범위로 유지하는 것이 좋습니다.",
  inbody_dashboard_detail_normal_amount_string: "@1~@2",

  //---------------glucose--------------------
  glucose_result_type_waring_title: "조금만 주의하세요,#당뇨 초기에요!",
  glucose_result_type_waring_subtitle:
    "건강한 생활습관으로 되돌리기만 하면 돼요!",
  glucose_result_type_waring_result: "당뇨 초기",
  glucose_result_type_waring_chart: "건강한 생활 습관을 유지해주세요",
  glucose_result_type_danger_title:
    "혈당 수치가 높게 나왔어요.#당뇨로 추정됩니다.",
  glucose_result_type_danger_subtitle: "전문가와 상담이 필요합니다.",
  glucose_result_type_danger_result: "당뇨",
  glucose_result_type_danger_chart: "건강 관리에 좀 더 신경써주세요",
  glucose_category_type_low_fat_low_salt_title: "저지방, 저염식",
  glucose_category_type_low_fat_low_salt_body:
    "음식을 조리할 때는 식용유를 줄이고, 소금 대신 허브와 스파이스로 맛을 내보세요. 아울러 가공식품보다는 신선한 과일과 채소를 즐기는 습관을 기르는 것도 좋은 방법이랍니다.",
  glucose_category_type_exercise_title: "규칙적인 운동",
  glucose_category_type_exercise_body:
    "규칙적인 운동은 몸과 마음의 건강을 위해 필수적입니다. 운동은 직접적으로 혈당을 떨어뜨리며 장기적으로 당뇨병 합병증을 예방하고 스트레스를 해소에 큰 도움이 됩니다. 일상 속에 꾸준한 운동 습관을 통해 더 건강하고 활력 넘치는 삶을 즐겨보세요!",
  glucose_category_type_water_title: "물 섭취",
  glucose_category_type_water_body:
    "물은 몸의 60%를 차지하며, 독소 제거, 체온 조절, 관절 보호, 소화 지원 및 영양 운반에 필수입니다. 건강 유지를 위해 하루 2–3리터의 물 섭취가 권장됩니다.",
  glucose_result_button_back: "홈",
  glucose_result_button_move_mobile_page: "모바일로 받아보기",
  glucose_result_text_mg_dl: "@mg/dL",
  glucose_result_text_last_update_date: "@일 전",
  glucose_result_screen_text_chart_body_resent_data_normal:
    "혈당 수치가 @1mg/dL로 측정되어 정상에 해당됩니다. 최근 @3회간 혈당 수치를 잘 유지하고 있네요! 현재의 건강한 생활 습관과 균형 잡힌 식사를 계속 유지해 주세요.",
  glucose_result_screen_text_chart_body_resent_data_bad_danger:
    "혈당 수치가 @1mg/dL로 측정되었습니다. 정상 범위보다 약 @2% 높아 당뇨의 가능성이 있습니다. 최근  @3회간 혈당 수치가 높습니다. 당뇨는 관리되지 않을 경우 여러 합병증을 유발할 수 있습니다. 전문의 상담을 받아보시고 건강관리에 좀 더 신경써주세요!",
  glucose_result_screen_text_chart_body_resent_data_good_danger:
    "혈당 수치가 @1mg/dL로 측정되었습니다. 정상 범위보다 약 @2% 높아  당뇨의 가능성이 있습니다. 최근  @3회간 수치에 비해  @4m/dL 개선되었지만, 당뇨는 여러 합병증을 유발할 수 있어 관리가 필요해요. 전문의 상담을 받아보시고 건강관리에 좀 더 신경써주세요!",
  glucose_result_screen_text_chart_body_resent_data_bad_waring:
    "혈당 수치가 @1mg/dL로 측정되었습니다. 정상 범위보다 약 @2% 높아 당뇨 초기의 가능성이 있습니다. 최근  @3회간 혈당 수치가 조금 높습니다. 당뇨는 관리되지 않을 경우 여러 합병증을 유발할 수 있습니다. 좀 더 노력해주세요!",
  glucose_result_screen_text_chart_body_resent_data_good_waring:
    "혈당 수치가 @1mg/dL로 측정되었습니다. 정상 범위보다 약 @2% 높아 당뇨 초기의 가능성이 있습니다. 최근  @3회간 수치에 비해  @4m/dL 개선되었지만, 당뇨는 여러 합병증을 유발할 수 있어 지속적인 관리가 필요해요. 좀 더 노력해주세요!",
  glucose_result_screen_text_chart_body_resent_no_data_normal:
    "혈당 수치가 @1m/dL로 ‘정상’ 범위에 해당합니다. 현재의 건강한 생활 습관과 균형 잡힌 식사를 계속 유지해 주세요.  주기적인 건강 검진과 함께 자신의 몸 상태를 주의 깊게 관찰하며, 최적의 상태로 유지하세요.",
  glucose_result_screen_text_chart_body_resent_no_data_waring:
    "혈당 수치가 @1mg/dL로 측정되었습니다. 정상 범위보다 약 @2% 높아 당뇨 초기의 가능성이 있습니다. 당뇨는 여러 합병증을 유발할 수 있어 지속적인 관리가 필요해요. 좀 더 노력해주세요!",
  glucose_result_screen_text_chart_body_resent_no_data_danger:
    "혈당 수치가 @1mg/dL로 측정되었습니다. 정상 범위보다 약 @2% 높아 당뇨의 가능성이 있습니다. 당뇨는 여러 합병증을 유발할 수 있어 관리가 필요해요. 전문의 상담을 받아보시고 건강관리에 좀 더 신경써주세요!",
  glucose_device_search_button_connect_device: "해당 기기 연결",
  glucose_device_search_text_title: "연결할 기기",
  glucose_device_search_text_subtitle: "연결할 수 있는 기기를 검색합니다.",
  glucose_device_search_text_registered_device_title: "등록된 기기",
  glucose_device_search_text_new_search_device_title: "새로 검색된 기기",
  glucose_device_search_button_search: "검색",
};
