import styled from "styled-components";
import { pxToVh } from "../../utils/calc/pxToVh";

export const FoodGuideLayout = styled.div<{ $isDetail?: boolean }>`
  position: relative;
  margin-top: ${pxToVh(15)};
  width: 100%;
  height: ${({ $isDetail }) => ($isDetail ? pxToVh(480) : pxToVh(359))};
  border: ${({ theme }) => `1px solid ${theme.color.gray200}`};
  border-radius: ${pxToVh(16)};
  padding: ${pxToVh(23)} ${pxToVh(22)};
  transition: 0.5s cubic-bezier(0.04, 0.67, 0.77, 0.94);
`;

export const Header = styled.div`
  width: 100%;
  height: ${pxToVh(56)};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const GuideTitle = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const SvgGuide = styled.div`
  display: flex;
  gap: 0 ${pxToVh(16)};
  svg {
    width: ${pxToVh(55)};
    height: ${pxToVh(55)};
  }
`;

export const Line = styled.div`
  height: ${pxToVh(1)};
  width: 100%;
  background-color: ${({ theme }) => theme.color.gray200};
  margin-top: ${pxToVh(21)};
  margin-bottom: ${pxToVh(16)};
`;

export const DetailTitleLayout = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ThreeCircleLayout = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${pxToVh(20)};
`;

export const DetailInfoLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${pxToVh(16)};
  gap: ${pxToVh(9)};
`;

export const ArrowLayout = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0px;
  svg {
    transform: translateX(-50%);
    width: ${pxToVh(24)};
    height: ${pxToVh(24)};
  }
`;

export const StateCommentLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 63px;
  span:nth-child(1) {
    width: 60%;
  }
  span {
    letter-spacing: ${pxToVh(-0.5)};
  }
  margin-top: ${pxToVh(36)};
  gap: ${pxToVh(9)};
`;

export const SquareLayout = styled.div`
  margin-top: ${pxToVh(41)};
  display: flex;
  flex-direction: column;
  gap: ${pxToVh(18)} 0;
`;
