import styled from "styled-components";
import { pxToVh } from "../../../utils/calc/pxToVh";

export const SquareBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${pxToVh(17)} 0;
  margin-top: ${pxToVh(90)};
  margin-bottom: ${pxToVh(15)};
  width: ${pxToVh(346)};
`;

export const SquareLayout = styled.div<{ $width?: string }>`
  width: ${({ $width }) => $width || "100%"};
  height: ${pxToVh(138)};
  border: ${({ theme }) => `1px solid ${theme.color.gray200}`};
  border-radius: ${pxToVh(17)};
  padding: ${pxToVh(18)} ${pxToVh(12)} ${pxToVh(22)} ${pxToVh(12)};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const TitleLayout = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const PickState = styled.div<{
  $backgroundColor: string;
  $isBorder?: boolean;
}>`
  width: fit-content;
  padding: 0 ${pxToVh(10)};
  height: ${pxToVh(22)};
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ theme, $isBorder: isBorder }) =>
    isBorder ? `1px solid ${theme.color.gray100}` : "none"};
  border-radius: ${pxToVh(18)};
  color: ${({ theme, $backgroundColor: backgroundColor }) =>
    backgroundColor === theme.color.white
      ? theme.color.black
      : theme.color.white};
  background-color: ${({ $backgroundColor: backgroundColor }) =>
    backgroundColor ? backgroundColor : "transparent"};
`;

export const ProgressLayout = styled.div`
  margin-top: ${pxToVh(34)};
`;

export const BottomLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: ${pxToVh(12)};
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2%;
  }
`;
