import { useLocation } from "react-router-dom";
import {
  TBloodQuery,
  bloodQueryItems,
  defaulBloodQuery,
} from "../types/bloodQuery.type";
import { useState } from "react";
import { TPopup } from "../types/popup.type";

export const useBlood = () => {
  const [isShow, setIsShow] = useState<TPopup>(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let queryData: TBloodQuery = defaulBloodQuery;

  bloodQueryItems.forEach((item) => {
    const value = params.get(item);
    queryData = Object.assign(queryData, { [item]: value });
  });

  const closeClick = () => setIsShow(null);
  const showPopupClick = (type: "graph" | "table" | "vascular") =>
    setIsShow(type);

  return {
    queryData,
    isShow,
    closeClick,
    showPopupClick,
  };
};
