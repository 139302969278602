import LocalizedStrings from "react-localization";
import localization from "../localization";

export const useLocalization = () => {
  const lang = navigator.language.includes("ko")
    ? "ko"
    : navigator.language.includes("ja")
    ? "jp"
    : "en";
  const translations = new LocalizedStrings(localization);
  translations.setLanguage(lang);
  return translations;
};
