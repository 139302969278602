import React from "react";
import Title from "../texts/title/Title";
import ResultComment from "../texts/resultComment/ResultComment";
import Better from "../better/Better";
import { HealthMentController } from "../../utils/comment/HealthMentStore";
import { useLocation } from "react-router-dom";
import { standardChart } from "../../utils/comment/HealthSignature";
import ShareFooter from "../shareFooter/ShareFooter";
// import BarChart from "../graph/chart/barChart/BarChart";
import { useLocalization } from "../../hooks/useLocalization";
import SquareInbody from "../square/squareInbody/SquareInbody";
import { pxToVh } from "../../utils/calc/pxToVh";
import { THealthType, TMark, TStatus } from "../../types/deepQuery.type";
import Text from "../atoms/text/Text";
import { theme } from "../../styles/theme";
import { SquareLayout } from "../foodGuide/FoodGuideStyles";

interface ILocationState {
  state: {
    type: THealthType;
    value: number;
    status: TStatus;
    mark: TMark;
    normalCount: string;
  };
}

const DeepTemplate = () => {
  const location: ILocationState = useLocation();
  const translation = useLocalization();
  const { HealthMentStore, betterComment } = HealthMentController();
  const { type, value, status, mark, normalCount } = location.state;

  const refindType = HealthMentStore[type][[status, mark].join("")];

  const [mainResult, refinedTitle] = refindType.resultTitle
    .replace(
      "@",
      `${Math.min(
        Math.abs(value - standardChart[type].min),
        Math.abs(value - standardChart[type].max)
      )}`
    )
    .split("#");

  return (
    <>
      <Title
        content={HealthMentStore[type][[status, mark].join("")].title}
        subTitle={translation.healthcare_compare_normal}
      />
      {/* <BarChart
        title={translation.healthcare_compare}
        data={[
          {
            id: translation.barchart_standard,
            msi: standardChart[type].standard,
          },
          { id: translation.barchart_me, msi: value },
        ]}
        maxValue={standardChart[type].max}
        tick={standardChart[type].tick}
      /> */}
      <SquareLayout>
        <Text
          content={translation.healthcare_compare}
          fontSize={theme.size.medium}
          fontWeight={theme.weight.bold}
        />
        <SquareInbody
          title={type}
          result={{
            target: value,
            min: standardChart[type].min,
            max: standardChart[type].max,
          }}
          status={status}
          type="health"
          isDetail
          isCustom={type === "msi" || type === "psi"}
          width={pxToVh(346)}
          squareClick={() => {}}
        />
      </SquareLayout>
      <ResultComment
        title={[mainResult, refinedTitle]}
        sub={HealthMentStore[type][[status, mark].join("")].subTitle}
      />
      <Better
        title={translation.better_title}
        commentTitle={[
          betterComment.categoryComment[type][[status, mark].join("")].title
            .food,
          betterComment.categoryComment[type][[status, mark].join("")].title
            .exercise,
        ]}
        category={[
          betterComment.category.food,
          betterComment.category.exercise,
          // betterComment.category.breath,
        ]}
        comment={[
          betterComment.categoryComment[type][[status, mark].join("")].comment
            .food,
          betterComment.categoryComment[type][[status, mark].join("")].comment
            .exercise,
          // betterComment.categoryComment[type][[status, mark].join("")].breath,
        ]}
      />
      <ShareFooter shareTitle={normalCount} />
    </>
  );
};

export default DeepTemplate;
