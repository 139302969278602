import { useLocalization } from "../../hooks/useLocalization";
import { theme } from "../../styles/theme";
import { TInbody, TStatus } from "../../types/deepQuery.type";

type TPickColor = {
  [index: string]: {
    [index: string]: TPickValue;
  };
};

type TPickValue = { pickBackColor: string; isBorder?: boolean; ment: string };

export const PickData = () => {
  const translation = useLocalization();

  const PickColorData: TPickColor = {
    health: {
      good: {
        pickBackColor: theme.color.white,
        ment: translation.normal,
      },
      normal: {
        pickBackColor: theme.color.gray100,
        ment: translation.warning,
        isBorder: true,
      },
      bad: {
        pickBackColor: theme.color.black,
        ment: translation.management,
        isBorder: true,
      },
    },
    inbody: {
      good: {
        pickBackColor: theme.color.white,
        ment: translation.standard,
      },
      down: {
        pickBackColor: theme.color.black,
        ment: translation.below_standard,
        isBorder: true,
      },
      up: {
        pickBackColor: theme.color.black,
        ment: translation.above_standard,
        isBorder: true,
      },
    },
  };

  return PickColorData;
};
