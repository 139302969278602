import React, { ReactElement } from "react";
import { Container, ImgContainer, TextInImage } from "./ResultImageStyles";
import Text from "../atoms/text/Text";
import { theme } from "../../styles/theme";
import { pxToVh } from "../../utils/calc/pxToVh";

const ResultImage = ({
  title,
  grade,
  value,
  comment,
  fontSize,
  icon,
}: {
  title?: string;
  grade: string;
  value: string;
  comment: string;
  fontSize?: string;
  icon: ReactElement;
}) => {
  return (
    <Container>
      <Text
        content={title || ""}
        fontSize={theme.size.medium}
        fontWeight={theme.weight.bold}
        letterSpacing={theme.letterSize.x}
        customStyle={{ width: pxToVh(346) }}
      />
      <ImgContainer>
        {icon}
        <TextInImage>
          <Text
            content={grade}
            fontSize={theme.size.large}
            fontWeight={theme.weight.bold}
          />
          <Text
            content={value}
            fontSize={theme.size.small}
            fontWeight={theme.weight.bold}
            letterSpacing={theme.letterSize.x}
          />
          <Text
            content={comment}
            fontSize={fontSize || theme.size.small}
            fontWeight={theme.weight.light}
            color={theme.color.gray100}
            letterSpacing={theme.letterSize.x}
            customStyle={{
              width: pxToVh(180),
              marginTop: pxToVh(5),
              textAlign: "center",
            }}
          />
        </TextInImage>
      </ImgContainer>
    </Container>
  );
};

export default ResultImage;
