import * as Svg from "../../components/atoms/icon/index";
import { useLocalization } from "../../hooks/useLocalization";

type TGlucoseGrade = {
  [index: string]: {
    type: string;
    title: string;
    sub: string;
    svg: React.ReactElement;
    comment: {
      [index: string]: {
        titleComment: string;
        subComment: string;
      };
    };
  };
};

export const GlucoseGrade = () => {
  const translocation = useLocalization();
  const glucoseGrade: TGlucoseGrade = {
    good: {
      type: translocation.glucose_grade_good_type, //"정상",
      title: translocation.glucose_grade_good_title, //"축하합니다!#혈당 수치가 정상입니다.",
      sub: translocation.glucose_grade_good_sub, //"이대로만 관리해주세요!",
      svg: <Svg.Norcose />,
      comment: {
        up: {
          titleComment: translocation.glucose_grade_good_comment_up_title, //"현재 생활 습관을 유지해주세요.",
          subComment:
            translocation.glucose_result_screen_text_chart_body_resent_data_normal, // "혈당 수치가 #mg/dL로 측정되어 '정상'에 해당됩니다. 최근 7회간 혈당 수치를 잘 유지하고 있네요! 현재의 건강한 생활 습관과 균형 잡힌 식사를 계속 유지해 주세요.",
        },
        down: {
          titleComment: translocation.glucose_grade_good_comment_up_title, //"현재 생활 습관을 유지해주세요.",
          subComment:
            translocation.glucose_result_screen_text_chart_body_resent_data_normal, // "혈당 수치가 #mg/dL로 측정되어 '정상'에 해당됩니다. 최근 7회간 혈당 수치를 잘 유지하고 있네요! 현재의 건강한 생활 습관과 균형 잡힌 식사를 계속 유지해 주세요.",
        },
        no: {
          titleComment: translocation.glucose_grade_good_comment_up_title, //"현재 생활 습관을 유지해주세요.",
          subComment:
            translocation.glucose_result_screen_text_chart_body_resent_no_data_normal, // "혈당 수치가 #m/dL로 ‘정상’ 범위에 해당합니다. 현재의 건강한 생활 습관과 균형 잡힌 식사를 계속 유지해 주세요.  주기적인 건강 검진과 함께 자신의 몸 상태를 주의 깊게 관찰하며, 최적의 상태로 유지하세요.",
        },
      },
    },
    normal: {
      type: translocation.glucose_result_type_waring_result, //"당뇨 초기",
      title: translocation.glucose_result_type_waring_title, //"조금만 주의하세요,#당뇨 초기로 추정됩니다.",
      sub: translocation.glucose_result_type_waring_subtitle, //"건강한 생활습관으로 되돌리기만 하면 돼요!",
      svg: <Svg.Precose />,
      comment: {
        up: {
          titleComment: translocation.glucose_result_type_waring_chart, //"건강한 생활 습관을 유지해주세요.",
          subComment:
            translocation.glucose_result_screen_text_chart_body_resent_data_bad_danger, //`혈당 수치가 #mg/dL로 측정되었습니다. 정상 범위보다 약 #% 높아 '당뇨 초기'의 가능성이 있습니다. 최근 7회간 혈당 수치가 조금 높습니다. 당뇨는 관리되지 않을 경우 여러 합병증을 유발할 수 있습니다. 좀 더 노력해주세요!`,
        },
        down: {
          titleComment: translocation.glucose_result_type_waring_chart, //"건강한 생활 습관을 유지해주세요.",
          subComment:
            translocation.glucose_result_screen_text_chart_body_resent_data_good_waring, //"혈당 수치가 #mg/dL로 측정되었습니다. 정상 범위보다 약 #% 높아 '당뇨 초기'의 가능성이 있습니다. 최근 7회간 수치에 비해 #m/dL 개선되었지만, 당뇨는 여러 합병증을 유발할 수 있어 지속적인 관리가 필요해요. 좀 더 노력해주세요!",
        },
        no: {
          titleComment: translocation.glucose_result_type_waring_chart, //"건강한 생활 습관을 유지해주세요.",
          subComment:
            translocation.glucose_result_screen_text_chart_body_resent_no_data_waring, // "혈당 수치가 #mg/dL로 측정되었습니다. 정상 범위보다 약 #% 높아 '당뇨 초기'의 가능성이 있습니다. 당뇨는 여러 합병증을 유발할 수 있어 지속적인 관리가 필요해요. 좀 더 노력해주세요!",
        },
      },
    },
    bad: {
      type: translocation.glucose_result_type_danger_result, //"당뇨",
      title: translocation.glucose_result_type_danger_title, // "혈당 수치가 높게 나왔어요.#당뇨로 추정됩니다.",
      sub: translocation.glucose_result_type_danger_subtitle, //"전문가와 상담이 필요합니다.",
      svg: <Svg.Glucose />,
      comment: {
        up: {
          titleComment: translocation.glucose_result_type_danger_chart, // "건강 관리에 좀 더 신경써주세요.",
          subComment:
            translocation.glucose_result_screen_text_chart_body_resent_data_bad_danger, //"혈당 수치가 #mg/dL로 측정되었습니다. 정상 범위보다 약 #% 높아 '당뇨'의 가능성이 있습니다. 최근 7회간 혈당 수치가 높습니다. 당뇨는 관리되지 않을 경우 여러 합병증을 유발할 수 있습니다. 전문의 상담을 받아보시고 건강관리에 좀 더 신경써주세요!",
        },
        down: {
          titleComment: translocation.glucose_result_type_danger_chart, //"건강 관리에 좀 더 신경써주세요.",
          subComment:
            translocation.glucose_result_screen_text_chart_body_resent_data_good_danger, // "혈당 수치가 #mg/dL로 측정되었습니다. 정상 범위보다 약 #% 높아 '당뇨'의 가능성이 있습니다. 최근 7회간 수치에 비해 #m/dL 개선되었지만, 당뇨는 여러 합병증을 유발할 수 있어 관리가 필요해요. 전문의 상담을 받아보시고 건강관리에 좀 더 신경써주세요!",
        },
        no: {
          titleComment: translocation.glucose_result_type_danger_chart, //"건강 관리에 좀 더 신경써주세요.",
          subComment:
            translocation.glucose_result_screen_text_chart_body_resent_no_data_danger, // "혈당 수치가 #mg/dL로 측정되었습니다. 정상 범위보다 약 #% 높아 '당뇨'의 가능성이 있습니다. 당뇨는 여러 합병증을 유발할 수 있어 관리가 필요해요. 전문의 상담을 받아보시고 건강관리에 좀 더 신경써주세요!",
        },
      },
    },
  };
  return glucoseGrade;
};
