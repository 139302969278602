import { pxToVh } from "../utils/calc/pxToVh";

const color = {
  black: "#000",
  white: "#fff",
  gray40: "#a2a3a5",
  gray50: "#a6a6a6",
  gray100: "#828282",
  gray200: "#646464",
  gray300: "#181818",
  gray400: "#373737",
  cyan10: "#66fcf1",
  cyan20: "#46A29F",
  cyan30: "#307D7B",
  cyan40: "#154543",
  danger: "#eb445a",
  danger10: "#bb2424",
  danger20: "#8b1c1c",
  danger30: "#6a1616",
};

const size = {
  large: pxToVh(22),
  lardium: pxToVh(20),
  medium: pxToVh(18),
  small: pxToVh(16),
  xs: pxToVh(14),
  xxs: pxToVh(12),
  xxxs: pxToVh(10),
};

const weight = {
  bold: "700",
  light: "400",
};

const letterSize = {
  x: pxToVh(-0.3),
  xs: "0px",
};

export const theme = {
  color,
  size,
  weight,
  letterSize,
};
