import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { theme } from "../../../styles/theme";
import Text from "../../atoms/text/Text";
import { TextLayout } from "./CircleProgressStyles";
import { pxToVh } from "../../../utils/calc/pxToVh";

const CircleProgress = ({
  width,
  value,
  stateText,
  circleColor,
  pathColor,
  textSize,
}: {
  width: string;
  value: number;
  circleColor?: string;
  pathColor?: string;
  stateText?: string;
  textSize?: string;
}) => {
  return (
    <div style={{ width, marginTop: pxToVh(2), marginRight: pxToVh(2) }}>
      <CircularProgressbarWithChildren
        value={value}
        text={`${value}%`}
        styles={{
          text: {
            fill: theme.color.white,
            fontSize: textSize || theme.size.small,
            fontWeight: theme.weight.bold,
            alignmentBaseline: "central",
          },
          path: { stroke: circleColor || theme.color.cyan20 },
          trail: { stroke: pathColor || theme.color.gray200 },
        }}
      >
        {stateText && (
          <TextLayout>
            <Text
              content="표준 수치보다"
              fontSize={theme.size.xxxs}
              fontWeight={theme.weight.light}
              color={theme.color.gray100}
            />
            <Text
              content={stateText}
              fontSize={theme.size.xxxs}
              fontWeight={theme.weight.bold}
              color={theme.color.gray100}
            />
          </TextLayout>
        )}
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default CircleProgress;
