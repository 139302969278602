import React from "react";
import Text from "../../atoms/text/Text";
import { theme } from "../../../styles/theme";
import { SubStyles } from "./TitleStyles";
import { TitleStyles } from "./TitleStyles";

const Title = ({
  content,
  firstSize,
  subTitle,
}: {
  content: string;
  firstSize?: string;
  subTitle?: string;
}) => {
  const [titleFirst, titleSecond] = content.split("#");
  const [subFirst, subSecond] = subTitle?.split("#") || ["", ""];
  return (
    <>
      <TitleStyles>
        <Text
          content={titleFirst}
          fontSize={firstSize || theme.size.large}
          fontWeight={theme.weight.bold}
          color={theme.color.white}
          letterSpacing={theme.letterSize.xs}
          customStyle={{ lineHeight: "150%", letterSpacing: "0px" }}
        />
        {titleSecond && (
          <Text
            content={titleSecond}
            fontSize={theme.size.large}
            fontWeight={theme.weight.bold}
            color={theme.color.white}
            letterSpacing={theme.letterSize.xs}
            customStyle={{ lineHeight: "150%" }}
          />
        )}
      </TitleStyles>
      {subTitle && (
        <>
          <SubStyles>
            <Text
              content={subFirst}
              fontSize={theme.size.small}
              fontWeight={theme.weight.light}
              color={theme.color.gray100}
              customStyle={{ lineHeight: "150%" }}
            />
            {subSecond && <br />}
            <Text
              content={subSecond}
              fontSize={theme.size.small}
              fontWeight={theme.weight.light}
              color={theme.color.gray100}
              customStyle={{ lineHeight: "150%" }}
            />
          </SubStyles>
        </>
      )}
    </>
  );
};

export default React.memo(Title);
