import { styled } from "styled-components";
import { pxToVh } from "../../../utils/calc/pxToVh";

export const TitleStyles = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pxToVh(346)};
  flex-wrap: wrap;
  margin-top: ${pxToVh(25)};
`;

export const SubStyles = styled.div`
  width: ${pxToVh(346)};
  margin-top: ${pxToVh(10)};
`;
