import { useLocation, useNavigate } from "react-router-dom";
import {
  IDeepMediQuery,
  deepMediQueryItems,
  defaulDeepMediQuery,
} from "../types/deepQuery.type";

export const useDeepMedi = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);

  let queryData: IDeepMediQuery = defaulDeepMediQuery;

  deepMediQueryItems.forEach((item) => {
    const value = params.get(item);
    queryData = Object.assign(queryData, {
      [item]: value,
    });
  });

  const balancerUrl = (
    direct: string,
    value: number,
    status: string,
    mark: string,
    normalCount: string
  ) => {
    navigate(`/health/result/${direct}`, {
      state: {
        type: direct,
        value: value,
        status: status,
        mark: mark,
        normalCount: normalCount,
      },
    });
  };

  return { queryData, balancerUrl };
};
