import { useLocalization } from "../../hooks/useLocalization";
import { THealthType } from "../../types/deepQuery.type";

type THealthMentStore = {
  [index: string]: {
    [index: string]: {
      title: string;
      resultTitle: string;
      subTitle: string;
    };
  };
};

type TBetter = {
  category: {
    food: string;
    exercise: string;
    breath: string;
  };
  categoryComment: Record<
    THealthType,
    {
      [index: string]: {
        title: { food: string; exercise: string };
        comment: { food: string; exercise: string };
        breath: string;
        // food: string;
        // exercise: string;
        // breath: string;
        // keyword: string;
      };
    }
  >;
};

export const HealthMentController = () => {
  const translation = useLocalization();
  const HealthMentStore: THealthMentStore = {
    hr: {
      good: {
        title: translation.healthcare_hr_good_title,
        resultTitle: translation.healthcare_hr_good_resultTitle,
        subTitle: translation.healthcare_hr_good_subTitle,
      },
      normalup: {
        title: translation.healthcare_hr_normalup_title,
        resultTitle: translation.healthcare_hr_normalup_resultTitle,
        subTitle: translation.healthcare_hr_normalup_subTitle,
      },
      normaldown: {
        title: translation.healthcare_hr_normaldown_title,
        resultTitle: translation.healthcare_hr_normaldown_resultTitle,
        subTitle: translation.healthcare_hr_normaldown_subTitle,
      },
      badup: {
        title: translation.healthcare_hr_badup_title,
        resultTitle: translation.healthcare_hr_badup_resultTitle,
        subTitle: translation.healthcare_hr_badup_subTitle,
      },
      baddown: {
        title: translation.healthcare_hr_baddown_title,
        resultTitle: translation.healthcare_hr_baddown_resultTitle,
        subTitle: translation.healthcare_hr_baddown_subTitle,
      },
    },

    rep: {
      good: {
        title: translation.healthcare_rep_good_title,
        resultTitle: translation.healthcare_rep_good_resultTitle,
        subTitle: translation.healthcare_rep_good_subTitle,
      },
      normal: {
        title: translation.healthcare_rep_normal_title,
        resultTitle: translation.healthcare_rep_normal_resultTitle,
        subTitle: translation.healthcare_rep_normal_subTitle,
      },
      bad: {
        title: translation.healthcare_rep_bad_title,
        resultTitle: translation.healthcare_rep_bad_resultTitle,
        subTitle: translation.healthcare_rep_bad_subTitle,
      },
    },

    msi: {
      good: {
        title: translation.healthcare_msi_good_title,
        resultTitle: translation.healthcare_msi_good_resultTitle,
        subTitle: translation.healthcare_msi_good_subTitle,
      },
      normal: {
        title: translation.healthcare_msi_normalup_title,
        resultTitle: translation.healthcare_msi_normalup_resultTitle,
        subTitle: translation.healthcare_msi_bad_subTitle,
      },
      bad: {
        title: translation.healthcare_msi_bad_title,
        resultTitle: translation.healthcare_msi_bad_resultTitle,
        subTitle: translation.healthcare_msi_bad_subTitle,
      },
    },

    psi: {
      good: {
        title: translation.healthcare_psi_good_title,
        resultTitle: translation.healthcare_psi_good_resultTitle,
        subTitle: translation.healthcare_psi_good_subTitle,
      },
      normal: {
        title: translation.healthcare_psi_normalup_title,
        resultTitle: translation.healthcare_psi_bad_resultTitle,
        subTitle: translation.healthcare_psi_bad_subTitle,
      },
      bad: {
        title: translation.healthcare_psi_bad_title,
        resultTitle: translation.healthcare_psi_bad_resultTitle,
        subTitle: translation.healthcare_psi_bad_subTitle,
      },
    },
  };

  const betterComment: TBetter = {
    category: {
      food: translation.better_category_food,
      exercise: translation.better_category_exercise,
      breath: translation.better_category_breath,
    },
    categoryComment: {
      hr: {
        good: {
          title: {
            food: translation.better_title_food_hr_good,
            exercise: translation.better_title_exercise_hr_good,
          },
          comment: {
            food: translation.better_comment_food_hr_good,
            exercise: translation.better_comment_exercise_hr_good,
          },
          breath: translation.better_comment_breath,
        },
        normalup: {
          title: {
            food: translation.better_title_food_hr_up,
            exercise: translation.better_title_exercise_hr_up,
          },
          comment: {
            food: translation.better_comment_food_hr_up,
            exercise: translation.better_comment_exercise_hr_up,
          },
          breath: translation.better_comment_breath,
        },
        normaldown: {
          title: {
            food: translation.better_title_food_hr_down,
            exercise: translation.better_title_exercise_hr_down,
          },
          comment: {
            food: translation.better_comment_food_hr_down,
            exercise: translation.better_comment_exercise_hr_down,
          },
          breath: translation.better_comment_breath,
        },
        badup: {
          title: {
            food: translation.better_title_food_hr_up,
            exercise: translation.better_title_exercise_hr_up,
          },
          comment: {
            food: translation.better_comment_food_hr_up,
            exercise: translation.better_comment_exercise_hr_up,
          },
          breath: translation.better_comment_breath,
        },
        baddown: {
          title: {
            food: translation.better_title_food_hr_down,
            exercise: translation.better_title_exercise_hr_down,
          },
          comment: {
            food: translation.better_comment_food_hr_down,
            exercise: translation.better_comment_exercise_hr_down,
          },
          breath: translation.better_comment_breath,
        },
      },
      rep: {
        good: {
          title: {
            food: translation.better_title_food_rep_good,
            exercise: translation.better_title_exercise_rep_good,
          },
          comment: {
            food: translation.better_comment_food_rep_good,
            exercise: translation.better_comment_exercise_rep_good,
          },
          breath: translation.better_comment_breath,
        },
        normal: {
          title: {
            food: translation.better_title_food_rep_down,
            exercise: translation.better_title_exercise_rep_down,
          },
          comment: {
            food: translation.better_comment_food_rep_down,
            exercise: translation.better_comment_exercise_rep_down,
          },
          breath: translation.better_comment_breath,
        },
        bad: {
          title: {
            food: translation.better_title_food_rep_up,
            exercise: translation.better_title_exercise_rep_up,
          },
          comment: {
            food: translation.better_comment_food_rep_up,
            exercise: translation.better_comment_exercise_rep_up,
          },
          breath: translation.better_comment_breath,
        },
      },
      msi: {
        good: {
          title: {
            food: translation.better_title_food_msi_down,
            exercise: translation.better_title_exercise_msi_down,
          },
          comment: {
            food: translation.better_comment_food_msi_good,
            exercise: translation.better_comment_exercise_msi_good,
          },
          breath: translation.better_comment_breath,
        },
        normal: {
          title: {
            food: translation.better_title_food_msi_up,
            exercise: translation.better_title_exercise_msi_up,
          },
          comment: {
            food: translation.better_comment_food_msi_up,
            exercise: translation.better_comment_exercise_msi_up,
          },
          breath: translation.better_comment_breath,
        },
        bad: {
          title: {
            food: translation.better_title_food_msi_up,
            exercise: translation.better_title_exercise_msi_up,
          },
          comment: {
            food: translation.better_comment_food_msi_up,
            exercise: translation.better_comment_exercise_msi_up,
          },
          breath: translation.better_comment_breath,
        },
      },
      psi: {
        good: {
          title: {
            food: translation.better_title_food_psi_down,
            exercise: translation.better_title_exercise_psi_down,
          },
          comment: {
            food: translation.better_comment_food_psi_good,
            exercise: translation.better_comment_exercise_psi_good,
          },
          breath: translation.better_comment_breath,
        },
        normal: {
          title: {
            food: translation.better_title_food_psi_up,
            exercise: translation.better_title_exercise_psi_up,
          },
          comment: {
            food: translation.better_comment_food_psi_up,
            exercise: translation.better_comment_exercise_psi_up,
          },
          breath: translation.better_comment_breath,
        },
        bad: {
          title: {
            food: translation.better_title_food_psi_up,
            exercise: translation.better_title_exercise_psi_up,
          },
          comment: {
            food: translation.better_comment_food_psi_up,
            exercise: translation.better_comment_exercise_psi_up,
          },
          breath: translation.better_comment_breath,
        },
      },
    },
  };

  const gluoseComment = {
    category: {
      food: translation.better_glucose_food,
      exercise: translation.better_glucose_exercise,
      water: translation.better_glucose_water,
    },
    categoryComment: {
      food: translation.better_glucose_comment_food,
      exercise: translation.better_glucose_comment_exercise,
      water: translation.better_glucose_comment_water,
    },
  };

  const bloodBetterComment = {
    type: [
      translation.better_blood_type_food,
      translation.better_blood_type_exercise,
      translation.better_blood_type_medical,
      translation.better_blood_type_rest,
    ],
    comment: [
      translation.better_blood_comment_food,
      translation.better_blood_comment_exercise,
      translation.better_blood_comment_medical,
      translation.better_blood_comment_rest,
    ],
  };
  return { HealthMentStore, betterComment, bloodBetterComment, gluoseComment };
};
