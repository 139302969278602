export default {
  hr: "Heart rate",
  rep: "Respiratory rate",
  psi: "Fatigue",
  msi: "Stress",
  gender: "Gender",
  male: "M",
  female: "F",
  document_title_result_hr: "Health Results",
  document_title_result_inbody: "Inbody Results",
  standard_range: "Normal range",
  count: "times",
  per_minute: "times / min",
  healthcare_compare_normal: "Compare to normal average values.",
  healthcare_compare: "Summary of Measurement Results",
  healthcare_hr_good_title:
    "Your heart rate is in the normal range.#Keep taking good care of yourself!",
  healthcare_hr_normalup_title: "Heart rate is high.#You need to be careful.",
  healthcare_hr_normaldown_title:
    "Your heart rate is low.#You need to be careful.",
  healthcare_hr_badup_title:
    "Your heart rate is very high.#You need to take care of it.",
  healthcare_hr_baddown_title:
    "Your heart rate is very low.#You need to take care of it.",

  healthcare_hr_good_resultTitle:
    "Heart rate is the normal range.#Please keep it this way!",
  healthcare_hr_normalup_resultTitle:
    "Heart rate is#@ higher than normal average values.",
  healthcare_hr_normaldown_resultTitle:
    "Heart rate is#@ lower than normal average values.",
  healthcare_hr_badup_resultTitle:
    "Heart rate is#@ higher than normal average values.",
  healthcare_hr_baddown_resultTitle:
    "Heart rate is#@ lower than normal average values.",

  // healthcare_hr_good_subTitle:
  //   "Your heart rate is within the normal range. This generally indicates healthy heart activity. However, maintaining healthy lifestyle habits and not forgetting regular health check-ups are important. Avoiding stress, consistent exercise, and a balanced diet are crucial.",
  // healthcare_hr_normalup_subTitle:
  //   "Your heart rate is measured higher than average. A rapid heart rate can be caused by stress, caffeine intake, anxiety, dehydration, and more. Consistent rest and stress management are important, and if high heart rate or other symptoms persist, consult a professional.",
  // healthcare_hr_normaldown_subTitle:
  //   "Your heart rate is measured lower than average. A low heart rate can occur in trained athletes or individuals with certain conditions. If symptoms or discomfort accompany, consulting a professional is necessary.",
  // healthcare_hr_badup_subTitle:
  //   "Your heart rate is measured excessively high. A very high heart rate can increase the burden on the heart and may suggest heart dysfunction or other health issues. Take immediate rest and regulate your breathing.",
  // healthcare_hr_baddown_subTitle:
  //   "Your heart rate is measured very low. Excessively low heart rate can indicate problems with the heart's normal function. If accompanied by symptoms like dizziness, fatigue, or chest pain, visit a medical institution immediately.",

  healthcare_hr_good_subTitle:
    "My resting heart rate is within the standard range and is in good health. It is important to maintain regular exercise, healthy eating habits, and manage stress effectively. Please continue to try to stay healthy.",
  healthcare_hr_normalup_subTitle:
    "If your heart rate is consistently fast, several factors can cause it to increase, including heart disease, hyperthyroidism, heart failure, and irregular heartbeat. It is important to consult a healthcare professional because a consistently fast heart rate can lead to problems such as increased blood pressure, damage to blood vessels, and increased strain on the heart. It is important to normalize your heart rate through lifestyle, eating habits, and stress management.",
  healthcare_hr_normaldown_subTitle:
    "If your heart rate is consistently slow, heart disease, low blood pressure, myocarditis, decreased thyroid function, ventricular braking, heart failure, myocardial infarction, etc. may be the cause of the decreased heart rate. If your heart rate is too slow, blood circulation may not be sufficient, which may result in a lack of oxygen to the brain or other organs, which may increase the risk of cardiovascular diseases such as heart failure. Therefore, if your heart rate continues to be slow, it is important to consult a medical professional to determine the cause and receive appropriate treatment. However, it may be low among people who exercise a lot, such as athletes. This is because the size of the heart increases due to exercise, so the amount of blood it can supply at one time is sufficient.",
  healthcare_hr_badup_subTitle:
    "심박수가 지If your heart rate is consistently fast, several factors can cause it to increase, including heart disease, hyperthyroidism, heart failure, and irregular heartbeat. It is important to consult a healthcare professional because a consistently fast heart rate can lead to problems such as increased blood pressure, damage to blood vessels, and increased strain on the heart. It is important to normalize your heart rate through lifestyle, eating habits, and stress management.속적으로 빠르다면 심장 질환, 갑상선 기능 항진증, 심부전, 불규칙한 심장 박동 등 여러 가지 요인이 심박수를 증가시킬 수 있어요. 지속적으로 빠른 심박수는 혈압 상승, 혈관 손상, 심장 부담 증가 등의 문제를 유발할 수 있으므로 의료 전문가와 상담하는 것이 중요합니다. 생활 방식, 식습관, 스트레스 관리 등을 통해 심박수를 정상화시키는 것이 중요해요.",
  healthcare_hr_baddown_subTitle:
    "If your heart rate is consistently slow, heart disease, low blood pressure, myocarditis, decreased thyroid function, ventricular braking, heart failure, myocardial infarction, etc. may be the cause of the decreased heart rate. If your heart rate is too slow, blood circulation may not be sufficient, which may result in a lack of oxygen to the brain or other organs, which may increase the risk of cardiovascular diseases such as heart failure. Therefore, if your heart rate continues to be slow, it is important to consult a medical professional to determine the cause and receive appropriate treatment. However, it may be low among people who exercise a lot, such as athletes. This is because the size of the heart increases due to exercise, so the amount of blood it can supply at one time is sufficient.",

  healthcare_rep_good_title:
    "Your respiratory rate is within the normal range.#Continue to maintain good health!",
  healthcare_rep_normal_title:
    "Your respiratory rate is below normal.#Caution is advised.",
  healthcare_rep_bad_title:
    "Your respiratory rate is elevated.#Caution is advised.",

  healthcare_rep_good_resultTitle:
    "Respiratory rate is in the normal range.#Please keep it this way!",
  healthcare_rep_normal_resultTitle:
    "Respiratory rate is#@ lower than normal average values.",
  healthcare_rep_bad_resultTitle:
    "Respiratory rate is#@ higher than normal average values.",

  // healthcare_rep_good_subTitle:
  //   "Your breathing rate is within the normal range. This can indicate a healthy state, but individual conditions or other symptoms may vary. Continue to monitor your body and seek professional help if abnormalities are found.",
  // healthcare_rep_normalup_subTitle:
  //   "Your breathing rate is measured higher than average. Rapid breathing can be caused by stress, physical issues, and more. If rapid breathing persists or is accompanied by other symptoms, consider consulting a professional.",
  // healthcare_rep_normaldown_subTitle:
  //   "Your breathing rate is measured lower than average. A decrease in breathing rate can stem from various causes and sometimes signal health issues. If you experience any other symptoms or discomfort, don’t hesitate to consult with a professional.",
  // healthcare_rep_badup_subTitle:
  //   "Your breathing rate is measured higher than average. Rapid breathing can be caused by stress, physical issues, and more. If rapid breathing persists or is accompanied by other symptoms, consider consulting a professional.",

  healthcare_rep_good_subTitle:
    "Your respiratory rate is within the standard range. This can indicate a healthy state, but it may vary depending on individual conditions or other symptoms. Continue to monitor your own physical condition, and if any abnormalities are found, it's advisable to seek the help of a professional.",
  healthcare_rep_normal_subTitle:
    "Your respiratory rate has been measured lower than average. A decrease in respiratory rate can be due to various causes and sometimes may signal health issues. If you experience any other symptoms or discomfort, do not hesitate to consult with a professional.",
  healthcare_rep_bad_subTitle:
    "Your respiratory rate has been measured higher than average. Rapid breathing can be caused by various factors, including stress and physical issues. If fast breathing persists or is accompanied by other symptoms, consulting with a professional should be considered.",

  healthcare_psi_good_title:
    "The fatigue level is within the normal range.#Keep taking good care of yourself!",
  healthcare_psi_normalup_title:
    "Fatigue is a little high.#You need to be careful.",
  healthcare_psi_normaldown_title:
    "It's a little less fatigued.#You need a balanced diet.",
  healthcare_psi_bad_title:
    "Widow state!#Fatigue Recovery Management is required.",

  healthcare_psi_good_resultTitle:
    "Fatigue is within the normal range.#Stay stable.",
  healthcare_psi_normalup_resultTitle:
    "The fatigue is @ higher than#the normal average values.",
  healthcare_psi_normaldown_resultTitle:
    "The fatigue is lower than#the normal average values @.",
  healthcare_psi_bad_resultTitle:
    "The fatigue is @ higher than#the normal average values.",

  // healthcare_psi_good_subTitle:
  //   "Fatigue is within the normal range. I think you already know the importance of managing stress regularly and having a positive lifestyle in your daily life. Keep this steady mind and enjoy a healthy routine.",
  // healthcare_psi_normalup_subTitle:
  //   "Currently, fatigue has been measured high. It's a good time to pay special attention to fatigue recovery management. Take a break for a healthy routine, and invest your time in self~care.",
  // healthcare_psi_normaldown_subTitle:
  //   "Fatigue is within the normal range. I think you already know the importance of regular stress management and positive lifestyle in your daily life. Keep this unwavering mind and enjoy a healthy daily life.",
  // healthcare_psi_bad_subTitle:
  //   "Currently, fatigue has been measured high. It's a good time to pay special attention to fatigue recovery management. Take a break for a healthy routine, and invest your time in self~care.",

  healthcare_psi_good_subTitle:
    "The current fatigue index is measured to be low. This means that you are relatively energetic and well-recharged. Maintain regular exercise and diet, and get plenty of rest.",
  healthcare_psi_normal_subTitle:
    "Fatigue is within the standard range. I think you already know the importance of regular stress management and positive lifestyle habits in your daily life. It is important to take care of your body and mind by getting good rest and proper rest. Manage your energy well by maintaining a balance of activity and rest. Please continue to try to maintain a healthy and active daily life.",
  healthcare_psi_bad_subTitle:
    "Fatigue was measured to be high. If fatigue persists, various physical and mental problems can occur. Fatigue generally appears when you are physically or mentally exhausted, and it often continues even after going to bed and not getting enough rest. If fatigue persists, problems such as increased risk of depression and anxiety disorders, decreased immunity, muscle pain, digestive problems, and decreased concentration and memory may occur. Therefore, if fatigue persists, it is important to manage it through appropriate rest, stress management, healthy eating habits, and sufficient exercise. If it persists, consult a medical professional to review the possibility of underlying health problems or underlying diseases.",

  healthcare_msi_good_title:
    "The stress level is normal.#Keep taking good care of yourself!",
  healthcare_msi_normalup_title:
    "It's a little stressful.#You need to be careful.",
  healthcare_msi_normaldown_title:
    "The stress is a little low.#You need a balanced management.",
  healthcare_msi_bad_title: "Widow state!#You need stress management.",

  healthcare_msi_good_resultTitle:
    "The stress is within the normal range.#Stay stable.",
  healthcare_msi_normalup_resultTitle:
    "The stress is @ higher than#the normal average values.",
  healthcare_msi_normaldown_resultTitle:
    "The stress is @ lower than#the normal average values.",
  healthcare_msi_bad_resultTitle:
    "The stress is @ higher than#the normal average values.",

  // healthcare_msi_good_subTitle:
  //   "The stress level is in the normal range. I think you already know the importance of regular stress management and positive lifestyle in your daily life. Keep this unwavering mind and enjoy a healthy daily life.",
  // healthcare_msi_normalup_subTitle:
  //   "The current stress has been measured high. Stress can increase due to various causes such as daily activities, stress, and insufficient sleep. It helps the body restore by increasing proper rest, balanced diet, and water intake.",
  // healthcare_msi_normaldown_subTitle:
  //   "The current stress index is low. This means that they are relatively energetic and rechargeable. However, consistently low fatigue may mean excessive activity, so don't forget the importance of proper rest and a balanced lifestyle.",
  // healthcare_msi_bad_subTitle:
  //   "The current stress index has been measured high. It's a good time to pay special attention to stress management. Take a break for a healthy routine, and invest your time in self~care.",

  healthcare_msi_good_subTitle:
    "Your current stress level is measured to be low. This indicates that you are relatively energetic and well-recharged. Continue to find satisfaction in everyday activities and allow your body and mind to rest. Enjoy hobbies or spending time with friends. Learn your own stress management techniques and maintain positive thinking.",
  healthcare_msi_normal_subTitle:
    "Your stress level is within the standard range, but continuous stress management is needed! Stress can arise from various causes, including psychological, environmental, and social factors. When stressed, stress hormones are released, which can increase heart rate, blood pressure, and respiration, and may lead to symptoms such as fatigue, headaches, and sleep disturbances. Therefore, it's important to find your own stress relief methods through meditation, stress, and exercise. Exposure to sunlight can activate serotonin, helping to alleviate stress. How about taking a moment to walk in the sunlight?",
  healthcare_msi_bad_subTitle:
    "Stress has been measured high. Various factors such as daily activities, stress, and lack of sleep can increase stress. If stress persists, it can lead to physical and mental illnesses. Therefore, managing and reducing stress is very important for maintaining physical and mental health. Above all, having regular lifestyle habits is the start of stress management. Practical management methods include adopting the habit of eating slowly and getting sufficient sleep for about 6-8 hours. Regular exercise, stress management techniques (e.g., meditation, muscle relaxation), and investing time in hobbies and interests are also good ways to manage stress. If stress continues and is feared to have a negative impact on health, it is important to consult with a healthcare professional.",

  barchart_me: "User",
  barchart_standard: "Normal Average Values",
  inbody_header_title: "InBody test results.",
  inbody_header_subtitle: "Tap each item.",
  check_url: "Please check the URL",
  normal: "Normal",
  warning: "Attention",
  management: "Management required",
  standard: "Within normal",
  below_standard: "Below normal",
  above_standard: "Above normal",
  healthcare_normal_0:
    "Overall, it is a step that#requires active health care.",
  healthcare_normal_1: "Health interest#is a necessary step.",
  healthcare_normal_2hr:
    "Heart rate is also a step that#requires special attention.",
  healthcare_normal_2rep:
    "Respiratory is also a step that#requires special attention.",
  healthcare_normal_2psi:
    "Fatigue is also a step that#requires special attention.",
  healthcare_normal_2msi:
    "Stress is also a step that#requires special attention.",
  healthcare_normal_3hr: "Attention is required#for heart rate entries.",
  healthcare_normal_3rep: "Attention is required#for respiratory entries.",
  healthcare_normal_3psi: "Attention is required#for fatigue entries.",
  healthcare_normal_3msi: "Attention is required#for stress entries.",
  healthcare_normal_4: "Everyone is normal!#Keep it this way!",
  footer_valid: "Please try it on your mobile.",
  footer_chrome_safari: "Please use Chrome and Safari.",
  footer_copy: "Copy",
  footer_share: "Share",
  footer_clipboard: "Copy to clipboard.",

  blood_vessel_result_a_type: "Normal (Type a)",
  blood_vessel_result_b_type: "Normal (Type b)",
  blood_vessel_result_c_type: "Caution (Type c)",
  blood_vessel_result_d_type: "Caution (Type d)",
  blood_vessel_result_e_type: "Diagnostic required (Type e)",
  blood_vessel_result_f_type: "Diagnostic required (Type f)",
  blood_vessel_result_g_type: "Diagnostic required (Type g)",
  blood_vessel_result_health_title: "My blood vessels are in good condition.",
  blood_vessel_result_waring_title:
    "I need to take care of my vascular health.",
  blood_vessel_result_danger_title:
    "I need a consultation with a professional.",
  blood_vessel_result_summary_health_text:
    "The blood vessel type is 'normal', with a waveform in the 2-30s, which is good. Keep yourself in your current state with the right lifestyle and regular exercise.",
  blood_vessel_result_summary_waring_text:
    "The blood vessel type is 'caution', and it has a waveform in the 4–50s, so you need to be careful. Consider constant management and lifestyle improvements.",
  blood_vessel_result_summary_danger_text:
    "The blood vessel type is 'diagnosis required' and has a waveform of 6 to 70s, so it needs to be managed. It is urgent to consult with experts and improve lifestyle.",
  blood_vessel_result_button_back: "Home",
  blood_vessel_result_button_move_mobile_page: "Receive on Mobile",
  blood_vessel_result_measure_date_text: "metrics",
  blood_vessel_result_user_age_text: "@age",
  blood_vessel_summary_result_title_text: "Summary of results",
  blood_vessel_summary_result_blood_vessel_health_type_title_text:
    "The vascular health type is 3 types (normal, careful, and managed), and the lower the vascular health index, the better.",
  blood_vessel_summary_result_blood_vessel_health_type_text:
    "Vascular health type",
  blood_vessel_summary_result_blood_vessel_health_index_text:
    "Vascular Health Index",
  blood_vessel_summary_result_blood_vessel_health_type_title_dialog_button:
    "What is the type of vascular health?",
  blood_vessel_summary_result_blood_vessel_health_index_chart_title_dialog_button:
    "How do I see the waveform of the vascular health index?",
  blood_vessel_result_detail_title_text: "Detailed measurement results",
  blood_vessel_result_detail_blood_vessel_age_title_text:
    "The vascular health age is the user's# vascular age compared to the same age group.",
  blood_vessel_tip_food_title_text: "Food",
  blood_vessel_tip_exercise_title_text: "Exercise",
  blood_vessel_tip_check_up_title_text: "a medical examination",
  blood_vessel_tip_rest_title_text: "Rest",
  blood_vessel_tip_food_body:
    "Reduce saturated fat, trans fat, and sodium intake, and consume a variety of plant-based foods (fruits, vegetables, whole grains, nuts). Excessive alcohol consumption can also elevate blood pressure, so drink alcohol in moderation.",
  blood_vessel_tip_exercise_body:
    "For vascular health, engage in moderate to low–intensity aerobic exercise (walking, cycling) 3–5 times a week, each session lasting 30 minutes. Additionally, increase flexibility and improve blood circulation through stretching or yoga.",
  blood_vessel_tip_check_up_body:
    "Regular health check-ups help monitor the condition of your blood vessels. Manage cholesterol, blood pressure, and blood sugar levels, and consult with a specialist if needed to find ways to improve vascular health.",
  blood_vessel_tip_rest_body:
    "Adequate rest is essential for vascular health. Take regular breaks to reduce stress, prioritize deep sleep, and use relaxation techniques such as meditation or deep breathing to maintain vascular health.",
  blood_vessel_measuring_title: "We are measuring#your vascular condition.",
  blood_vessel_measuring_count: "@ sec left",
  blood_vessel_measuring_subtitle:
    "Maintain a stable state#during the measurement.",
  blood_vessel_measuring_my_ppg: "My PPG",
  blood_vessel_measuring_chart_title:
    "This chart shows how blood flows through your vessels with each heartbeat.",
  blood_vessel_measuring_back: "Back",
  blood_vessel_count: "seconds",
  m_d_hh_mm: "Month@Date",
  ppg_avg_title: "Average Pulse",
  ppg_state_title: "Pulse State",
  user_ppg_result_title: "User's Figure",
  age_ppg_avg_title: "Age Group Average",
  user: "User",
  age_range_ppg_avg_title: "Age Range Average",
  low: "Low",
  high: "High",
  average: "Average",
  blood_vessel_health_title: "Vascular Health",
  user_age: "User Age",
  blood_vessel_age: "Vascular Age",
  compare_user_age: "Compared to User's Age",
  blood_vessel_health_type_dialog_title:
    "Vascular Type#indicates the internal structure of your blood vessels.",
  blood_vessel_health_type_dialog_subtitle:
    "It is classified into three major types.",
  blood_vessel_health_index_chart_type_dialog_title:
    "The waveform of the Vascular Health Index#indicates the elasticity of your blood vessels.",
  blood_vessel_health_index_chart_type_dialog_subtitle:
    "It is divided into four stages.",
  blood_vessel_health_age_dialog_title:
    "Vascular Health#reflects the function and condition of your blood vessels.",
  blood_vessel_health_age_dialog_subtitle:
    "We'll explain it in an easy-to-understand way!",
  blood_vessel_value: "Pluse Figure",
  blood_vessel_my_value: "My Levels",
  blood_vessel_measure_result: "Blood vessel health measurement result",
  blood_vessel_type: "Type",

  better_title: "It's better this way",

  better_category_food: "Food",
  better_category_exercise: "Exercise",
  better_category_breath: "Breathing practice",

  better_title_food_hr_good: "Low-fat protein, foods rich in omega-3 ",
  better_title_food_hr_up:
    "A diet containing magnesium, omega-3, and antioxidants ",
  better_title_food_hr_down: "A diet containing omega-3, fiber, and potassium ",
  better_title_food_rep_good:
    "Foods rich in antioxidants, omega-3, vitamin D, and magnesium ",
  better_title_food_rep_up:
    "A diet containing magnesium, omega-3, and vitamin B ",
  better_title_food_rep_down:
    "A diet containing iron, vitamin C, omega-3, and magnesium ",
  better_title_food_msi_up:
    "A diet containing magnesium, omega-3, and vitamins ",
  better_title_food_msi_down:
    "Foods rich in omega-3, complex carbohydrates, and vitamins ",
  better_title_food_psi_up:
    "A diet containing iron, complex carbohydrates, protein, and omega-3 ",
  better_title_food_psi_down: "Foods rich in protein, iron, and vitamins ",

  better_title_exercise_hr_good: "Low-fat protein, foods rich in omega-3 ",
  better_title_exercise_hr_up:
    "Improving cardiovascular health through light aerobic and strength exercises ",
  better_title_exercise_hr_down:
    "Strengthening heart muscles through regular aerobic and strength exercises ",
  better_title_exercise_rep_good:
    "Maintaining stability through aerobic exercise, breath control, and strength training ",
  better_title_exercise_rep_up:
    "Controlling breath count through yoga, meditation, light walks, and stretching ",
  better_title_exercise_rep_down:
    "Improving cardiorespiratory function through aerobic exercises, yoga, and stretching ",
  better_title_exercise_msi_up:
    "Calming the body and mind through aerobic exercises, yoga, meditation, and strength training ",
  better_title_exercise_msi_down:
    "Maintaining stability through consistent aerobic exercise, yoga, strength training, and meditation ",
  better_title_exercise_psi_up:
    "Relieving bodily tension with light aerobic exercise and stretching ",
  better_title_exercise_psi_down:
    "Maintaining a stable condition through strength training and stretching",

  // 음식
  better_comment_food_hr_good:
    "When the heart rate is within the normal range, it is important for heart health and overall wellness to have a varied and balanced diet that includes whole grains, a variety of fruits and vegetables, low-fat protein sources (poultry, fish, legumes), fish rich in omega-3 fatty acids, and healthy fats (avocado, olive oil, nuts). \n\nAdequate hydration and limiting caffeinated and sugary beverages are also essential for maintaining heart health. \n\nBy consuming these nutrients in a balanced way, you can reduce the risk of heart disease and keep your heart rate at a healthy level. ",
  better_comment_food_hr_up:
    "When the heart rate is high, it is recommended to consume foods rich in magnesium (spinach, almonds, black beans) and omega-3 fatty acids (salmon, mackerel, flaxseed), as well as fruits and vegetables that are high in antioxidants (berries, kale) and drinks rich in antioxidants (green tea, herbal tea). \n\n It is also important to avoid caffeine, alcohol, and high-sugar processed foods, and to ensure adequate hydration and rest to stabilize the heart rate and improve cardiovascular health. ",
  better_comment_food_hr_down:
    "When the heart rate is below the normal range, it is advisable to consume foods rich in omega-3 fatty acids (salmon, tuna, flaxseeds, chia seeds, walnuts), fiber (vegetables, fruits, whole grains, legumes), potassium (bananas, potatoes, spinach, apricots), and antioxidants (berries, dark chocolate, artichokes, nuts). \n\nThese foods are beneficial for stabilizing heart rate, improving heart rhythm, aiding digestion, regulating blood sugar and cholesterol levels, and protecting heart and vascular health, thereby reducing the burden on the cardiovascular system. \n\nIt is important to consult with a doctor if you consistently have a low heart rate.",
  better_comment_food_rep_good:
    "When the breathing rate is within the normal range, it is beneficial to consume foods rich in antioxidants (berries, spinach, broccoli), omega-3 fatty acids (salmon, walnuts), vitamin D (egg yolks, fatty fish), and magnesium (almonds, pumpkin seeds) to enhance respiratory function and support physical health. \n\nThese nutrients are helpful for cell protection, reducing inflammation, and supporting respiratory muscle function. A balanced diet can promote overall and respiratory health. ",
  better_comment_food_rep_up:
    "When the breathing rate is high, reducing physical and mental tension and calming down can be facilitated by consuming foods rich in magnesium (spinach, almonds, pumpkin seeds), omega-3 fatty acids (salmon, flaxseeds, walnuts), and vitamins B6 and B12 (chicken, salmon, dairy). \n\nThe intake of chamomile and lavender herbal teas is also beneficial. Adequate hydration is important, and these nutrients and fluids can help alleviate a high breathing rate and reduce stress responses.  \n\nRegular consumption of these foods and beverages can promote physical and mental stability. ",
  better_comment_food_rep_down:
    "When the breathing rate is below the standard range, it is advisable to consume a diet rich in iron (spinach, lentils, red meat), vitamin C (oranges, kiwi, broccoli), omega-3 fatty acids (salmon, flaxseeds), and magnesium (almonds, pumpkin seeds). \n\nThese nutrients can promote oxygenation, aid in the absorption of iron, support the relaxation of respiratory muscles, and improve respiratory health, thereby enhancing overall health. ",
  better_comment_food_msi_good:
    "When stress is within the normal range, it is advisable to maintain physical and mental health and manage stress by consuming a diet that includes magnesium (spinach, pumpkin seeds, almonds), omega-3 fatty acids (salmon, flaxseeds, walnuts), complex carbohydrates (whole grain bread, oats, brown rice), B vitamins (whole grain products, eggs, dairy), vitamin C (oranges, kiwi, broccoli), and tryptophan (turkey, chicken, milk).  \n\nThese foods help to calm the nervous system, maintain energy levels, and reduce stress hormones.  \n\nA balanced diet, regular exercise, adequate sleep, and practicing stress management techniques are important for enhancing overall health. ",
  better_comment_food_msi_up:
    "When stress levels are high, it is important to consume foods rich in magnesium (spinach, pumpkin seeds, almonds), omega-3 fatty acids (salmon, flaxseeds, walnuts), B vitamins (whole grain products, eggs, dairy), and vitamin C (oranges, kiwi, broccoli) to calm the nervous system, promote emotional stability, and lower stress hormone levels.  \n\nAdditionally, consuming protein foods rich in tryptophan (turkey, chicken, milk) and ensuring adequate hydration can help improve mood and mitigate stress responses.  \n\nSuch a balanced diet is essential for maintaining physical and mental well-being and effectively managing stress. ",
  better_comment_food_psi_good:
    "When fatigue is within the normal range, maintaining energy levels and boosting vitality can be achieved by continuously consuming foods rich in nutrients such as complex carbohydrates (whole grain bread, oats, brown rice), proteins (chicken, fish, legumes, nuts), iron (spinach, red meats, lentils), and omega-3 fatty acids (salmon, flaxseeds, chia seeds).  \n\nAdditionally, the intake of fruits and vegetables rich in vitamins and minerals and adequate hydration are important for enhancing overall health and energy levels.  \n\nSuch a balanced diet aids in maintaining health and managing fatigue in daily life. ",
  better_comment_food_psi_up:
    "To reduce fatigue and boost energy, it is important to have a balanced diet that includes foods rich in iron (spinach, red meats, lentils), complex carbohydrates (whole grain bread, oats, brown rice), proteins (chicken, fish, legumes, nuts), and omega-3 fatty acids (salmon, flaxseeds, chia seeds), along with adequate hydration.  \n\nThese nutrients are helpful in increasing energy levels in daily life and managing persistent fatigue. ",

  // 운동
  better_comment_exercise_hr_good:
    "When the heart rate is normal, it is recommended to regularly engage in a variety of activities that include aerobic exercises (fast walking, jogging, cycling, swimming, dancing) and strength training (weight lifting, bodyweight exercises, resistance band workouts) to maintain and enhance heart health. \n\nThese exercises strengthen the cardiovascular system, improve heart rate, and build muscle to increase the heart's efficiency. \n\nWhen planning an exercise regimen, it's important to consider your fitness level and health status, and seek advice from a professional if necessary. ",
  better_comment_exercise_hr_up:
    "When the heart rate is high, it is crucial to choose low to moderate intensity aerobic exercises that can strengthen the cardiovascular system without placing excessive strain on the heart. Walking and light strength training using lightweight dumbbells are effective ways to manage the heart rate appropriately and improve heart health. \n\nIt is recommended to consult with a doctor or health professional before exercising to safely plan your workout, avoid excessive intensity, and adjust according to your fitness level. ",
  better_comment_exercise_hr_down:
    "When the heart rate is below the normal range, it is advisable to consider exercises that include aerobic activities (walking, jogging, swimming, cycling) and strength training (light weight training, resistance band workouts) to strengthen the heart muscle and improve blood circulation. \n\nThese activities are beneficial for naturally increasing the heart rate and improving the efficiency of the heart. \n\nIt is important to consult with a doctor before starting any exercise to establish an appropriate exercise plan that matches your health status and fitness level, and seeking advice from a professional for consistently low heart rate is recommended.",
  better_comment_exercise_rep_good:
    "When the breathing rate is standard, it is beneficial to regularly engage in a variety of exercises to strengthen the body and respiratory function and maintain overall health. \n\nThis includes aerobic exercises (walking, jogging, cycling, swimming), yoga or Pilates, and strength training. These exercises can enhance heart health and respiratory efficiency, improve cardiopulmonary function and muscle strength, and promote stress management and mental well-being.  \n\nIt's important to diversify and choose exercise plans that match your fitness level and interests. ",
  better_comment_exercise_rep_up:
    "When the breathing rate is high, it is important to choose activities such as yoga, meditation or mindfulness, light walking or jogging, and stretching or Pilates to calm the body and mind and regulate breathing. \n\nThese activities help to release tension in the body and mind, decrease stress hormones, and encourage deep and regular breathing.  \n\nRegular practice can promote physical and mental stability and reduce stress. ",
  better_comment_exercise_rep_down:
    "When the breathing rate is below the standard range, it is advisable to engage in light aerobic exercises (fast walking, light jogging, swimming), yoga or Pilates focused on breathing control, and regular stretching. \n\nThese activities can strengthen respiratory muscles, enhance cardiopulmonary function, and improve breathing patterns. \n\nIt is important to create an exercise plan considering your fitness level and health status. ",
  better_comment_exercise_msi_good:
    "When stress is within the standard range, it is recommended to consistently engage in a variety of activities to maintain physical and mental balance and manage stress. \n\nThese activities include aerobic exercises (walking, jogging, cycling, swimming), yoga and Pilates, strength training (weight training, resistance band exercises), and meditation and breathing exercises.  \n\nSuch activities help strengthen the heart, relieve bodily tension, enhance positive mood, and improve mental clarity, thereby reducing stress hormone levels. Regular practice can enhance stress management and overall well-being. ",
  better_comment_exercise_msi_up:
    "When stress levels are high, choosing a variety of exercises, including aerobic activities (walking, jogging, cycling, swimming), yoga and Pilates, meditation and breathing exercises, and strength training, is important for finding physical and mental balance and reducing stress.  \n\nThese activities provide positive effects by strengthening the heart, relaxing the body and mind, improving mood, and lowering stress hormone levels.  \n\nRegular practice aids in stress management and maintaining a healthy lifestyle. ",
  better_comment_exercise_psi_good:
    "When fatigue is within the normal range, it is advisable to regularly perform a variety of exercises for maintaining physical fitness and health improvement. \n\nThis includes aerobic exercises (walking, jogging, cycling, swimming), strength training (weight training, resistance band workouts), and flexibility and balance exercises (yoga, Pilates, stretching).  \n\nThese activities boost energy levels and mood, reduce stress, and relieve muscle tension, thereby enhancing overall health. It's important to tailor the exercise plan to your fitness level and interests for a diverse and engaging routine. ",
  better_comment_exercise_psi_up:
    "When feeling fatigued, engaging in light aerobic exercises (walking, light jogging, cycling), stretching, yoga, Pilates, and swimming can be ideal activities for reducing stress and boosting energy.  \n\nThese exercises invigorate the body, relieve muscle tension, and improve blood circulation, thereby enhancing overall health. It's important to tailor the amount of exercise to your fitness level, seek advice from a professional if necessary, and avoid excessive fatigue. ",

  better_comment_breath:
    "Deep and slow breathing or meditation can help calm the mind and reduce stress. Breathing practice involves taking deep breaths and exhaling slowly, while meditation involves focusing on your breathing in a comfortable place. Consistent practice is important.",

  // glucose Comment
  better_glucose_food: "Low Fat, Low Salt Diet",
  better_glucose_exercise: "Regular Exercise",
  better_glucose_water: "Hydration",
  better_glucose_comment_food:
    "When cooking, reduce the use of cooking oil and add flavor with herbs and spices instead of salt. Additionally, cultivating a habit of enjoying fresh fruits and vegetables rather than processed foods is a good way to maintain a healthy diet.",
  better_glucose_comment_exercise:
    "Regular exercise is essential for both physical and mental health. Exercise directly lowers blood sugar and helps prevent long-term complications of diabetes while aiding stress relief. Enjoy a steady exercise routine in your daily life for a healthier and more energetic lifestyle!",
  better_glucose_comment_water:
    "Water constitutes 60% of the body and is essential for toxin removal, temperature regulation, joint protection, digestion support, and nutrient transport. For maintaining good health, it is recommended to consume 2–3 liters of water per day.",

  better_blood_type_food: "Food",
  better_blood_type_exercise: "Exercise",
  better_blood_type_medical: "a medical examination",
  better_blood_type_rest: "Rest",

  better_blood_comment_food:
    "Reduce saturated fat, trans fat, and sodium intake, and consume a variety of plant-based foods (fruits, vegetables, whole grains, nuts). Excessive alcohol consumption can also elevate blood pressure, so drink alcohol in moderation.",
  better_blood_comment_exercise:
    "For vascular health, engage in moderate to low–intensity aerobic exercise (walking, cycling) 3–5 times a week, each session lasting 30 minutes. Additionally, increase flexibility and improve blood circulation through stretching or yoga.",
  better_blood_comment_medical:
    "Regular health check-ups help monitor the condition of your blood vessels. Manage cholesterol, blood pressure, and blood sugar levels, and consult with a specialist if needed to find ways to improve vascular health.",
  better_blood_comment_rest:
    "Adequate rest is essential for vascular health. Take regular breaks to reduce stress, prioritize deep sleep, and use relaxation techniques such as meditation or deep breathing to maintain vascular health.",

  //-----------------------inbody---------------------------
  inbody_result_feedback_normal_0:
    "Overall, it is a step that requires active health care.",
  inbody_result_feedback_normal_1_smm:
    "Muscle mass category is normal, but caution is required.",
  inbody_result_feedback_normal_1_tbw:
    "Body water content category is normal, but caution is required.",
  inbody_result_feedback_normal_1_pbf:
    "Body fat percentage category is normal, but caution is required.",
  inbody_result_feedback_normal_1_whr:
    "Abdominal fat percentage items are normal, but# care is required.",
  inbody_result_feedback_normal_2: "Attention is needed for health concern.",
  inbody_result_feedback_normal_3_smm:
    "Caution is needed for the muscle mass category.",
  inbody_result_feedback_normal_3_tbw:
    "Caution is needed for the body water content category.",
  inbody_result_feedback_normal_3_pbf:
    "Caution is needed for the body fat percentage category.",
  inbody_result_feedback_normal_3_whr:
    "Caution is needed for the abdominal fat percentage category.",
  inbody_result_feedback_normal_4: "All normal! Keep it up!",
  permission_denied_message: "Please allow permission",
  home: "Home",
  inbody_bmi_data: "BMI @",
  inbody_after_move: " left",
  inbody_second: "@ sec",
  inbody_ok: "Confirm",
  inbody_retry: "Retry",
  inbody_back: "Back",
  inbody_do_back: "Back",
  inbody_score: "@ points",
  inbody_normal: "Normal",
  inbody_needs_attention: "Management required",
  inbody_caution_needs: "the need for attention",
  inbody_rank: "@ in the top",
  inbody_receive_mobile: "Receive on mobile",
  inbody_swipe_hint: "Push the screen to the side!",
  inbody_swipe_hint_content_easy_access_hint:
    "You can easily view the content.",
  inbody_complete: "Complete",
  inbody_smm: "skeletal muscle",
  inbody_tbw: "body water",
  inbody_pbf: "body fat percentage",
  inbody_whr: "abdominal fat percentage",
  inbody_gram: "@g",
  inbody_percentage: "@%",
  inbody_do_connect: "Connecting",
  inbody_cancel_connect: "Disconnect",
  inbody_standard_range: "Normal range",
  within_standard: "Within normal",
  inbody_carbohydrate: "Carbohydrate",
  inbody_fat: "Fat",
  inbody_protein: "Protein",
  inbody_empty: "",
  inbody_return_to_device_connection: "Return to Device Connection",
  SMM: "Skeletal muscle",
  TBW: "Body water",
  PBF: "Body fat percentage",
  WHR: "Abdominal fat percentage",
  PSMM: "Skeletal muscle percentage",
  inbody_PBF_MIN: "Minimum Body Fat Standard",
  inbody_PBF_MAX: "the maximum standard for body fat",
  inbody_IPBF: "ideal body fat percentage",
  inbody_WHR_MAX: "Abdominal fat maximum standard",
  inbody_WHR_MIN: "Minimum Standard for Abdominal Fat Rate",
  inbody_SMM_MIN: "Skeletal Muscle Minimum Standard",
  inbody_SMM_MAX: "Skeletal Muscle Maximum Standard",
  inbody_TBW_MIN: "Minimum Body Moisture Standard",
  inbody_TBW_MAX: "Maximum Body Moisture Standard",
  bmi_1: "Underweight",
  bmi_2: "Normal weight",
  bmi_3: "Overweight",
  bmi_4: "Obese",
  bmi_5: "Severely obese",
  chicken_breast_steak: "Chicken breast steak",
  salmon_steak: "Salmon steak",
  chicken_breast: "Chicken breast",
  salmon: "Salmon",
  soy_sauce: "Soy sauce",
  honey: "Honey",
  garlic: "Garlic",
  olive_oil: "Olive oil",
  onion: "Onion",
  fresh_lemon_juice: "Lemon juice",
  inbody_inbodyconnect_instruction_start:
    "Input complete! Starting device connection.",
  inbody_inbodyconnect_instruction_stand_on:
    "Please stand on the footboard of the in-body device!",
  inbody_inbodyconnect_status_connecting: "Connecting device.",
  inbody_inbodyconnect_instruction_disconnect_when_leave_device:
    "When you leave the footrest of the in-body device,#will disconnect.",
  inbody_inbodyconnect_progress_percentage: "%d completed",
  inbody_inbodyconnect_status_connected: "Device connection completed.",
  inbody_inbodyconnect_instruction_next_step: "Measurements will begin soon.",
  inbody_inbodyconnect_status_connecting_fail: "Device connection failed.",
  inbody_inbodyconnect_status_bluetooth_connecting_fail:
    "Bluetooth connection failed.",
  inbody_inbodyconnect_instruction_check_settings:
    "Please check the settings and try again.",
  inbody_connecting_cancel_connection: "Disconnect",
  inbody_inbodyconnect_status_connecting_cancel: "Connection canceled.",
  inbody_inbodyconnect_instruction_press_connect:
    "Please press the button to connect.",
  inbody_inbodyconnect_status_connecting_delay: "Connection is being delayed.",
  inbody_inbodyconnect_instruction_if_cant_connect_try_again:
    "If the connection continues to fail, go back and retry.",
  inbody_preparation_barefoot_title: "It is tested barefoot.",
  inbody_preparation_barefoot_description:
    "Socks/stockings affect current flow,#causing inaccurate body #ncomposition measurements.",
  inbody_preparation_after_toilet_title: "Measure after using the bathroom.",
  inbody_preparation_after_toilet_description:
    "Fecal urine is not body composition, but it can affect the test results.",
  inbody_preparation_moist_hands_feet_title: "Keep your hands and feet moist.",
  inbody_preparation_moist_hands_feet_description:
    "Please wipe the palms and soles of your feet with wet tissue and inspect them.",
  inbody_preparation_header_main_text: "Please check before the test!",
  inbody_preparation_header_sub_text: "Precautions Before Inspection",
  inbody_preparation_posture_step2_main_text: "Thighs should not touch.",
  inbody_preparation_posture_step3_main_text:
    "Don't take your feet off and stick them firmly on the device.",
  inbody_preparation_posture_step4_main_text: "Now, hold the handle!",
  inbody_preparation_posture_sub_text: "Precautions for inspection",
  inbody_preparation_go_measuring: "Direct to measurement",
  inbody_measuring_in_progress: "Measuring body composition.",
  inbody_measuring_in_progress2: "Please keep the arms and legs together.",
  inbody_measuring_duration_approx: "It will take about 60 seconds.",
  inbody_measuring_warnings: "Movement may cause disconnection.",
  inbody_measuring_completed: "Measurement completed.",
  inbody_measuring_check_result: "Check the measurement results.",
  inbody_measuring_fail: "Measurement failed.",
  inbody_measuring_fail_do_not_move: "Do not move during measurement.",
  inbody_report_user_balance_evalutation_good: "Balance is well maintained!",
  inbody_report_user_information: "@1cm, @2kg \n@3 years /",
  inbody_report_range_type_under: "Necessity",
  inbody_report_range_type_over: "High",
  inbody_report_under_weight: "Underweight",
  inbody_report_standard_weight: "Normal weight",
  inbody_report_over_weight: "Overweight",
  inbody_report_obese_weight: "Obese",
  inbody_report_severe_obese_weight: "Severely obese",
  inbody_report_header_title: "InBody test results.",
  inbody_report_header_title_sub: "Tap each item.",
  inbody_dashboard_detail_guide_title: "Diet guide",
  inbody_dashboard_detail_guide_description:
    "Based on your health information, we propose a diet to promote @.",
  inbody_dashboard_detail_diet_detailed_info: "View detailed information",
  inbody_dashboard_detail_diet_image_description: "Meal image",
  inbody_dashboard_detail_daily_intake_cal_percentage:
    "@kcal#%% of recommended \n daily intake",
  inbody_dashboard_detail_nutrient_info_title: "Nutritional info.",
  inbody_dashboard_detail_nutrient_info_title_detail: "Details",
  inbody_dashboard_detail_daily_intake_note:
    "*Based on daily \nrecommended intake",
  inbody_dashboard_detail_daily_intake_note1:
    "15% of recommended \n daily intake",
  inbody_dashboard_detail_daily_intake_note2:
    "25% of recommended \n daily intake",
  inbody_dashboard_detail_standard_intake_note: "*Per serving",
  inbody_dashboard_detail_carbohydrate_format1: "/ Carbohydrates @g",
  inbody_dashboard_detail_protein_format1: "/ Protein @g",
  inbody_dashboard_detail_fat_format1: "/ Fats @g",
  inbody_dashboard_detail_title_note: "Compared with normal average values.",
  inbody_dashboard_detail_evaluation_title_inner_smm:
    "Skeletal muscle mass is within the standard range.",
  inbody_dashboard_detail_evaluation_title_under_smm:
    "Skeletal muscle mass is below the standard range.",
  inbody_dashboard_detail_evaluation_title_over_smm:
    "Skeletal muscle mass exceeded the standard range.",
  inbody_dashboard_detail_evaluation_title_inner_tbw:
    "Body water content is within the standard range.",
  inbody_dashboard_detail_evaluation_title_under_tbw:
    "Body water content is below the standard range.",
  inbody_dashboard_detail_evaluation_title_over_tbw:
    "Body water content exceeds the standard range.",
  inbody_dashboard_detail_evaluation_title_inner_pbf:
    "Body fat percentage is within the standard range.",
  inbody_dashboard_detail_evaluation_title_under_pbf:
    "Body fat percentage is below the standard range.",
  inbody_dashboard_detail_evaluation_title_over_pbf:
    "Body fat percentage exceeded the standard range.",
  inbody_dashboard_detail_evaluation_title_inner_whr:
    "Abdominal fat percentage is within the standard range.",
  inbody_dashboard_detail_evaluation_title_under_whr:
    "Abdominal fat percentage is below the standard range.",
  inbody_dashboard_detail_evaluation_title_over_whr:
    "Abdominal fat percentage exceeded the standard range.",
  inbody_dashboard_detail_evaluation_description_inner_smm:
    "You're doing great!",
  inbody_dashboard_detail_evaluation_description_under_smm:
    "Consistent management is needed.",
  inbody_dashboard_detail_evaluation_description_over_smm:
    "Balanced management is needed.",
  inbody_dashboard_detail_evaluation_description_inner_tbw:
    "You're doing great!",
  inbody_dashboard_detail_evaluation_description_under_tbw:
    "Consistent management is needed.",
  inbody_dashboard_detail_evaluation_description_over_tbw:
    "Balanced management is needed.",
  inbody_dashboard_detail_evaluation_description_inner_pbf:
    "You're doing great!",
  inbody_dashboard_detail_evaluation_description_under_pbf:
    "Consistent management is needed.",
  inbody_dashboard_detail_evaluation_description_over_pbf:
    "Balanced management is needed.",
  inbody_dashboard_detail_evaluation_description_inner_whr:
    "You're doing great!",
  inbody_dashboard_detail_evaluation_description_under_whr:
    "Consistent management is needed.",
  inbody_dashboard_detail_evaluation_description_over_whr:
    "Balanced management is needed.",
  inbody_dashboard_detail_evaluation_description_detail_inner_smm:
    "Currently, skeletal muscle mass is within the normal range. Skeletal muscle is one of the important factors that determine our body's basic motor skills and the quality of our daily lives. Try to maintain this good condition by keeping up with continuous fitness management and a healthy lifestyle.",
  inbody_dashboard_detail_evaluation_description_detail_under_smm:
    "Currently, skeletal muscle mass has been measured below the normal range. Steady exercise and a balanced diet are necessary to improve skeletal muscle mass. Adequate skeletal muscle mass greatly contributes to maintaining good health.",
  inbody_dashboard_detail_evaluation_description_detail_over_smm:
    "Currently, skeletal muscle mass has been measured above the normal range. This indicates well-developed muscles. However, excessive muscle gain can impact your health, so it is important to maintain balanced exercise and dietary habits.",
  inbody_dashboard_detail_evaluation_description_detail_inner_tbw:
    "Currently, your body water content is within the normal range. Body water content is closely related to your overall health status. It is hoped that you maintain this condition by consistently drinking water and maintaining a healthy lifestyle.",
  inbody_dashboard_detail_evaluation_description_detail_under_tbw:
    "Currently, your body water content has been measured below the normal range. Since body water content is closely related to our health, it is important to compensate for the lack of water. Check your recommended daily water intake and current lifestyle habits, and consult with an expert if necessary.",
  inbody_dashboard_detail_evaluation_description_detail_over_tbw:
    "Currently, your body water content is measured above the normal range. Excessive body water can affect various body functions. To maintain a proper moisture balance, monitor your fluid intake and eating patterns, and consult an expert if you have any abnormal symptoms or concerns.",
  inbody_dashboard_detail_evaluation_description_detail_inner_pbf:
    "Currently, the body fat percentage is within the normal range. Body fat percentage is associated with overall health status, physical function, and metabolic health issues. We recommend continuing consistent exercise and balanced meals to maintain your current state.",
  inbody_dashboard_detail_evaluation_description_detail_under_pbf:
    "Currently, the body fat percentage is measured below the normal range. A low body fat percentage may indicate a lack of necessary energy storage and multiple functions, so caution is needed. Keep your body fat percentage within the appropriate range through balanced diet and nutritional intake.",
  inbody_dashboard_detail_evaluation_description_detail_over_pbf:
    "Currently, the body fat percentage is measured above the normal range. Excessive body fat percentage can increase the risk of various health issues, so caution is necessary. It's important to control the body fat percentage through consistent exercise, balanced diet, and lifestyle improvements. Consulting with experts for a health and dietary plan suited to individual circumstances is recommended.",
  inbody_dashboard_detail_evaluation_description_detail_inner_whr:
    "Currently, the abdominal fat percentage is within the normal range. Abdominal fat is related to various functions and health conditions of the body. Since your abdominal fat percentage is currently stable, we recommend continuing regular exercise and balanced meals.",
  inbody_dashboard_detail_evaluation_description_detail_under_whr:
    "Currently, the abdominal fat percentage is measured below the normal range. Excessively low abdominal fat percentage can affect the body's energy storage and various functions. It is recommended to keep your body fat percentage within the appropriate range through balanced diet and nutritional intake.",
  inbody_dashboard_detail_evaluation_description_detail_over_whr:
    "Currently, the abdominal fat percentage is measured above the normal range. Excessively high abdominal fat percentage can affect the body's energy storage and various functions. It is recommended to keep your body fat percentage within the appropriate range through balanced diet and nutritional intake.",
  inbody_dashboard_detail_normal_amount_string: "@1～@2",
  glucose_popup_title:
    "The waveform of the blood vessel health index refers#to the elasticity of the blood vessel.",
  glucose_popup_sub: "It consists of four stages in total.",

  glucose_result_title: "Blood glucose measurement results",
  result_title: "Diagnosis Result",
  glucose_result_high_text: "Higher than the normal range by @mg/dL",
  glucose_result_low_text: "Lower than the normal range by @mg/dL",
  glucose_line_chart_title: "Recent Health Status",

  glucose_grade_good_type: "Normal",
  glucose_grade_good_title: "Congratulations!# Blood sugar levels are normal.",
  glucose_grade_good_sub: "Just take care of it like this!",
  glucose_grade_good_comment_up_title: "Please maintain your current lifestyle",
  //---------------glucose--------------------
  glucose_result_type_waring_title:
    "Just be a little careful,#Initial to diabetes!",
  glucose_result_type_waring_subtitle:
    "All you have to do is get back to a healthy lifestyle!",
  glucose_result_type_waring_result: "Early Stage Diabetes",
  glucose_result_type_waring_chart: "Maintain Healthy Lifestyle Habits",
  glucose_result_type_danger_title:
    "Your Blood Sugar Level is High. Diabetes is Suspected.",
  glucose_result_type_danger_subtitle: "Professional Consultation is Required.",
  glucose_result_type_danger_result: "Diabetes",
  glucose_result_type_danger_chart: "Pay More Attention to Health Management",
  glucose_category_type_low_fat_low_salt_title: "Low Fat, Low Salt Diet",
  glucose_category_type_low_fat_low_salt_body:
    "When cooking, reduce the use of cooking oil and add flavor with herbs and spices instead of salt. Additionally, cultivating a habit of enjoying fresh fruits and vegetables rather than processed foods is a good way to maintain a healthy diet.",
  glucose_category_type_exercise_title: "Regular Exercise",
  glucose_category_type_exercise_body:
    "Regular exercise is essential for both physical and mental health. Exercise directly lowers blood sugar and helps prevent long-term complications of diabetes while aiding stress relief. Enjoy a steady exercise routine in your daily life for a healthier and more energetic lifestyle!",
  glucose_category_type_water_title: "Hydration",
  glucose_category_type_water_body:
    "Water constitutes 60% of the body and is essential for toxin removal, temperature regulation, joint protection, digestion support, and nutrient transport. For maintaining good health, it is recommended to consume 2–3 liters of water per day.",
  glucose_result_button_back: "Home",
  glucose_result_button_move_mobile_page: "Receive on Mobile",
  glucose_result_text_mg_dl: "@mg/dL",
  glucose_result_text_last_update_date: "@day ago",
  glucose_result_screen_text_chart_body_resent_data_normal:
    "The blood sugar level is measured at @1mg/dL, falling within the 'Normal' range. You have been maintaining a good blood sugar level over the past @3 measurements! Continue your healthy lifestyle habits and balanced diet for optimal well-being. Periodic health check-ups are recommended to closely monitor your body's condition.",
  glucose_result_screen_text_chart_body_resent_data_bad_danger:
    "The blood sugar level is measured at @1mg/dL, which is approximately @2% higher than the normal range, indicating a potential risk of 'Diabetes.' Your recent @3 measurements have shown consistently high blood sugar levels. Diabetes, if left unmanaged, can lead to various complications. Consult with a healthcare professional for advice and pay closer attention to health management!",
  glucose_result_screen_text_chart_body_resent_data_good_danger:
    "The blood sugar level is measured at @1mg/dL, which is approximately @2% higher than the normal range, indicating a potential risk of 'Diabetes.' While there has been a @4m/dL improvement compared to your recent @3 measurements, diabetes can lead to various complications, requiring continuous management. Consult with a healthcare professional for advice and pay closer attention to health management!",
  glucose_result_screen_text_chart_body_resent_data_bad_waring:
    "The blood sugar level is measured at @1mg/dL, which is approximately @2% higher than the normal range, indicating a potential risk of 'Early Stage Diabetes.' Your recent @3 measurements have shown slightly elevated blood sugar levels. Diabetes, if left unmanaged, can lead to various complications. Put in more effort!",
  glucose_result_screen_text_chart_body_resent_data_good_waring:
    "The blood sugar level is measured at @1mg/dL, which is approximately @2% higher than the normal range, indicating a potential risk of 'Early Stage Diabetes.' While there has been a @4m/dL improvement compared to your recent @3 measurements, diabetes can lead to various complications, requiring continuous management. Put in more effort!",
  glucose_result_screen_text_chart_body_resent_no_data_normal:
    "The blood sugar level is @1m/dL, falling within the 'Normal' range. Continue maintaining your healthy lifestyle habits and balanced diet. Keep a watchful eye on your body's condition through regular health check-ups for optimal well-being.",
  glucose_result_screen_text_chart_body_resent_no_data_waring:
    "The blood sugar level is measured at @1mg/dL, which is approximately @2% higher than the normal range, indicating a potential risk of 'Early Stage Diabetes.' Diabetes can lead to various complications, requiring continuous management. Put in more effort!",
  glucose_result_screen_text_chart_body_resent_no_data_danger:
    "The blood sugar level is measured at @1mg/dL, which is approximately @2% higher than the normal range, indicating a potential risk of 'Diabetes.' Consult with a healthcare professional for advice and pay closer attention to health management!",
  glucose_device_search_button_connect_device: "Connect to the Device",
  glucose_device_search_text_title: "Devices to Connect",
  glucose_device_search_text_subtitle:
    "Search for devices that can be connected.",
  glucose_device_search_text_registered_device_title: "Registered Devices",
  glucose_device_search_text_new_search_device_title:
    "Newly Discovered Devices",
  glucose_device_search_button_search: "Search",
};
