import styled from "styled-components";
import { pxToVh } from "../../../utils/calc/pxToVh";

export const SquareLayout = styled.div`
  width: ${pxToVh(342)};
  margin-top: ${pxToVh(32)};
  display: flex;
  flex-wrap: wrap;
  gap: ${pxToVh(18)} ${pxToVh(10)};
  margin-bottom: ${pxToVh(18)};
`;

export const SingleSquare = styled.div`
  width: ${pxToVh(166)};
  height: ${pxToVh(150)};
  border: ${({ theme }) => `1px solid ${theme.color.gray200}`};
  border-radius: ${pxToVh(12)};
  padding: ${pxToVh(16)} 0 ${pxToVh(13)} ${pxToVh(13)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TitleLayout = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-right: ${pxToVh(13)};
  svg {
    width: ${pxToVh(20)};
    height: ${pxToVh(20)};
  }
`;

export const BottomLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-right: ${pxToVh(16)};
  span {
    line-height: 150%;
  }
  svg {
    width: ${pxToVh(24)};
    height: ${pxToVh(24)};
  }
`;

export const UrlCenter = styled.div`
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

export const TitleLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToVh(6)} 0;
`;

export const BottomLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToVh(2)};
`;
