import styled from "styled-components";
import { pxToVh } from "../../../utils/calc/pxToVh";

export const BloodSquareContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${pxToVh(18)};
  margin-bottom: ${pxToVh(28)};
`;

export const BloodSquareBox = styled.div`
  margin-top: ${pxToVh(28)};
  width: ${pxToVh(162)};
  height: ${pxToVh(168)};
  padding: ${pxToVh(25)} ${pxToVh(20)} ${pxToVh(18)} ${pxToVh(20)};
  background-color: ${({ theme }) => theme.color.gray300};
  border: ${({ theme }) => `1px solid ${theme.color.gray100}`};
  border-radius: ${pxToVh(15)};
  display: flex;
  flex-direction: column;
`;

export const SvgType = styled.div<{ width: number; height: number }>`
  width: ${pxToVh(64)};
  height: ${pxToVh(64)};

  svg {
    width: ${({ width }) => pxToVh(width)};
    height: ${({ height }) => pxToVh(height)};
  }
`;

export const ResultBlood = styled.div`
  width: ${pxToVh(325)};
  margin-bottom: ${pxToVh(24)};
`;
