import * as Svg from "../../components/atoms/icon/index";
import { useLocalization } from "../../hooks/useLocalization";

export type INutriData = {
  [index: string]: {
    svg: React.ReactNode;
    infoText: {
      name: string;
      kcal: string;
      sub: string;
    };
    mainCircleValue: number;
    info: {
      circleValue: number;
      nutriTitle: string;
      nutriValue: string;
    }[];
    details: {
      title: string;
      detail: string;
    }[];
  };
};

export const NutriData = () => {
  const translocation = useLocalization();
  const nutriData: INutriData = {
    chicken: {
      svg: <Svg.Chicken />,
      infoText: {
        name: translocation.chicken_breast_steak, //"닭가슴살 스테이크",
        kcal: "304kcal",
        sub: translocation.inbody_dashboard_detail_daily_intake_note1, //"하루 권장 섭취량의 15%",
      },
      mainCircleValue: 15,
      info: [
        {
          circleValue: 3,
          nutriTitle: translocation.inbody_carbohydrate, //"탄수화물",
          nutriValue: "15g",
        },
        {
          circleValue: 21,
          nutriTitle: translocation.inbody_protein, //"단백질",
          nutriValue: "30g",
        },
        {
          circleValue: 15,
          nutriTitle: translocation.inbody_fat, //"지방",
          nutriValue: "10g",
        },
      ],
      details: [
        {
          title: translocation.chicken_breast, //"닭가슴살",
          detail: `150g / ${translocation.inbody_carbohydrate} 30g`, //탄수화물
        },
        {
          title: translocation.soy_sauce, // "간장",
          detail: `3ml / ${translocation.inbody_protein} 1g`, //단백질
        },
        {
          title: translocation.garlic, //"마늘",
          detail: `2g / ${translocation.inbody_carbohydrate} 0.2g`, //탄수화물
        },
        {
          title: translocation.honey, //"꿀",
          detail: `5ml / ${translocation.inbody_fat} 1g`, //지방
        },
      ],
    },
    salmon: {
      svg: <Svg.Salmon />,
      infoText: {
        name: translocation.salmon_steak, //"연어 스테이크",
        kcal: "300kcal",
        sub: translocation.inbody_dashboard_detail_daily_intake_note2, //"하루 권장 섭취량의 25%",
      },
      mainCircleValue: 25,
      info: [
        {
          circleValue: 0,
          nutriTitle: translocation.inbody_carbohydrate, // "탄수화물",
          nutriValue: "0g",
        },
        {
          circleValue: 49,
          nutriTitle: translocation.inbody_protein, // "단백질",
          nutriValue: "25g",
        },
        {
          circleValue: 36,
          nutriTitle: translocation.inbody_fat, // "지방",
          nutriValue: "20g",
        },
      ],
      details: [
        {
          title: translocation.salmon, // "연어",
          detail: `200g / ${translocation.inbody_protein} 20g`, //단백질
        },
        {
          title: translocation.olive_oil, // "올리브 오일",
          detail: `15ml / ${translocation.inbody_fat} 10g`, //지방
        },
        {
          title: translocation.onion, // "양파",
          detail: `50g / ${translocation.inbody_carbohydrate} 0.2g`, //탄수화물
        },
        {
          title: translocation.fresh_lemon_juice, //"레몬즙",
          detail: `10ml / ${translocation.inbody_protein}} 1g`, //단백질
        },
      ],
    },
  };
  return nutriData;
};
