import Title from "../texts/title/Title";
import HealthSquare from "../square/deepSquare/DeepSquare";
import { SquareLayout } from "../square/deepSquare/DeepSquareStyles";
import ShareFooter from "../shareFooter/ShareFooter";
import { useDeepMedi } from "../../hooks/useDeepMedi";
import { calcHr, calcRep, calcSi } from "../../utils/calc/CalcHealth";
import { DocumentTitle } from "../../utils/DocumentTitle";
import { useLocalization } from "../../hooks/useLocalization";
import { HealthSignitureController } from "../../utils/comment/HealthSignature";
import { TStatus } from "../../types/deepQuery.type";

const DeepController = () => {
  const { queryData, balancerUrl } = useDeepMedi();
  const translation = useLocalization();
  const { hr, rep, msi, psi } = queryData;
  const { hrStatus, hrMark } = calcHr(hr);
  const { repStatus, repMark } = calcRep(rep);
  const { status: psiStatus } = calcSi(psi);
  const { status: msiStatus } = calcSi(msi);
  const goodCount = [hrStatus, repStatus, psiStatus, msiStatus].filter(
    (m) => m === "good"
  ).length;
  const toArray: {
    key: "hr" | "rep" | "psi" | "msi";
    status: TStatus;
  }[] = [
    { key: "hr", status: hrStatus },
    { key: "rep", status: repStatus },
    { key: "psi", status: psiStatus },
    { key: "msi", status: msiStatus },
  ];
  const badElement = toArray.filter(
    (f) => f.status === "bad" || f.status === "normal"
  );
  const GoodCountTitle = HealthSignitureController(badElement[0]?.key);

  DocumentTitle(
    `${translation.document_title_result_hr} - ${GoodCountTitle[goodCount]}`
  );

  return (
    <>
      <Title
        content={GoodCountTitle[goodCount]}
        subTitle={translation.healthcare_compare_normal}
      />
      <SquareLayout>
        <HealthSquare
          top={translation.hr}
          middle={[hr, translation.per_minute]}
          bottom={`${translation.standard_range} ${
            translation.getLanguage() === "en" ? "\n" : ""
          }(61 ~ 99 ${translation.count})`}
          type={hrStatus}
          mark={hrMark}
          isValid={hr === undefined}
          balancerUrl={() =>
            balancerUrl(
              "hr",
              hr,
              hrStatus,
              hrMark,
              `${translation.document_title_result_hr} - ${GoodCountTitle[goodCount]}`
            )
          }
        />
        <HealthSquare
          top={translation.rep}
          middle={[rep, translation.per_minute]}
          bottom={`${translation.standard_range}  ${
            translation.getLanguage() === "en" ? "\n" : ""
          }(6 ~ 18 ${translation.count})`}
          type={repStatus}
          mark={repMark}
          isValid={rep === undefined}
          balancerUrl={() =>
            balancerUrl(
              "rep",
              rep,
              repStatus,
              "",
              `${translation.document_title_result_hr} - ${GoodCountTitle[goodCount]}`
            )
          }
        />
        <HealthSquare
          top={translation.psi}
          middle={[String(psi)]}
          bottom={`${translation.standard_range}  ${
            translation.getLanguage() === "en" ? "\n" : ""
          }(0 ~ 50)`}
          type={psiStatus}
          isValid={psi === undefined}
          balancerUrl={() =>
            balancerUrl(
              "psi",
              psi,
              psiStatus,
              "",
              `${translation.document_title_result_hr} - ${GoodCountTitle[goodCount]}`
            )
          }
        />
        <HealthSquare
          top={translation.msi}
          middle={[String(msi)]}
          bottom={`${translation.standard_range}  ${
            translation.getLanguage() === "en" ? "\n" : ""
          }(0 ~ 50)`}
          type={msiStatus}
          isValid={msi === undefined}
          balancerUrl={() =>
            balancerUrl(
              "msi",
              msi,
              msiStatus,
              "",
              `${translation.document_title_result_hr} - ${GoodCountTitle[goodCount]}`
            )
          }
        />
      </SquareLayout>
      <ShareFooter
        shareTitle={`${translation.document_title_result_hr} - ${GoodCountTitle[goodCount]}`}
      />
    </>
  );
};

export default DeepController;
