export const calcGlugose = (glucose: number) => {
  if (glucose < 100) return "good";
  else if (glucose < 126) return "normal";
  else return "bad";
};

export const calcBmi = (bmi: number) => {
  if (bmi < 18) return "grade_1";
  else if (bmi < 23) return "grade_2";
  else if (bmi < 25) return "grade_3";
  else if (bmi < 30) return "grade_4";
  else return "grade_5";
};

export const calcInboy = (min: number, max: number, target: number) => {
  if (min <= target && target <= max) return "good";
  else if (min > target) return "down";
  else if (max < target) return "up";
  return "good";
};

export const percentV = (min: number, max: number, v: number) => {
  if (v > max) return 100;
  return ((v - min) / (max - min)) * 100;
};
