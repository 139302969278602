import * as Svg from "../../components/atoms/icon/index";
import { useLocalization } from "../../hooks/useLocalization";
import { THealthType } from "../../types/deepQuery.type";
import { TBmiGrade } from "../../types/inbodyQuery.type";

type TBmiGradeRecord = Record<
  TBmiGrade,
  { title: string; img: React.ReactElement }
>;

export type TInbodyGrade = "smm" | "tbw" | "pbf" | "whr";
type TTransform = Record<
  TInbodyGrade | THealthType,
  { name: string; unit: string }
>;

export const BMIgrade = () => {
  const translocation = useLocalization();
  const bmiGrade: TBmiGradeRecord = {
    grade_1: {
      title: translocation.inbody_report_under_weight, //"저체중",
      img: <Svg.UnWeight />,
    },
    grade_2: {
      title: translocation.inbody_report_standard_weight, //"표준체중",
      img: <Svg.StWeight />,
    },
    grade_3: {
      title: translocation.inbody_report_over_weight, //"과체중",
      img: <Svg.OvWeight />,
    },
    grade_4: {
      title: translocation.inbody_report_obese_weight, //"비만",
      img: <Svg.FatWeight />,
    },
    grade_5: {
      title: translocation.inbody_report_severe_obese_weight, //"고도비만",
      img: <Svg.OvFatWeight />,
    },
  };
  return bmiGrade;
};

type TinbodyComment = {
  [index: string]: {
    [index: string]: {
      title: string;
      resultTitle: string;
      subTitle: string;
    };
  };
};

export const InbodyCommentController = () => {
  const translocation = useLocalization();
  const inbodyComment: TinbodyComment = {
    smm: {
      good: {
        title: translocation.inbody_dashboard_detail_evaluation_title_inner_smm, //"골격근량이#표준 범위 내에요."
        resultTitle:
          translocation.inbody_dashboard_detail_evaluation_description_inner_smm, //"잘 하고 있어요!"
        subTitle:
          translocation.inbody_dashboard_detail_evaluation_description_detail_inner_smm, //"현재 골격근량이 표준 범위 이내입니다. 골격근은 우리 몸의 기본적인 운동 능력과 일상 생활의 품질을 결정하는 중요한 요소 중 하나입니다. 지속적인 체력 관리와 건강한 생활 습관을 유지하면서 좋은 상태를 유지해 보세요.",
      },
      down: {
        title: translocation.inbody_dashboard_detail_evaluation_title_under_smm, //"골격근량이#표준 범위 미만이에요.",
        resultTitle:
          translocation.inbody_dashboard_detail_evaluation_description_under_smm, //"꾸준한 관리가 필요해요.",
        subTitle:
          translocation.inbody_dashboard_detail_evaluation_description_detail_under_smm, //"현재 골격근량이 표준 범위보다 낮게 측정되었습니다. 골격근량을 향상시키기 위한 꾸준한 운동과 균형 잡힌 식사가 필요합니다. 적절한 골격근량은 건강 유지에 큰 도움이 됩니다.",
      },
      up: {
        title: translocation.inbody_dashboard_detail_evaluation_title_over_smm, //"골격근량이#표준 범위를 초과했어요.",
        resultTitle:
          translocation.inbody_dashboard_detail_evaluation_description_over_smm, //"균형잡힌 관리가 필요해요.",
        subTitle:
          translocation.inbody_dashboard_detail_evaluation_description_detail_over_smm, //"현재 골격근량이 표준 범위보다 높게 측정되었습니다. 이는 근육 발달이 잘 되어 있음을 나타냅니다. 그러나 과도한 근육 증가는 건강에 영향을 줄 수 있으므로, 균형 잡힌 운동과 식습관을 유지하는 것이 중요합니다.",
      },
    },
    tbw: {
      good: {
        title: translocation.inbody_dashboard_detail_evaluation_title_inner_tbw, //"체수분량이#표준 범위 내에요.",
        resultTitle:
          translocation.inbody_dashboard_detail_evaluation_description_inner_tbw, //"잘 하고 있어요!",
        subTitle:
          translocation.inbody_dashboard_detail_evaluation_description_detail_inner_tbw, //"현재 체수분량이 표준 범위 이내입니다. 체수분량은 신체의 전반적인 건강 상태와 밀접한 관련이 있습니다. 꾸준한 수분 섭취와 건강한 생활 습관을 유지하여 이 상태를 계속 유지하시길 바랍니다.",
      },
      down: {
        title: translocation.inbody_dashboard_detail_evaluation_title_under_tbw, //"체수분량이#표준 범위 미만이에요.",
        resultTitle:
          translocation.inbody_dashboard_detail_evaluation_description_under_tbw, //"꾸준한 관리가 필요해요.",
        subTitle:
          translocation.inbody_dashboard_detail_evaluation_description_detail_under_tbw, //"현재 체수분량이 표준 범위보다 낮게 측정되었습니다. 체수분량은 우리 몸의 건강과 밀접한 관련이 있으므로, 부족한 수분을 보충하는 것이 중요합니다. 일일 권장 수분 섭취량과 현재의 생활 습관을 확인하고, 필요한 경우 전문가와 상담해보세요.",
      },
      up: {
        title: translocation.inbody_dashboard_detail_evaluation_title_over_tbw, //"체수분량이#표준 범위를 초과했어요.",
        resultTitle:
          translocation.inbody_dashboard_detail_evaluation_description_over_tbw, //"균형잡힌 관리가 필요해요.",
        subTitle:
          translocation.inbody_dashboard_detail_evaluation_description_detail_over_tbw, //"현재 체수분량이 표준 범위보다 높게 측정되었습니다. 과도한 체수분량은 신체의 여러 기능에 영향을 줄 수 있습니다. 적절한 수분 균형을 유지하기 위해 음료 섭취량과 식사 패턴을 확인하시고, 만약 이상 증상이나 걱정이 있으시다면 전문가와 상담하는 것을 권장드립니다.",
      },
    },
    pbf: {
      good: {
        title: translocation.inbody_dashboard_detail_evaluation_title_inner_pbf, //"체지방률이#표준 범위 내에요.",
        resultTitle:
          translocation.inbody_dashboard_detail_evaluation_description_inner_pbf, //"잘 하고 있어요!",
        subTitle:
          translocation.inbody_dashboard_detail_evaluation_description_detail_inner_pbf, //"현재 체지방률이 표준 범위 이내입니다. 체지방률은 전반적인 건강 상태, 신체 기능, 그리고 대사 관련 건강 문제와 관련이 있습니다. 현재 상태를 유지하기 위해 꾸준한 운동과 균형 잡힌 식사를 계속하시길 권장드립니다.",
      },
      down: {
        title: translocation.inbody_dashboard_detail_evaluation_title_under_pbf, //"체지방률이#표준 범위 미만이에요.",
        resultTitle:
          translocation.inbody_dashboard_detail_evaluation_description_under_pbf, //"꾸준한 관리가 필요해요.",
        subTitle:
          translocation.inbody_dashboard_detail_evaluation_description_detail_under_pbf, //"현재 체지방률이 표준 범위보다 낮게 측정되었습니다. 낮은 체지방률은 필요한 에너지 저장 및 여러 기능의 부족을 의미할 수 있으므로 주의가 필요합니다. 균형 잡힌 식사와 영양 섭취를 통해 체지방률을 적정 범위로 유지해주세요.",
      },
      up: {
        title: translocation.inbody_dashboard_detail_evaluation_title_over_pbf, //"체지방률이#표준 범위를 초과했어요.",
        resultTitle:
          translocation.inbody_dashboard_detail_evaluation_description_over_pbf, //"균형잡힌 관리가 필요해요.",
        subTitle:
          translocation.inbody_dashboard_detail_evaluation_description_detail_over_pbf, //"현재 체지방률이 표준 범위보다 높게 측정되었습니다. 과도한 체지방률은 다양한 건강 문제의 위험을 증가시킬 수 있으므로 주의가 필요합니다. 꾸준한 운동, 균형 잡힌 식사, 그리고 생활 습관 개선을 통해 체지방률을 조절하는 것이 중요합니다. 개인의 상황에 맞는 건강 및 식생활 계획을 세우기 위해 전문가와의 상담을 권장드립니다.",
      },
    },
    whr: {
      good: {
        title: translocation.inbody_dashboard_detail_evaluation_title_inner_whr, //"복부지방률이#표준 범위 내에요.",
        resultTitle:
          translocation.inbody_dashboard_detail_evaluation_description_inner_whr, //"잘 하고 있어요!",
        subTitle:
          translocation.inbody_dashboard_detail_evaluation_description_detail_inner_whr, //"현재 복부지방률이 표준 범위 이내입니다. 복부지방은 신체의 여러 기능 및 건강 상태와 관련이 있습니다. 현재 복부지방률이 안정적인 범위에 있으므로, 꾸준한 운동과 균형 잡힌 식사를 계속하시길 바랍니다.",
      },
      down: {
        title: translocation.inbody_dashboard_detail_evaluation_title_under_whr, //"복부지방률이#표준 범위 미만이에요.",
        resultTitle:
          translocation.inbody_dashboard_detail_evaluation_description_under_whr, //"꾸준한 관리가 필요해요.",
        subTitle:
          translocation.inbody_dashboard_detail_evaluation_description_detail_under_whr, //"현재 복부지방률이 표준 범위보다 낮게 측정되었습니다. 지나치게 낮은 복부지방률은 몸의 에너지 저장 및 다양한 기능에 영향을 줄 수 있습니다. 균형 잡힌 식사와 영양 섭취를 통해 체지방률을 적정 범위로 유지하는 것이 좋습니다.",
      },
      up: {
        title: translocation.inbody_dashboard_detail_evaluation_title_over_whr, //"복부지방률이#표준 범위를 초과했어요.",
        resultTitle:
          translocation.inbody_dashboard_detail_evaluation_description_over_whr, //"균형잡힌 관리가 필요해요.",
        subTitle:
          translocation.inbody_dashboard_detail_evaluation_description_detail_over_whr, //"현재 복부지방률이 표준 범위보다 높게 측정되었습니다. 지나치게 낮은 복부지방률은 몸의 에너지 저장 및 다양한 기능에 영향을 줄 수 있습니다. 균형 잡힌 식사와 영양 섭취를 통해 체지방률을 적정 범위로 유지하는 것이 좋습니다.",
      },
    },
  };

  const transformType: TTransform = {
    smm: { name: translocation.SMM, unit: "kg" }, // "골격근"
    tbw: { name: translocation.TBW, unit: "L" }, // "체수분"
    pbf: { name: translocation.PBF, unit: "%" }, // "체지방률"
    whr: { name: translocation.WHR, unit: "" }, // "복부지방률"
    hr: { name: translocation.hr, unit: translocation.per_minute }, // "심박수"
    rep: { name: translocation.rep, unit: translocation.per_minute }, // "호흡수"
    msi: { name: translocation.msi, unit: "" }, // "스트레스"
    psi: { name: translocation.psi, unit: "" }, // "피로도"
  };

  return { inbodyComment, transformType };
};
