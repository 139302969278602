import * as Svg from "../../components/atoms/icon/index";
import { useLocalization } from "../../hooks/useLocalization";
import { THealthType, TMark } from "../../types/deepQuery.type";

type TStandardHealth = Record<
  THealthType | string,
  { standard: number; max: number; tick: number[]; min: number }
>;

export const MarkSig: Record<TMark, React.ReactElement> = {
  up: <Svg.HealthInCrease />,
  down: <Svg.HealthDecrease />,
  "": <></>,
};

export const HealthSignitureController = (key?: THealthType | undefined) => {
  const translation = useLocalization();

  const roadBalance: { [index: string]: { [index: string]: string } } = {
    2: {
      hr: translation.healthcare_normal_2hr,
      rep: translation.healthcare_normal_2rep,
      psi: translation.healthcare_normal_2psi,
      msi: translation.healthcare_normal_2msi,
    },
    3: {
      hr: translation.healthcare_normal_3hr,
      rep: translation.healthcare_normal_3rep,
      psi: translation.healthcare_normal_3psi,
      msi: translation.healthcare_normal_3msi,
    },
  };

  const GoodCountTitle: { [index: string]: string } = {
    0: translation.healthcare_normal_0,
    1: translation.healthcare_normal_1,
    2: roadBalance[2][key as string],
    3: roadBalance[3][key as string],
    4: translation.healthcare_normal_4,
  };
  return GoodCountTitle;
};

export const standardChart: TStandardHealth = {
  hr: { standard: 75, min: 61, max: 99, tick: [0, 40, 80, 120] },
  rep: { standard: 10, min: 6, max: 18, tick: [0, 10, 20, 30] },
  msi: { standard: 25, min: 0, max: 50, tick: [0, 35, 70, 100] },
  psi: { standard: 25, min: 0, max: 50, tick: [0, 35, 70, 100] },
};
