import styled from "styled-components";
import { keyframes } from "styled-components";
import { pxToVh } from "../../../utils/calc/pxToVh";

export const WidthBarPadding = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  gap: ${pxToVh(14)};
  justify-content: start;
  align-items: center;
`;

export const RightHeader = styled.div`
  display: flex;
  align-items: center;
  span {
    letter-spacing: ${pxToVh(0.4)};
  }
  svg {
    width: ${pxToVh(24)};
    height: ${pxToVh(24)};
  }
`;

export const Baseline = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0 ${pxToVh(2)};
`;

const fadeUp = keyframes`
    0% {
        opacity: 0;
        transform: translateY(50%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const WidthBarLayout = styled.div<{
  $width?: string;
  $min?: number;
  $max?: number;
}>`
  position: relative;
  width: ${({ $width }) => ($width ? $width : "100%")};
  height: ${pxToVh(8)};
  background-color: ${({ theme }) => theme.color.gray200};
  border-radius: ${pxToVh(4)};
  &::before {
    content: "";
    position: absolute;
    opacity: 0;
    animation: ${fadeUp} 0.6s ease-in-out 1s forwards;
    top: ${pxToVh(-10)};
    left: ${({ $min }) => `${$min}%`};
    width: ${({ $min }) => ($min ? pxToVh(1) : 0)};
    height: ${pxToVh(8)};
    background-color: ${({ theme }) => theme.color.gray100};
  }
  &::after {
    content: "";
    position: absolute;
    opacity: 0;
    animation: ${fadeUp} 0.6s ease-in-out 1s forwards;
    top: ${pxToVh(-10)};
    left: ${({ $max }) => `${$max}%`};
    width: ${({ $max }) => ($max ? pxToVh(1) : 0)};
    height: ${pxToVh(8)};
    background-color: ${({ theme }) => theme.color.gray100};
  }
`;

export const ProgressValue = styled.div<{
  $isStandard?: boolean;
  $target: number;
  $min?: number;
}>`
  position: absolute;
  transition: 1s ease-in-out;
  background: ${({ $isStandard, theme }) =>
    $isStandard
      ? theme.color.gray50
      : `linear-gradient(
    270deg,
    #00FFEC 80.93%,
    rgba(255, 255, 255, 0.80) 111.06%
  )`};
  border-radius: ${pxToVh(4)};
  top: 0;
  left: ${({ $min }) => `${$min}%` || 0};
  width: ${({ $target }) => `${$target}%`};
  height: 100%;
`;

export const TextLayout = styled.div<{ $center: number }>`
  position: absolute;
  top: ${pxToVh(-36)};
  left: ${({ $center }) => `${$center}%`};
  transform: translateX(-50%);
`;
