import React from "react";
import CircleProgress from "../../graph/circleProgress/CircleProgress";
import { theme } from "../../../styles/theme";
import Text from "../../atoms/text/Text";
import {
  Center,
  InfoDetailTextLayout,
  NutritionInfoLayout,
} from "./NutritionStyles";
import { pxToVh } from "../../../utils/calc/pxToVh";
import { INutrition } from "../../../types/nutrition.type";

const Nutrition = ({ circleValue, nutriTitle, nutriValue }: INutrition) => {
  return (
    <NutritionInfoLayout>
      <Center>
        <CircleProgress
          width={pxToVh(55)}
          value={circleValue}
          circleColor={theme.color.cyan20}
          textSize={"26px"}
        />
      </Center>
      <InfoDetailTextLayout>
        <Text
          content={nutriTitle}
          fontSize={theme.size.xs}
          fontWeight={theme.weight.bold}
        />
        <Text
          content={nutriValue}
          fontSize={theme.size.xxs}
          fontWeight={theme.weight.light}
        />
      </InfoDetailTextLayout>
    </NutritionInfoLayout>
  );
};

export default Nutrition;
