import React, { useMemo } from "react";
import { StyledText } from "./StyledText";

interface IText extends React.HTMLAttributes<HTMLSpanElement> {
  content: string;
  fontSize: string;
  fontWeight: string;
  letterSpacing?: string;
  customStyle?: React.CSSProperties;
}

const Text = ({
  content,
  fontSize,
  fontWeight,
  color,
  letterSpacing,
  customStyle,
}: IText) => {
  const style = useMemo(() => {
    return {
      fontSize,
      fontWeight,
      color,
      letterSpacing,
      ...customStyle,
    };
  }, [fontSize, fontWeight, color, letterSpacing, customStyle]);

  return <StyledText style={style}>{content}</StyledText>;
};

export default Text;
