import React from "react";
import {
  BottomLabel,
  BottomLayout,
  SingleSquare,
  TitleLabel,
  TitleLayout,
  UrlCenter,
} from "./DeepSquareStyles";
import Text from "../../atoms/text/Text";
import { theme } from "../../../styles/theme";
import * as Svg from "../../atoms/icon/index";
import { PickState } from "../squareInbody/SquareInbodyStyles";
import { MarkSig } from "../../../utils/comment/HealthSignature";
import { useLocalization } from "../../../hooks/useLocalization";
import { PickData } from "../../../utils/comment/PickColorData";
import { TMark, TStatus } from "../../../types/deepQuery.type";

const DeepSquare = ({
  top,
  type,
  middle,
  bottom,
  mark,
  isValid,
  balancerUrl,
}: {
  top: string;
  type: TStatus;
  middle: (string | number)[];
  bottom: string;
  mark?: TMark;
  isValid: boolean;
  balancerUrl: () => void;
}) => {
  const translation = useLocalization();
  const PickColorData = PickData();

  if (isValid) {
    return (
      <SingleSquare>
        <UrlCenter>
          <Text
            content={translation.check_url}
            fontSize={theme.size.xs}
            fontWeight={theme.weight.bold}
          />
        </UrlCenter>
      </SingleSquare>
    );
  }

  return (
    <SingleSquare onClick={balancerUrl}>
      <TitleLabel>
        <TitleLayout>
          <Text
            content={top}
            fontSize={theme.size.xs}
            fontWeight={theme.weight.bold}
          />
          {MarkSig[mark || ""]}
        </TitleLayout>
        <PickState
          $backgroundColor={PickColorData["health"][type].pickBackColor}
          $isBorder={PickColorData["health"][type].isBorder}
        >
          <Text
            content={PickColorData["health"][type].ment}
            fontSize={theme.size.xxxs}
            fontWeight={theme.weight.bold}
          />
        </PickState>
      </TitleLabel>
      <BottomLabel>
        <div>
          <Text
            content={String(middle[0])}
            fontSize={theme.size.large}
            fontWeight={theme.weight.bold}
          />
          <Text
            content={String(middle[1] || "")}
            fontSize={theme.size.xs}
            fontWeight={theme.weight.bold}
          />
        </div>
        <BottomLayout>
          <Text
            content={bottom}
            fontSize={theme.size.xxs}
            fontWeight={theme.weight.bold}
            color={theme.color.gray100}
            letterSpacing={theme.letterSize.x}
          />
          <Svg.ArrowRight />
        </BottomLayout>
      </BottomLabel>
    </SingleSquare>
  );
};

export default DeepSquare;
